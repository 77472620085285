import React from 'react'

export const Femalelink = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 1.2 36.18 31.8"
    >
      <defs>
        <linearGradient id={props.id} x1="40" y1="40" x2="40" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M15.1,28H2.2l0.1-0.4c0.4-1.8,1.6-3.2,3.4-3.8c2-0.7,3.4-2.5,3.4-4.7c0-0.4-0.2-0.7-0.5-0.9C7,17.4,6,15.6,6,13.8v-2.3
        c2.3,2.2,5.7,3.8,9.7,4.3c-0.4,1.1-1.2,2-2.2,2.6c-0.3,0.2-0.5,0.5-0.5,0.9c0,2,1.2,3.7,3,4.5c0.3-0.5,0.6-0.9,1-1.3l1.6-1.6
        l2.1-2.1l1-1c0.2-1.2,0.3-2.5,0.3-3.6c0-7.5-4.1-12-11-12S0,6.5,0,14c0,3.2,0.8,6.8,2.1,9.9c-0.9,0.9-1.5,2.1-1.7,3.4l-0.2,0.9
        c-0.1,0.5,0,0.9,0.3,1.3C0.8,29.8,1.2,30,1.7,30h14C15.3,29.4,15.1,28.7,15.1,28z M26.9,29l-1.6,1.6c-0.9,0.9-2.2,1.4-3.4,1.4c-1.2,0-2.5-0.5-3.4-1.4c-1.9-1.9-1.9-5,0-6.8l1.6-1.6c0.4-0.4,1.1-0.4,1.5,0
        c0.4,0.4,0.4,1.1,0,1.5l-1.6,1.6c-1.1,1.1-1.1,2.8,0,3.8c1.1,1.1,2.8,1.1,3.8,0l1.6-1.6c0.4-0.4,1.1-0.4,1.5,0S27.3,28.6,26.9,29z
         M22.1,25.3l3.2-3.2c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-3.2,3.2c-0.2,0.2-0.5,0.3-0.8,0.3s-0.5-0.1-0.8-0.3
        C21.7,26.4,21.7,25.8,22.1,25.3z M30.6,25.3L29,26.9c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5
        l1.6-1.6c1.1-1.1,1.1-2.8,0-3.8c-1.1-1.1-2.8-1.1-3.8,0l-1.6,1.6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l1.6-1.6
        c1.9-1.9,5-1.9,6.8,0C32.5,20.3,32.5,23.4,30.6,25.3z"
      />
    </svg>
  )
}
export default Femalelink
