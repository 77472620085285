import React from 'react'
// IMAGES
import ModalImage from './../images/TchitchatBrandHome.png'

const DialogBox = ({
  onSubmit,
  title = '... message',
  sentence = '',
  txtbutton = '',
  cancelbutton = '',
  bottomtext = '',
}) => {
  const handleClick = (choice) => {
    onSubmit(choice)
  }

  return (
    <>
      <div className="dialogbox">
        <div className="dialog-frame">
          <div className="dialog-col">
            <div className="dialog-line">
              <div className="title-image">
                <img
                  key={'img-modale'}
                  src={ModalImage}
                  alt="tchitchat brand"
                  className="img-brand"
                  width="48px"
                  height="48px"
                />
              </div>
              <div className="title-brand">Tchitchat</div>
            </div>
            <br />
            <div className="dialog-line">
              <div className="title">{title}</div>
            </div>

            <div className="dialog-line">
              <div className="splitbar"></div>
            </div>

            <div className="dialog-line">
              <div className="sentence">{sentence} </div>
            </div>
          </div>
        </div>
        <div className="txtbutton">
          <div className="btn btn-validation btn-modal" onClick={() => handleClick('yes')}>
            {txtbutton}
          </div>
        </div>
        {cancelbutton !== '' ? (
          <div className="txtbutton">
            <div className="btn btn-validation btn-modal" onClick={() => handleClick('cancel')}>
              {cancelbutton}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="bottom-frame">
          <div className="bottom-line">
            <div className="bottom-text" onClick={() => handleClick('todo ...')}>
              {bottomtext}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DialogBox
