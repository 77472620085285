import React, { FC } from 'react'

import Instagram from '../../../pictos/Instagram'
import Snapchat from '../../../pictos/Snapchat'
import Facebook from '../../../pictos/Facebook'
import Linkedin from '../../../pictos/Linkedin'
import GoogleMaps from '../../../pictos/GoogleMaps'
import Whatsapp from '../../../pictos/Whatsapp'
import Messenger from '../../../pictos/Messenger'
import Skype from '../../../pictos/Skype'
import TikTok from '../../../pictos/TikTok'
import Website from '../../../pictos/Website'
import AppleMusic from '../../../pictos/AppleMusic'
import Spotify from '../../../pictos/Spotify'
import SoundCloud from '../../../pictos/SoundCloud'
import Youtube from '../../../pictos/Youtube'
import Twitch from '../../../pictos/Twitch'
import Twitter from '../../../pictos/Twitter'
import Pinterest from '../../../pictos/Pinterest'
import Reddit from '../../../pictos/Reddit'
import Vcard from '../../../pictos/Vcard'
import ShortMessage from '../../../pictos/ShortMessage'
import Email from '../../../pictos/Email'
import Facetime from '../../../pictos/Facetime'
import Wechat from '../../../pictos/Wechat'
import Telegram from '../../../pictos/Telegram'
import Signal from '../../../pictos/Signal'
import Webpro from '../../../pictos/Webpro'
import Calendly from '../../../pictos/Calendly'
import Malt from '../../../pictos/Malt'
import Slack from '../../../pictos/Slack'
import Behance from '../../../pictos/Behance'
import Lydia from '../../../pictos/Lydia'
import Paypal from '../../../pictos/Paypal'
import Discord from '../../../pictos/Discord'
import Gsm from '../../../pictos/Gsm'
import Landline from '../../../pictos/Landline'

interface IPicto {
  // PictoId
  pictoId?: string
  setPictoId?: (pictoId: string) => void
  // pictoName
  pictoName?: string
  setPictoName?: (name: string) => void
  // pictoFile
  pictoFile?: string
  setPictoFile?: (file: string) => void
  // topColor
  topColor?: string
  setTopColor?: (color: string) => void
  // bottomColor
  bottomColor?: string
  setBottomColor?: (color: string) => void
  // width
  pictoWidth?: string
  setPictoWidth?: (width: string) => void
  // height
  pictoHeight?: string
  setPictoHeight?: (height: string) => void
}

interface ISvg {
  id?: string
  setId?: (id: string) => void
  top_color?: string
  setTop_Color?: (color: string) => void
  bottom_color?: string
  setBottom_Color?: (color: string) => void
  width?: string
  setWidth?: (width: string) => void
  height?: string
  setHeight?: (height: string) => void
}

const components = {
  Instagram: Instagram,
  Snapchat: Snapchat,
  Facebook: Facebook,
  Linkedin: Linkedin,
  GoogleMaps: GoogleMaps,
  Whatsapp: Whatsapp,
  Messenger: Messenger,
  Skype: Skype,
  TikTok: TikTok,
  Website: Website,
  AppleMusic: AppleMusic,
  Spotify: Spotify,
  SoundCloud: SoundCloud,
  Youtube: Youtube,
  Twitch: Twitch,
  Twitter: Twitter,
  Pinterest: Pinterest,
  Reddit: Reddit,
  Vcard: Vcard,
  ShortMessage: ShortMessage,
  Email: Email,
  Facetime: Facetime,
  Wechat: Wechat,
  Telegram: Telegram,
  Signal: Signal,
  Webpro: Webpro,
  Calendly: Calendly,
  Malt: Malt,
  Slack: Slack,
  Behance: Behance,
  Lydia: Lydia,
  Paypal: Paypal,
  Discord: Discord,
  Gsm: Gsm,
  Landline: Landline,
}

const Picto: FC<IPicto> = (props) => {
  // -------------------------------------------
  if (!props.pictoName || props.pictoName === 'none') {
    return <></>
  }
  const ThePicto = components[props.pictoName]
  if (!ThePicto) {
    return <></>
  }
  // let ThePicto: FC<ISvg> = () => {
  //   return <></>
  // }

  // if (props.pictoFile !== undefined) {
  //   console.log('components, props.pictoFile:', props)
  //   ThePicto = components[props.pictoFile as keyof typeof components]
  // }

  // const ThePicto: FC<ISvg> =
  //   props.pictoFile !== undefined ? components[props.pictoFile as keyof typeof components] : ''

  return (
    <ThePicto
      id={props.pictoName}
      top_color={props.topColor}
      bottom_color={props.bottomColor}
      width={props.pictoWidth}
      height={props.pictoHeight}
    />
  )
}

export default Picto
