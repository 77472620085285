import React from 'react'

export const Landline = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-5 -18 300 330.2"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M86.954,0H36.823c-8.425,0-15.279,6.853-15.279,15.277V277.81c0,8.424,6.854,15.277,15.279,15.277h50.131
		c8.367,0,15.179-6.761,15.272-15.105V15.104C102.133,6.761,95.321,0,86.954,0z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M257.364,15.104H113.545v262.878h143.819c7.818,0,14.18-6.361,14.18-14.18V29.285
		C271.544,21.466,265.183,15.104,257.364,15.104z M134.561,31.104h96.033v53.552h-96.033V31.104z M151.134,231.324h-14.572
		c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5
		C158.634,227.96,155.27,231.324,151.134,231.324z M151.134,199.192h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5
		h14.572c4.136,0,7.5,3.364,7.5,7.5C158.634,195.828,155.27,199.192,151.134,199.192z M151.134,167.061h-14.572
		c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5S155.27,167.061,151.134,167.061z
		 M151.134,134.929h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5
		C158.634,131.564,155.27,134.929,151.134,134.929z M191.864,231.324h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5
		h14.572c4.136,0,7.5,3.364,7.5,7.5C199.364,227.96,196,231.324,191.864,231.324z M191.864,199.192h-14.572
		c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5
		C199.364,195.828,196,199.192,191.864,199.192z M191.864,167.061h-14.572c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5h14.572
		c4.136,0,7.5,3.364,7.5,7.5S196,167.061,191.864,167.061z M191.864,134.929h-14.572c-4.136,0-7.5-3.364-7.5-7.5
		c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5C199.364,131.564,196,134.929,191.864,134.929z M232.595,231.324
		h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5
		C240.095,227.96,236.73,231.324,232.595,231.324z M232.595,199.192h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5
		h14.572c4.136,0,7.5,3.364,7.5,7.5C240.095,195.828,236.73,199.192,232.595,199.192z M232.595,167.061h-14.572
		c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5S236.73,167.061,232.595,167.061z
		 M232.595,134.929h-14.572c-4.136,0-7.5-3.364-7.5-7.5c0-4.136,3.364-7.5,7.5-7.5h14.572c4.136,0,7.5,3.364,7.5,7.5
		C240.095,131.564,236.73,134.929,232.595,134.929z"
      />
    </svg>
  )
}
export default Landline
