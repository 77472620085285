import React from 'react'

export const Curve = (props) => {
  return (
    <svg
      width={props.width ? props.width : 80}
      height={props.height ? props.height : 50}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 50"
    >
      <defs>
        <linearGradient id={props.id} x1="40" y1="50" x2="40" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M 80 50 C 56 50 80 4 35 4 V 0 H 80 Z"
        // d="M 80 50 C 69 50 75 8 61 4 V 0 H 80 Z"
      />
    </svg>
  )
}
export default Curve
