import { FC, useState, useEffect, useRef, useContext, createContext } from 'react'

import { useNavigate } from 'react-router-dom'

// COMPONENTS
import Modal from './../components/ModalFrame'
import ConnectionBox from './../components/ConnectionBox'
// CONTEXT
import { ProfileContext } from './../Reducers/ProfileContext'
import { setUserOffline } from './../Reducers/ProfileActions'
// LIBRARIES
import { useIntl } from 'react-intl'

interface IContext {
  isConnected: boolean
}

const ConnectionContext = createContext<IContext>({
  isConnected: false,
})

interface Props {
  children: React.ReactNode
}

export const ConnectionProvider: FC<Props> = ({ children }) => {
  const { state, dispatch } = useContext(ProfileContext)
  const navigate = useNavigate()
  const intl = useIntl()
  const [isConnected, setIsConnected] = useState<boolean>(state.connection)
  const modal1 = useRef<any>(null)

  useEffect(() => {
    const timer = window.setInterval(() => {
      // console.log('ConnectionProvider PLOP ++++++++++++++')
      if (state.offline === false) {
        setIsConnected(state.connection)
        if (state.connection === false) {
          modal1.current.open()
        } else {
          modal1?.current?.close()
        }
        return
      }
    }, 5000)
    return () => {
      window.clearInterval(timer)
    }
  }, [state.offline, state.connection])

  const handleDialogSignIn = async (response) => {
    switch (response) {
      case 'login':
        modal1.current.close()
        navigate('/login')
        break
      case 'home':
        modal1.current.close()
        navigate('/home')
        break
      case 'offline':
        modal1.current.close()
        setUserOffline(true, state, dispatch)
        window.location.reload()
        break
      default:
        break
    }
  }

  return (
    <ConnectionContext.Provider
      value={{ isConnected }}
      // React Children
    >
      {children}
      {/* // MODAL 2 -> Sign in  */}
      <Modal ref={modal1} nocross={true}>
        <div className="line fullGrid">
          <div className="grid100">
            <ConnectionBox
              onSubmit={handleDialogSignIn}
              title={intl.formatMessage({ id: 'app.reconnect.title' })}
              sentence={intl.formatMessage({ id: 'app.reconnect.sentence' })}
              txtbutton={intl.formatMessage({ id: 'app.reconnect.txtbutton' })}
              cancelbutton={intl.formatMessage({ id: 'app.reconnect.cancelbutton' })}
              bottomtext={intl.formatMessage({ id: 'app.reconnect.stayoffline' })}
            />
          </div>
        </div>
      </Modal>
    </ConnectionContext.Provider>
  )
}
