import React from 'react'

export const Heart = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="13 17.7 105.4 92.5"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M92.6,18.8C92.5,18.8,92.5,18.8,92.6,18.8c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0 
         l-14.3,2.4c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0-0.1,0.1 
          c-0.1,0.1-0.3,0.3-0.4,0.4c0,0,0,0,0,0L64,40.7L51.7,22.2c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.1,0-0.1-0.1 
           c-0.1-0.1-0.2-0.2-0.4-0.3c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.1-0.5-0.2c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-14.3-2.4c-0.1,0-0.1,0-0.2,0 
            c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.6,0-1.1,0.2-1.6,0.6L14.8,36c0,0,0,0,0,0c-0.1,0.1-0.3,0.3-0.4,0.4c0,0,0,0.1,0,0.1 
             c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.5l4.8,26.2 
              c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2l23.8,28.6c0.1,0.1,0.3,0.3,0.4,0.4 
               c0,0,0,0,0,0l19,14.3c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0 
                c0.2,0,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.2 
                 l19-14.3c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.4l23.8-28.6c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3 
                  c0-0.1,0.1-0.2,0.1-0.3l4.8-26.2c0-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.1-0.1-0.2 
                   c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.4-0.4c0,0,0,0,0,0l-19-16.7C93.7,19,93.1,18.8,92.6,18.8z M59.6,42.6
                     H41.9l8-14.5L59.6,42.6z M46,25.3l-6.6,11.8L38.1,24L46,25.3z M33.5,26l1.6,15.5l-14-4.7L33.5,26z M19.5,41.4l14.2,4.7L22.6,58.7 
                      L19.5,41.4z M36.8,49.8l4.8,15.9l-15.9-3.2L36.8,49.8z M42.6,86L27.3,67.7l15.3,3.1V86z M41,47.3h18L45.9,63.7L41,47.3z M47.3,73.5 
                       l12.2,9.1l-12.2,6.1V73.5z M61.6,102.1L49.5,93l12.2-6.1V102.1z M61.6,78.3l-13.3-10l13.3-16.6V78.3z M89.9,24l-1.3,13.1L82,25.3 
                        L89.9,24z M78.1,28.1l8,14.5H68.4L78.1,28.1z M66.4,51.7l13.3,16.6l-13.3,10V51.7z M66.4,102.1V86.9L78.5,93L66.4,102.1z M80.7,88.7 
                         l-12.2-6.1l12.2-9.1V88.7z M82.1,63.7L69,47.3h18L82.1,63.7z M85.4,86V70.7l15.3-3.1L85.4,86z M86.5,65.7l4.8-15.9l11.1,12.7 
                          L86.5,65.7z M105.4,58.7L94.3,46.1l14.2-4.7L105.4,58.7z M106.9,36.9l-14,4.7L94.5,26L106.9,36.9z"
      />
    </svg>
  )
}
export default Heart
