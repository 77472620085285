import React from 'react'

export const Gsm = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 -1 27.39 28.8"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M19.039,0H7.761C6.683,0,5.812,0.875,5.812,1.952v22.899c0,1.076,0.871,1.951,1.949,1.951h11.277
        c1.078,0,1.951-0.875,1.951-1.951V1.952C20.99,0.875,20.118,0,19.039,0z M10.617,1.186h5.568c0.141,0,0.254,0.21,0.254,0.471
        c0,0.26-0.113,0.471-0.254,0.471h-5.568c-0.141,0-0.253-0.211-0.253-0.471C10.364,1.396,10.476,1.186,10.617,1.186z
         M13.401,24.875c-0.687,0-1.246-0.558-1.246-1.246s0.56-1.244,1.246-1.244s1.245,0.557,1.245,1.244
        C14.646,24.317,14.086,24.875,13.401,24.875z M19.528,20.607H7.274V3.294h12.254V20.607z"
      />
    </svg>
  )
}
export default Gsm
