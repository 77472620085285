import React from 'react'

export const Deadjim = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 124 123.7"
    >
      <defs>
        <linearGradient id={props.id} x1="125" y1="125" x2="125" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <g>
        <path
          fill={`url(#${props.id})`}
          fillRule="evenodd"
          d="M60.835,121.669c-33.544,0-60.834-27.29-60.834-60.835C0.001,27.29,27.291,0,60.835,0s60.834,27.29,60.834,60.834
		C121.669,94.379,94.379,121.669,60.835,121.669z M60.835,6C30.6,6,6.001,30.599,6.001,60.834c0,30.236,24.599,54.835,54.834,54.835
		s54.834-24.599,54.834-54.835C115.669,30.599,91.071,6,60.835,6z"
        />
        <path
          fill={`url(#${props.id})`}
          fillRule="evenodd"
          d="M78.844,97.437h-6c0-5.642-5.477-10.231-12.208-10.231s-12.208,4.59-12.208,10.231h-6c0-8.95,8.168-16.231,18.208-16.231
		S78.844,88.486,78.844,97.437z"
        />
        <polygon
          fill={`url(#${props.id})`}
          fillRule="evenodd"
          points="98.363,45.285 94.119,41.042 88.006,47.155 81.892,41.042 77.65,45.285 83.763,51.398 77.65,57.512 81.892,61.755 
		88.006,55.642 94.119,61.755 98.363,57.512 92.249,51.398 	"
        />
        <polygon
          fill={`url(#${props.id})`}
          fillRule="evenodd"
          points="43.622,45.285 39.379,41.042 33.266,47.155 27.151,41.042 22.908,45.285 29.022,51.398 22.908,57.512 
		27.151,61.755 33.266,55.642 39.379,61.755 43.622,57.512 37.509,51.398 	"
        />
      </g>
    </svg>
  )
}
export default Deadjim
