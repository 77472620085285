import React, { lazy } from 'react'
// import { ConnectionProvider } from './hooks/useConnection'
// Pull Downs
// PAGES GUEST
import PullHome from './pulls/pullhome'
import PullGuest from './pulls/pullguest'
// PAGES USER
const PullMain = lazy(() => import('./pulls/pullmain'))
const PullHelp = lazy(() => import('./pulls/pullhelp'))
const PullProfile = lazy(() => import('./pulls/pullprofile'))
const PullSettings = lazy(() => import('./pulls/pullsettings'))
const PullSignin = lazy(() => import('./pulls/pullsignin'))
const PullLogin = lazy(() => import('./pulls/pulllogin'))
const PullPrivacy = lazy(() => import('./pulls/pullprivacy'))
const PullTerms = lazy(() => import('./pulls/pullterms'))

const pulldowns = [
  {
    path: '/on_scan/:card_guid',
    element: <PullGuest />,
  },
  {
    path: '/on_tap/:card_guid',
    element: <PullGuest />,
  },
  {
    path: '/on_link/:card_guid',
    element: <PullGuest />,
  },
  {
    path: '/on_self/:card_guid',
    element: <PullGuest />,
  },
  {
    path: '/',
    element: <PullHome />,
  },
  {
    path: '/home',
    element: <PullHome />,
  },
  {
    path: '/guest',
    element: <PullGuest />,
  },
  {
    path: '/signin',
    element: <PullSignin />,
  },
  {
    path: '/login',
    element: <PullLogin />,
  },
  {
    path: '/main',
    element: <PullMain />,
  },
  {
    path: '/profile',
    element: <PullProfile />,
  },
  {
    path: '/contacts',
    element: <PullGuest />,
  },
  {
    path: '/settings',
    element: <PullSettings />,
  },
  {
    path: '/help',
    element: <PullHelp />,
  },
  {
    path: '/privacy',
    element: <PullPrivacy />,
  },
  {
    path: '/terms',
    element: <PullTerms />,
  },
  {
    path: '*',
    element: <PullMain />,
  },
]

export default pulldowns
