import React from 'react'

export const Bin = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 -0.498 24.22 23"
    >
      <defs>
        <linearGradient id={props.id} x1="32" y1="32" x2="32" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M21,0.502H1c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1h0.219l2.304,13.771
        c0.405,1.96,1.746,3.226,3.416,3.226h8.124c1.716,0,3.059-1.274,3.423-3.263l2.297-13.734H21c0.551,0,1-0.449,1-1v-2
        C22,0.951,21.551,0.502,21,0.502z M16.102,4.957l-0.454-0.455h0.909L16.102,4.957z M9.522,19.501l-0.567-0.567l2.001-2.001
        l2.001,2.001l-0.566,0.567H9.522z M5.356,4.502h0.909L5.811,4.957L5.356,4.502z M15.395,5.663l-1.731,1.731l-2.002-2.002
        l0.892-0.891h1.68L15.395,5.663z M7.679,4.502h1.679l0.891,0.891L8.248,7.394l-1.73-1.73L7.679,4.502z M10.956,4.686l-0.184-0.184
        h0.368L10.956,4.686z M10.956,6.099l2.002,2.002l-2.001,2L8.955,8.1L10.956,6.099z M10.25,10.809L8.249,12.81l-2.002-2.002
        l2.001-2.001L10.25,10.809z M10.957,11.516l2.001,2.001l-2.001,2.002l-2.002-2.002L10.957,11.516z M11.664,10.809l2.001-2.001
        l2.001,2.001l-2.001,2.001L11.664,10.809z M7.541,8.101L5.54,10.102L3.906,8.468L3.878,8.303L5.81,6.371L7.541,8.101z M4.19,10.165
        l0.643,0.643l-0.458,0.458L4.19,10.165z M4.994,14.973l-0.417-2.494l0.963-0.963l2.002,2.002L5.54,15.519L4.994,14.973z
         M8.248,14.224l2.002,2.002l-2.001,2.001l-2.002-2.002L8.248,14.224z M11.664,16.227l2.001-2.002l2.001,2.001l-2.002,2.002
        L11.664,16.227z M14.372,13.518l2.001-2.001l1.038,1.038l-0.387,2.312l-0.651,0.651L14.372,13.518z M17.08,10.809l0.749-0.749
        l-0.215,1.283L17.08,10.809z M16.373,10.102l-2.001-2.001l1.731-1.731l2.001,2.001L16.373,10.102z M3.942,4.502l1.162,1.162
        L3.676,7.092l-0.433-2.59H3.942z M5.484,17.904l-0.131-0.785l0.187-0.187l2.002,2.002l-0.567,0.567H6.938
        C5.896,19.501,5.557,18.25,5.484,17.904z M16.519,17.89c-0.05,0.268-0.352,1.611-1.457,1.611h-0.123l-0.567-0.567l2.002-2.002
        l0.262,0.262L16.519,17.89z M18.313,7.167l-1.504-1.504l1.162-1.161h0.788L18.313,7.167z"
        // viewBox="17.32 -1 588.4 514" d="M449.195,41.164H348.641C346.894,18.174,327.625,0,304.195,0h-96.389c-23.43,0-42.698,18.174-44.446,41.164H62.807
        // c-24.579,0-44.575,19.997-44.575,44.575v38.695c0,20.698,14.181,38.144,33.336,43.137l40.99,327.838
        // c1.18,9.458,9.221,16.592,18.701,16.592h289.485c9.479,0,17.521-7.132,18.701-16.592v0.002l40.99-327.84
        // c19.155-4.995,33.336-22.439,33.336-43.137V85.74C493.77,61.161,473.775,41.164,449.195,41.164z M390.584,481.656H121.417
        // L82.327,169.009h347.348L390.584,481.656z M463.426,124.434c0,7.847-6.384,14.231-14.231,14.231h-2.333H65.14h-2.333
        // c-7.847,0-14.231-6.384-14.231-14.231v-4.505h282.864c8.378,0,15.172-6.794,15.172-15.172c0-8.378-6.794-15.172-15.172-15.172
        // H48.575V85.74c0-7.847,6.384-14.231,14.231-14.231h115.597c8.378,0,15.172-6.794,15.172-15.172V44.575
        // c0-7.847,6.384-14.231,14.231-14.231h96.389c7.847,0,14.231,6.384,14.231,14.231v11.761c0,8.378,6.794,15.172,15.172,15.172
        // h115.597c7.847,0,14.231,6.384,14.231,14.231V124.434z M351.29,314.968c10.23-9.218,13.271-20.142,14.149-25.126c4.121-23.362-10.664-48.971-32.958-57.086
        // c-4.755-1.73-15.736-4.558-28.834-0.308c-2.869-13.47-10.808-21.564-14.685-24.815c-18.174-15.249-47.745-15.249-65.918,0
        // c-3.876,3.253-11.816,11.347-14.685,24.815c-13.098-4.253-24.079-1.423-28.834,0.308c-22.292,8.115-37.077,33.724-32.958,57.086
        // c0.878,4.982,3.919,15.906,14.149,25.126c-10.232,9.22-13.272,20.144-14.151,25.126c-4.121,23.363,10.664,48.972,32.958,57.086
        // c4.753,1.733,15.735,4.562,28.834,0.308c2.869,13.47,10.809,21.565,14.685,24.817c9.086,7.624,21.022,11.437,32.96,11.437
        // c11.937,0,23.872-3.813,32.96-11.437c3.876-3.253,11.816-11.347,14.685-24.817c13.098,4.251,24.078,1.423,28.834-0.308
        // c22.292-8.114,37.077-33.723,32.958-57.086C364.561,335.112,361.521,324.188,351.29,314.968z M302.728,330.141
        // c9.062,0,30.4,1.484,32.823,15.222c1.569,8.891-4.969,20.215-13.453,23.303c-13.106,4.778-25.064-12.966-29.594-20.814
        // c-4.19-7.255-13.468-9.742-20.725-5.553c-4.869,2.811-7.591,7.912-7.589,13.157c0.002,2.572,0.657,5.18,2.036,7.568
        // c4.53,7.848,13.916,27.07,3.229,36.036c-6.917,5.805-19.994,5.805-26.909,0c-10.687-8.967-1.302-28.188,3.229-36.036
        // c1.379-2.388,2.035-4.996,2.036-7.568c0.002-5.245-2.72-10.346-7.589-13.157c-7.26-4.19-16.536-1.702-20.725,5.553
        // c-4.53,7.848-16.489,25.591-29.594,20.814c-8.484-3.088-15.022-14.412-13.453-23.303c2.423-13.74,23.761-15.222,32.823-15.222
        // c8.378,0,15.172-6.794,15.172-15.172c0-8.378-6.794-15.172-15.172-15.172c-9.062,0-30.4-1.484-32.823-15.222
        // c-1.569-8.889,4.969-20.215,13.453-23.303c13.106-4.767,25.066,12.967,29.594,20.814c4.19,7.255,13.471,9.746,20.725,5.553
        // c4.869-2.811,7.591-7.912,7.589-13.157c-0.002-2.573-0.657-5.18-2.036-7.568c-4.53-7.848-13.916-27.068-3.229-36.035
        // c6.917-5.805,19.994-5.805,26.909,0c10.687,8.967,1.302,28.186-3.229,36.035c-1.379,2.388-2.035,4.995-2.036,7.568
        // c-0.002,5.245,2.72,10.346,7.589,13.157c7.258,4.189,16.534,1.702,20.725-5.553c4.53-7.848,16.489-25.586,29.594-20.814
        // c8.484,3.088,15.022,14.413,13.453,23.303c-2.423,13.74-23.761,15.222-32.823,15.222c-8.378,0-15.172,6.794-15.172,15.172
        // C287.556,323.348,294.35,330.141,302.728,330.141z M387.677,94.024c-0.696-0.698-1.472-1.335-2.306-1.881c-0.819-0.546-1.699-1.017-2.61-1.396
        // c-0.925-0.379-1.881-0.668-2.837-0.865c-1.957-0.394-3.975-0.394-5.932,0c-0.971,0.197-1.927,0.485-2.837,0.865
        // c-0.924,0.379-1.804,0.85-2.623,1.396c-0.834,0.545-1.61,1.182-2.308,1.881c-0.698,0.698-1.335,1.485-1.88,2.306
        // c-0.546,0.819-1.017,1.714-1.396,2.625c-0.379,0.91-0.668,1.865-0.865,2.836c-0.197,0.973-0.303,1.974-0.303,2.958
        // c0,1.001,0.106,1.989,0.303,2.974c0.197,0.973,0.485,1.928,0.865,2.837c0.379,0.912,0.85,1.79,1.396,2.61
        // c0.545,0.836,1.182,1.61,1.88,2.321c0.698,0.698,1.473,1.32,2.308,1.881c0.819,0.546,1.699,1.016,2.623,1.396
        // c0.912,0.379,1.866,0.668,2.837,0.865c0.973,0.197,1.972,0.287,2.958,0.287c0.986,0,1.989-0.091,2.974-0.287
        // c0.956-0.197,1.912-0.485,2.837-0.865c0.91-0.379,1.79-0.85,2.61-1.396c0.834-0.561,1.61-1.183,2.308-1.881
        // c0.698-0.713,1.335-1.485,1.897-2.321c0.545-0.819,1.017-1.698,1.396-2.61c0.379-0.91,0.666-1.865,0.865-2.837
        // c0.197-0.985,0.287-1.972,0.287-2.974C392.123,100.758,390.499,96.859,387.677,94.024z"
      />
    </svg>
  )
}
export default Bin
