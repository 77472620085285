// import styles from './SplashScreen.module.scss'
import React, { Component } from 'react'
import { useState, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import ProgressBar from './ProgressBar'

const SplashScreen = ({ onChange = undefined }) => {
  const [brand, setBrand] = useState(true)
  const opacities = useSpring({ opacity: brand ? 1 : 0, config: { duration: 2500 } })
  useEffect(() => {
    setBrand(true)
  }, [])

  const handleChange = () => {
    // console.log('handleChange SplashScreen')
    onChange(true)
  }

  return (
    <>
      <div className="splashscreen">
        <div className="logo">
          <animated.div className="logo-text" style={opacities}>
            {/* <animated.div className="logo-box" style={opacities}> */}
            {/* <div className="http_address">{pageLocation()}</div> */}
            {'Tchitchat Web App loading ...'}
            {/* <div className="logo" style={{ background Image: `url(${config.logo})` }}></div> */}
          </animated.div>
        </div>

        <div className="splitbar"></div>
        <div className="version">
          Automatic install <br />
          New PWA version: {process.env.REACT_APP_VERSION}
        </div>

        <div className="">
          <br />
        </div>
        <ProgressBar onChange={() => handleChange()} />
        <div className=""></div>

        <div className="logo"></div>
      </div>
    </>
  )
}

export default SplashScreen
