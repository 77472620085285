import React from 'react'

export const Arrowup = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 5 93.17 81.9"
      transform="rotate(180)"
    >
      <defs>
        <linearGradient id={props.id} x1="100" y1="100" x2="100" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M78.9,37.8l-30-30.6C48.1,6.4,47.1,6,46,6s-2.1,0.4-2.9,1.2l-30,30.6c-1.1,1.1-1.5,3.1-0.8,4.5
        c0.6,1.5,2.1,2.6,3.7,2.6h13v37c0,2.2,2.3,4,4.5,4h25c2.2,0,4.5-1.8,4.5-4V45h13c1.6,0,3.1-1.2,3.7-2.6C80.3,40.9,80,39,78.9,37.8z
         M58.5,37c-2.2,0-3.5,1.4-3.5,3.6V78H37V40.6c0-2.2-1.3-3.6-3.5-3.6h-8L46,16.1L66.5,37H58.5z"
      />
    </svg>
  )
}
export default Arrowup
