import React from 'react'

export const SoundCloud = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-35 -40 570.5 600"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6
        C504,57,447.2,0.2,377.6,0.2z M99.6,305c0,0.8-0.8,1.2-1.2,1.2c-0.8,0-1.2-0.4-1.2-1.2l-2.4-18.4l2.4-18.8c0-0.8,0.8-1.2,1.2-1.2
        c0.8,0,1.2,0.4,1.2,1.2l2.8,18.8L99.6,305z M111.2,316.2c0,0.8-0.8,1.2-1.2,1.2c-0.8,0-1.2-0.4-1.2-1.2l-3.2-29.6l3.2-30.4
        c0-0.8,0.8-1.2,1.2-1.2c0.8,0,1.2,0.4,1.2,1.2l3.6,30.4L111.2,316.2z M124,321.4c0,0.8-0.8,1.6-1.6,1.6c-0.8,0-1.6-0.8-1.6-1.6
        l-2.8-34.8l2.8-36c0-0.8,0.8-1.6,1.6-1.6s1.6,0.8,1.6,1.6l3.2,36L124,321.4z M136.8,322.6c0,1.2-0.8,2-2,2c-1.2,0-1.6-0.8-2-2
        L130,287l2.8-36.8c0-1.2,0.8-2,2-2c1.2,0,1.6,0.8,2,2L140,287L136.8,322.6z M149.6,323c0,1.2-0.8,2.4-2,2.4c-1.2,0-2-0.8-2-2.4
        l-2.8-36l2.8-34.4c0-1.2,0.8-2.4,2-2.4c1.2,0,2,0.8,2,2.4l2.8,34.4L149.6,323z M164,323c0,1.6-2,2.4-3.2,2.4
        c-1.2,0-2.8-1.2-2.8-2.4l-2.8-36l2.4-56c0-1.6,1.2-2.4,2.4-2.4s2.4,1.2,2.4,2.4l3.6,56L164,323z M176,322.6c0,1.6-1.6,2.8-2.8,2.8
        c-1.6,0-2.8-1.2-2.8-2.8l-2.4-36l2.4-68.8c0-1.6,1.2-2.8,2.8-2.8c1.6,0,2.4,1.2,2.8,2.8l2.8,68.8L176,322.6z M188,322.2
        c0,1.6-1.2,3.2-2.8,3.2c-1.6,0-2.8-1.2-2.8-3.2l-2-35.6l2-74.4c0-1.6,1.2-3.2,2.8-3.2c1.6,0,2.8,1.2,2.8,3.2l2,74.4L188,322.2z
         M201.6,322.2c0,2-1.6,3.2-3.2,3.2c-1.6,0-3.2-1.6-3.2-3.2l-2-35.2l2-76.8c0-2,1.6-3.2,3.2-3.2s3.2,1.6,3.2,3.2l2.4,76.4
        L201.6,322.2z M215.2,321.8c0,2-1.6,3.6-3.6,3.6s-3.2-1.6-3.6-3.6l-2-34.8l2-75.2c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6l2,74.8
        L215.2,321.8z M228,321.4c0,2-1.6,4-3.6,4s-3.6-1.6-3.6-4l-1.6-34.8l1.6-72c0-2.4,1.6-4,3.6-4s3.6,1.6,3.6,4l1.6,72L228,321.4z
         M244,321.4c0,2.4-2.8,4.4-4.8,4.4s-4.4-2-4.4-4.4l-1.6-34.4l1.2-86c0-2.4,1.6-4.4,4-4.4c2,0,4,2,4,4.4l2.8,86L244,321.4z
         M256,320.6c0,2.4-2.4,4.4-4.4,4.4c-2.4,0-4.4-2-4.4-4.4l-1.6-34l1.6-93.6c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4l2,93.6
        L256,320.6z M370.4,325.4c-0.8,0-106.8,0-106.8,0c-2.4-0.4-3.6-2.4-3.6-4.8v-132c0-2.4,0.4-3.6,3.2-4.8c7.6-3.2,16-4.8,24.4-4.8
        c35.6,0,64.8,29.2,68,66.8c4.4-2,9.6-3.2,14.8-3.2c21.2,0,38.8,18.8,38.8,41.6C409.2,307,392,325.4,370.4,325.4z"
      />
    </svg>
  )
}
export default SoundCloud
