import React from 'react'

export const Arrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 25 19"
    >
      <defs>
        <linearGradient id={props.id} x1="25" y1="25" x2="25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M22,12a1,1,0,0,1-1,1H5.414l5.293,5.293a1,1,0,1,1-1.414,1.414l-7-7a1,1,0,0,1,0-1.414l7-7a1,1,0,1,1,1.414,1.414L5.414,11H21A1,1,0,0,1,22,12Z"
      />
    </svg>
  )
}
export default Arrow
