import { Client } from './clients'

// const client_name = 'tchitchat-dev'
const client_name = process.env.REACT_APP_CLIENT_NAME

const config = {
  slug: client_name,
  name: Client(client_name).name,
  // logo: require(`./${client_name}.png`),
  apis: {
    baseURL: Client(client_name).apis.baseURL,
    version: Client(client_name).apis.version,
  },
  admin_url: Client(client_name).admin_url,
  raw_url_addr: Client(client_name).raw_url_addr,
  firebase_configuration: Client(client_name).firebase_configuration,
  firebase_public_key: Client(client_name).firebase_public_key,
  colors: {
    primary: Client(client_name).colors.primary,
    secondary: Client(client_name).colors.secondary,
    third: Client(client_name).colors.third,
    fourth: Client(client_name).colors.fourth,
  },
}

export default config
