import React, { useReducer } from 'react'
import { ProfileContext } from './ProfileContext'
import ProfileReducer from './ProfileReducer'

function getLocalStorage(key, initialValue) {
  try {
    const value = window.localStorage.getItem(key)
    return value ? JSON.parse(value) : initialValue
  } catch (e) {
    // if error, return initial value
    return initialValue
  }
}
function getSessionStorage(key, initialValue) {
  try {
    const value = window.sessionStorage.getItem(key)
    return value ? JSON.parse(value) : initialValue
  } catch (e) {
    // if error, return initial value
    return initialValue
  }
}
const ProfileState = ({ children }) => {
  const initialState = {
    fetching: getSessionStorage('fetching', false),
    error: getSessionStorage('error', false),
    profile: getSessionStorage('profile', false),
    message: getSessionStorage('message', ''),
    currentProfile: getSessionStorage('currentProfile', []),
    currentCardList: getSessionStorage('currentCardList', []),
    connection: getSessionStorage('connection', false),
    internet: getSessionStorage('internet', false),
    offline: getSessionStorage('offline', false),
    route: getSessionStorage('route', ''),
    exp: getSessionStorage('exp', 0),
    originalNetlinks: getSessionStorage('originalNetlinks', []),
    batchName: getSessionStorage('batchName', ''),
    count: getSessionStorage('count', 0),
    fileName: getSessionStorage('fileName', ''),
    fileContent: getSessionStorage('fileContent', ''),
    // From google firebase :
    token: '',
    role: getLocalStorage('role', 'anon'),
    displayName: getLocalStorage('displayName', '#Display Name#'),
    email: getLocalStorage('email', '<email>'),
    uid: getLocalStorage('uid', ''),
    accessToken: getSessionStorage('accessToken', ''),
    providerId: getLocalStorage('providerId', ''),
    photoURL: getLocalStorage('photoURL', ''),
    online: getLocalStorage('online', false),
    onlineExpiry: getLocalStorage('onlineExpiry', 0),
    // From rest db api :
    displayPseudo: getLocalStorage('displayPseudo', '<Pseudo Name>'),
    cardGuid: getLocalStorage('cardGuid', ''),
    netbio: getLocalStorage('netbio', '<Netbio>'),
    photo: getLocalStorage('photo', '<Photo>'),
    photoFileType: getLocalStorage('photoFileType', '<Photo file type>'),
    expiryTime: getLocalStorage('expiryTime', 0),
    typeContract: getLocalStorage('typeContract', ''),
    ownerKey: getLocalStorage('ownerKey', ''),
    expirationDate: getLocalStorage('expirationDate', '2022-05-18T07:10:19.000Z'),
    accountHistory: getLocalStorage('accountHistory', []),
    netlinks: getLocalStorage('netlinks', []),
    balance: getLocalStorage('balance', 0.0),
  }

  const [state, dispatch] = useReducer(ProfileReducer, initialState)

  return (
    <ProfileContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}
export default ProfileState
