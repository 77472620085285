import React from 'react'

export const Lydia = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-2 -1 27.51 26"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.895 17.611a.421.421 0 0 1-.168.035h-1.155a.608.608 0 0 1-.56-.377l-4-9.613-3.991 9.607a.61.61 0 0 1-.56.377H6.273a.42.42 0 0 1-.385-.59L10.91 5.575a.793.793 0 0 1 .726-.475h.748a.792.792 0 0 1 .726.48l5.003 11.482a.42.42 0 0 1-.218.549z"
      />
    </svg>
  )
}
export default Lydia
