import React from 'react'

export const Gueststar = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1.0005 12.072 592.7 525.1"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M548.829,212.313c-1.004-3.09-3.884-5.182-7.133-5.182H343.101L281.732,18.255c-1.004-3.09-3.884-5.183-7.133-5.183
        s-6.129,2.092-7.133,5.183l-61.37,188.876H7.5c-3.249,0-6.129,2.092-7.133,5.182s0.096,6.475,2.725,8.385l85.935,62.436
        c-6.444,12.273-10.097,26.232-10.097,41.03c0,3.589,0.22,7.126,0.637,10.604c-0.246,1.513-0.028,3.083,0.655,4.487
        c1.665,9.658,4.895,18.787,9.425,27.111c0.302,1.334,0.965,2.604,2.004,3.643c0.068,0.068,0.141,0.126,0.21,0.19
        c4.508,7.379,10.066,14.048,16.463,19.803c0.267,0.397,0.574,0.776,0.925,1.127c0.622,0.623,1.33,1.104,2.081,1.462
        c7.049,5.797,15.006,10.528,23.622,13.944c0.857,0.535,1.796,0.88,2.762,1.037c0.97,0.348,1.947,0.679,2.933,0.993L102.39,526.307
        c-1.004,3.09,0.096,6.475,2.725,8.385c2.629,1.91,6.188,1.91,8.816,0L274.599,417.96l160.667,116.732
        c1.314,0.955,2.861,1.433,4.408,1.433s3.094-0.478,4.408-1.433c2.629-1.91,3.729-5.295,2.725-8.385l-38.256-117.741
        c35.282-11.25,61.03-44.051,61.697-82.858c0.382-1.36,0.377-2.806-0.022-4.162c-0.284-9.713-2.136-19.041-5.317-27.733
        c-0.098-0.334-0.226-0.66-0.371-0.98c-1.265-3.333-2.725-6.57-4.367-9.697l85.935-62.436
        C548.734,218.788,549.833,215.404,548.829,212.313z M185.742,275.918c1.465,1.464,3.384,2.197,5.304,2.197
        c1.919,0,3.839-0.732,5.304-2.196l12.536-12.536c3.436,2.347,6.654,4.985,9.639,7.865L114.58,375.192
        c-2.88-2.985-5.518-6.203-7.865-9.639l69.947-69.947c2.929-2.929,2.929-7.678,0-10.606c-2.93-2.929-7.678-2.929-10.607,0
        l-66.741,66.741c-1.75-4.311-3.105-8.82-4.015-13.49l86.284-86.284c4.67,0.909,9.179,2.265,13.49,4.014l-9.331,9.331
        C182.813,268.24,182.813,272.989,185.742,275.918z M153.352,396.351l86.332-86.332c0.708,3.618,1.147,7.329,1.302,11.114
        l-76.519,76.519C160.681,397.499,156.97,397.059,153.352,396.351z M237.921,345.412c-7.105,23.504-25.673,42.072-49.177,49.177
        L237.921,345.412z M137.278,391.211c-3.93-1.779-7.683-3.88-11.211-6.293l102.182-102.182c2.413,3.528,4.515,7.28,6.293,11.211
        L137.278,391.211z M94.197,318.138c2.916-35.763,31.509-64.355,67.272-67.272L94.197,318.138z M425.409,508.989L279.007,402.621
        c-2.629-1.909-6.188-1.909-8.816,0L123.788,508.989l31.548-97.097c3.977,0.548,8.035,0.838,12.16,0.838
        c36.605,0,68.094-22.322,81.57-54.069c3.906-0.261,6.995-3.503,6.995-7.475v-24.854c0.614-1.621,0.645-3.407,0.096-5.046
        c0.951-9.335,8.859-16.644,18.441-16.644c10.221,0,18.536,8.315,18.536,18.537v28.007c0,3.972,3.089,7.214,6.995,7.475
        c1.356,3.195,2.897,6.294,4.606,9.285c0.174,0.363,0.378,0.715,0.614,1.052c4.907,8.324,11.131,15.784,18.391,22.081
        c0.016,0.016,0.03,0.034,0.046,0.05c0.302,0.302,0.627,0.564,0.963,0.804c7.557,6.361,16.188,11.482,25.579,15.051
        c0.341,0.157,0.688,0.293,1.045,0.396c9.372,3.426,19.48,5.31,30.016,5.346c0.028,0,0.055,0.004,0.083,0.004
        c0.016,0,0.032-0.002,0.048-0.002c0.06,0,0.12,0.002,0.18,0.002c4.125,0,8.182-0.29,12.158-0.838L425.409,508.989z M409.517,256.076
        l-9.235,9.236c-2.929,2.929-2.929,7.678,0.001,10.607c1.464,1.464,3.384,2.196,5.303,2.196s3.839-0.732,5.304-2.197l12.396-12.396
        c3.427,2.357,6.64,5.001,9.615,7.889L328.948,375.363c-2.888-2.975-5.532-6.188-7.889-9.615l70.142-70.142
        c2.929-2.929,2.929-7.678,0-10.606c-2.93-2.929-7.678-2.929-10.607,0l-66.98,66.98c-1.762-4.296-3.133-8.791-4.059-13.446
        l86.517-86.517C400.726,252.943,405.221,254.314,409.517,256.076z M378.449,397.648c-3.61-0.158-7.15-0.583-10.608-1.246
        l86.099-86.098c0.662,3.458,1.087,6.998,1.245,10.608L378.449,397.648z M340.468,385.057l102.125-102.125
        c2.404,3.539,4.492,7.305,6.259,11.246l-97.138,97.138C347.773,389.548,344.007,387.46,340.468,385.057z M308.375,318.499
        c2.762-36.053,31.609-64.899,67.661-67.661L308.375,318.499z M402.63,394.68l49.587-49.586
        C445.152,368.854,426.391,387.615,402.63,394.68z M452.083,270.47c-0.807-1.055-1.635-2.091-2.488-3.108
        c-0.266-0.395-0.57-0.771-0.919-1.12c-0.045-0.045-0.094-0.083-0.14-0.126c-6.252-7.188-13.644-13.358-21.887-18.234
        c-0.416-0.303-0.855-0.557-1.312-0.763c-9.325-5.302-19.701-8.959-30.731-10.577c-0.125-0.018-0.251-0.041-0.377-0.053
        c-4.094-0.582-8.275-0.89-12.527-0.89c-39.823,0-73.588,26.421-84.696,62.653c-5.944-5.348-13.801-8.609-22.407-8.609
        c-8.606,0-16.464,3.261-22.408,8.61c-11.107-36.232-44.872-62.654-84.695-62.654c-28.663,0-54.184,13.69-70.382,34.871
        l-66.531-48.338h180.962c3.249,0,6.129-2.093,7.133-5.183l55.921-172.105l55.92,172.105c1.004,3.09,3.884,5.183,7.133,5.183h180.963
        L452.083,270.47z"
      />
    </svg>
  )
}
export default Gueststar
