import React from 'react'

export const Wechat = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 1.72 34 35.76"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M31.385 16.995c-0.807-1.839-2.344-3.297-4.12-4.198-3.141-1.589-7.042-1.583-10.172 0.042-2.177 1.115-3.99 3.115-4.505 5.547-0.427 1.792-0.047 3.719 0.906 5.281 1.417 2.349 3.974 3.849 6.63 4.328 1.922 0.391 3.911 0.161 5.781-0.375 1.125 0.432 2.125 1.135 3.214 1.661-0.281-0.943-0.583-1.875-0.906-2.807 1.224-0.865 2.328-1.948 2.995-3.302 0.99-1.885 1.052-4.229 0.177-6.177zM18.156 4.375c-3.328-1.87-7.469-2.151-11.047-0.859-2.354 0.849-4.479 2.422-5.797 4.563-1.193 1.932-1.641 4.344-1.063 6.552 0.573 2.453 2.281 4.51 4.349 5.885-0.401 1.135-0.781 2.266-1.141 3.406 1.297-0.677 2.599-1.391 3.901-2.078 1.557 0.5 3.219 0.745 4.88 0.688-0.443-1.255-0.542-2.62-0.339-3.932 0.297-1.828 1.297-3.5 2.656-4.74 2.302-2.156 5.563-3.063 8.661-2.844-0.583-2.844-2.583-5.25-5.099-6.641zM20.469 17.693c-0.281 0.87-1.542 1.13-2.156 0.469-0.672-0.609-0.411-1.891 0.474-2.161 0.979-0.411 2.109 0.714 1.682 1.693zM26.859 17.818c-0.339 0.781-1.521 0.964-2.099 0.359-0.281-0.255-0.359-0.641-0.458-0.974 0.135-0.609 0.557-1.245 1.24-1.271 0.938-0.13 1.781 1.031 1.297 1.885zM17.318 9.214c0.010 1.281-1.698 2.078-2.656 1.208-1-0.714-0.714-2.448 0.453-2.807 1.047-0.417 2.286 0.458 2.203 1.583zM9.24 9.536c-0.229 1.115-1.719 1.651-2.594 0.906-1.010-0.714-0.729-2.484 0.458-2.844 1.167-0.448 2.49 0.734 2.135 1.938z"
      />
    </svg>
  )
}
export default Wechat
