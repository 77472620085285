// Inspiration from Arthur M. thx.
import { FC, useState, createContext, useContext } from 'react'

interface INavbarContext {
  //
  navbarTitle?: string
  setNavbarTitle: (title: string) => void
  //
  titleIcon?: string
  setTitleIcon: (icon: string) => void
  //
  absoluteWidth?: string
  setAbsoluteWidth: (absoluteWidth: string) => void
  //
  absoluteHeight?: string
  setAbsoluteHeight: (absoluteHeight: string) => void
  //
  pullDown?: boolean
  setPullDown: (pullDown: boolean) => void
  //
  withoutNavbar?: boolean
  setWithoutNavbar: (withoutNavbar: boolean) => void
  //
  withoutTopnav?: boolean
  setWithoutTopnav: (withoutTopnav: boolean) => void
  //
  iconTopLeft?: string
  setIconTopLeft: (iconLeft: string) => void
  //
  iconTopLeftDown?: string
  setIconTopLeftDown: (iconLeftDown: string) => void
  //
  iconTopRight?: string
  setIconTopRight: (iconRight: string) => void
  //
  badgeTopRight?: number
  setBadgeTopRight: (calls: number) => void
  //
  onlineBadge?: boolean
  setOnlineBadge: (onlineBadge: boolean) => void
  //
  cancelRedir?: string
  setCancelRedir: (redir: string) => void
}

const NavbarContext = createContext<INavbarContext>({
  //
  navbarTitle: undefined,
  setNavbarTitle: () => {},
  //
  titleIcon: undefined,
  setTitleIcon: () => {},
  //
  absoluteWidth: undefined,
  setAbsoluteWidth: () => {},
  //
  absoluteHeight: undefined,
  setAbsoluteHeight: () => {},
  //
  pullDown: false,
  setPullDown: () => {},
  //
  withoutNavbar: false,
  setWithoutNavbar: () => {},
  //
  withoutTopnav: false,
  setWithoutTopnav: () => {},
  //
  iconTopLeft: undefined,
  setIconTopLeft: () => {},
  //
  iconTopLeftDown: undefined,
  setIconTopLeftDown: () => {},
  //
  iconTopRight: undefined,
  setIconTopRight: () => {},
  //
  badgeTopRight: undefined,
  setBadgeTopRight: () => {},
  //
  onlineBadge: false,
  setOnlineBadge: () => {},
  //
  cancelRedir: undefined,
  setCancelRedir: () => {},
})

export const NavbarProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [navbarTitle, setNavbarTitle] = useState<string>()
  const [titleIcon, setTitleIcon] = useState<string>()
  const [absoluteWidth, setAbsoluteWidth] = useState<string>()
  const [absoluteHeight, setAbsoluteHeight] = useState<string>()
  const [pullDown, setPullDown] = useState<boolean>()
  const [withoutNavbar, setWithoutNavbar] = useState<boolean>()
  const [withoutTopnav, setWithoutTopnav] = useState<boolean>()
  const [iconTopLeft, setIconTopLeft] = useState<string>()
  const [iconTopLeftDown, setIconTopLeftDown] = useState<string>()
  const [iconTopRight, setIconTopRight] = useState<string>()
  const [badgeTopRight, setBadgeTopRight] = useState<number>()
  const [onlineBadge, setOnlineBadge] = useState<boolean>()
  const [cancelRedir, setCancelRedir] = useState<string>()

  return (
    <NavbarContext.Provider
      value={{
        navbarTitle,
        setNavbarTitle,
        titleIcon,
        setTitleIcon,
        absoluteWidth,
        setAbsoluteWidth,
        absoluteHeight,
        setAbsoluteHeight,
        pullDown,
        setPullDown,
        withoutNavbar,
        setWithoutNavbar,
        withoutTopnav,
        setWithoutTopnav,
        iconTopLeft,
        setIconTopLeft,
        iconTopLeftDown,
        setIconTopLeftDown,
        iconTopRight,
        setIconTopRight,
        badgeTopRight,
        setBadgeTopRight,
        onlineBadge,
        setOnlineBadge,
        cancelRedir,
        setCancelRedir,
      }}
    >
      {children}
    </NavbarContext.Provider>
  )
}

export const useNavbar = () => useContext(NavbarContext)
