import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// COMPONENTS
import Icon from '../components/Layout/Icon'
//AUTH
import { useAuth } from './../auth/authProvider'
// ICONS
import Profile from './../icons/Profile'
import Tuto from './../icons/Tuto'
import Eiffel from './../icons/Eiffel'
import Gears from '../icons/Gears'
import Signin from './../icons/Signin'
// HOOKS
import { useNavbar } from './../hooks/useNavbar.tsx'
// CONFIG
import config from './../config'
// CONTEXT
import { FormattedMessage, useIntl } from 'react-intl'
// IMAGES
import HomeImage from './../images/TchitchatBrandHome.png'

const PullHome = () => {
  const intl = useIntl()
  const {
    navbarTitle,
    setNavbarTitle,
    setTitleIcon,
    setAbsoluteWidth,
    setAbsoluteHeight,
    setIconTopLeft,
    setIconTopLeftDown,
    setIconTopRight,
    setBadgeTopRight,
    setCancelRedir,
  } = useNavbar()

  useEffect(() => {
    setNavbarTitle(intl.formatMessage({ id: 'app.home' }))
    setTitleIcon('haunted')
    setAbsoluteWidth('28px')
    setAbsoluteHeight('28px')
    setIconTopLeft('menu')
    setIconTopLeftDown('cross')
    setIconTopRight('femalelink')
    setBadgeTopRight(1)
    setCancelRedir('/home')
  })

  return (
    <div className="inner innerMediumWidth innerBottomMenu">
      <div className="rowbox">
        <div className="colbox">
          <div className="title-box">
            <div className="title-image">
              <div className="main-image">
                <img
                  key={'img-home'}
                  src={HomeImage}
                  alt="tchitchat brand"
                  className="img-brand"
                  width="96px"
                  height="96px"
                />
              </div>
            </div>
            <div className="title-frame">
              {/* <Icon
                iconId={'rose-pullhome'}
                iconTitle={'diamond'}
                topColor={'white'}
                bottomColor={config.colors.primary}
                iconWidth={'38px'}
                iconHeight={'38px'}
              /> */}
              <div className="title"> {navbarTitle} </div>
            </div>
          </div>

          <div className="buttoncol">
            {/* ////////// Tchitchat APP ? */}
            <div className="button-row">
              <div className="button btn-menu">
                <div className="barrette"></div>
                <div className="icon">
                  <Eiffel
                    id="eiffel"
                    width={32}
                    height={28}
                    top_color={config.colors.secondary}
                    bottom_color={'white'}
                  />
                </div>
                <div className="link">
                  <div
                    className="txtlink"
                    onClick={() => window.open('https://wp.tchitch.at', '_blank')}
                  >
                    Tchitchat APP ?
                  </div>
                </div>
              </div>
            </div>
            {/* ////////// Installation kit */}
            <div className="button-row">
              <div className="button btn-menu">
                <div className="barrette"></div>
                <div className="icon">
                  <Gears
                    id="contactus"
                    width={32}
                    height={32}
                    top_color={config.colors.secondary}
                    bottom_color={'white'}
                  />
                </div>
                <div className="link">
                  <div className="txtlink">
                    {/* <Link to="/settings">Installation kit</Link> */}
                    <div
                      className="txtlink"
                      onClick={() =>
                        window.open('https://wp.tchitch.at/product-category/kits/', '_blank')
                      }
                    >
                      <FormattedMessage id="app.kit" defaultMessage="Installation kit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ////////// Log out */}
            <div className="button-row">
              <div className="button btn-menu">
                <div className="barrette"></div>
                <div className="icon">
                  {/* {auth ???????????????}
                   */}

                  <Signin
                    id="signin"
                    width={30}
                    height={30}
                    top_color={config.colors.secondary}
                    bottom_color={'white'}
                  />
                </div>
                <div className="link">
                  <div className="txtlink">
                    <Link to="/login">
                      <FormattedMessage id="app.login" defaultMessage="Log in" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row-band">
              <div className="line-band">
                <div className="policy-link">
                  <h6>
                    <Link to="/terms">
                      <FormattedMessage id="app.terms" defaultMessage="Terms of Service" />
                    </Link>{' '}
                    |
                  </h6>
                </div>
                <div className="policy-link">
                  <h6>
                    &nbsp;
                    <Link to="/privacy">
                      <FormattedMessage id="app.privacy" defaultMessage="Privacy Policy " />
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PullHome
