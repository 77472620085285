// import styles from './ProgressBar.module.scss'
import React, { Component, useEffect, useState } from 'react'

const ProgressBar = ({ onChange = undefined }) => {
  const [progress, setProgress] = useState(5)

  useEffect(() => {
    const timer1 = setInterval(() => {
      setProgress((progress) => progress + ((Math.floor(Math.random() * 5) + 1) % 100))
    }, 150)
    return () => clearInterval(timer1)
  }, [])

  useEffect(() => {
    if (progress > 100) {
      // fermer la fenêtre
      onChange(true)
      setProgress(0)
    }
  }, [progress, onChange])

  return (
    <>
      <main>
        <div id="progress">Loading ... {progress} %</div>

        <div className="progress">
          <span className="progress-bar" style={{ width: progress }}></span>
        </div>
      </main>
    </>
  )
}

export default ProgressBar
