import React from 'react'

export const Reddit = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1.7 -0.5 34.92 34.54"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M12.594 19.094c-0.863 0-1.556-0.694-1.556-1.538 0-0.862 0.694-1.556 1.556-1.556 0.85 0 1.538 0.694 1.538 1.556 0 0.85-0.694 1.538-1.538 1.538zM31.5 16c0 8.563-6.938 15.5-15.5 15.5s-15.5-6.938-15.5-15.5c0-8.563 6.938-15.5 15.5-15.5s15.5 6.938 15.5 15.5zM23.231 13.425c-0.587 0-1.106 0.244-1.488 0.625-1.4-0.969-3.287-1.594-5.381-1.663l1.087-4.894 3.463 0.781c0 0.85 0.694 1.538 1.537 1.538 0.863 0 1.556-0.706 1.556-1.556s-0.694-1.556-1.556-1.556c-0.606 0-1.125 0.363-1.381 0.863l-3.825-0.85c-0.188-0.050-0.381 0.088-0.431 0.275l-1.194 5.4c-2.075 0.088-3.944 0.706-5.344 1.675-0.381-0.4-0.919-0.637-1.506-0.637-2.181 0-2.894 2.931-0.9 3.925-0.069 0.313-0.106 0.637-0.106 0.969 0 3.287 3.7 5.95 8.25 5.95 4.569 0 8.269-2.663 8.269-5.95 0-0.331-0.038-0.675-0.119-0.988 1.956-1 1.237-3.906-0.931-3.906zM18.925 20.688c-1.137 1.137-4.756 1.119-5.85 0-0.137-0.137-0.381-0.137-0.519 0-0.156 0.156-0.156 0.4 0 0.538 1.425 1.425 5.456 1.425 6.888 0 0.156-0.138 0.156-0.381 0-0.538-0.138-0.137-0.381-0.137-0.519 0zM19.406 16c-0.85 0-1.538 0.694-1.538 1.556 0 0.85 0.694 1.538 1.538 1.538 0.863 0 1.556-0.694 1.556-1.538 0-0.862-0.688-1.556-1.556-1.556z"
      />
    </svg>
  )
}
export default Reddit
