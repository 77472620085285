import { useEffect, useState } from 'react'

export default function useFetch(url) {
  const [data, setData] = useState(null)
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url)
      if (!response.ok) {
        // oups! something went wrong
        return
      }

      const data = await response.json()
      console.log('data :', data)
      setData(data)
    }

    loadData()
  }, [url])
  return data
}
