import React from 'react'

export const Slack = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 -1 34 35.76"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M6.724 20.219c-0.005 1.855-1.505 3.36-3.36 3.365-1.859-0.005-3.359-1.505-3.364-3.365 0.005-1.855 1.511-3.355 3.364-3.359h3.36zM8.416 20.219c0.005-1.855 1.511-3.355 3.365-3.359 1.849 0.004 3.355 1.504 3.359 3.359v8.417c-0.004 1.853-1.504 3.359-3.359 3.364-1.86-0.005-3.36-1.511-3.365-3.364zM11.781 6.724c-1.855-0.005-3.36-1.505-3.365-3.36 0.005-1.853 1.505-3.359 3.365-3.364 1.855 0.005 3.355 1.511 3.359 3.364v3.36zM11.781 8.416c1.855 0.005 3.355 1.505 3.359 3.365-0.004 1.855-1.504 3.355-3.359 3.359h-8.417c-1.853-0.004-3.359-1.504-3.364-3.359 0.005-1.86 1.511-3.36 3.364-3.365zM25.276 11.781c0.005-1.86 1.505-3.36 3.36-3.365 1.853 0.005 3.359 1.505 3.364 3.365-0.005 1.855-1.511 3.355-3.364 3.359h-3.36zM23.584 11.781c-0.005 1.855-1.511 3.355-3.365 3.359-1.855-0.004-3.355-1.509-3.359-3.359v-8.417c0.004-1.853 1.504-3.359 3.359-3.364 1.86 0.005 3.36 1.505 3.365 3.364zM20.219 25.276c1.86 0 3.36 1.505 3.365 3.36-0.005 1.859-1.505 3.359-3.365 3.364-1.855-0.005-3.355-1.511-3.359-3.364v-3.36zM20.219 23.584c-1.855-0.005-3.355-1.511-3.359-3.365 0.004-1.855 1.504-3.355 3.359-3.359h8.417c1.853 0.004 3.359 1.504 3.364 3.359-0.005 1.86-1.505 3.36-3.364 3.365z"
      />
    </svg>
  )
}
export default Slack
