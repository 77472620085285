import React from 'react'

export const Vcard = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 -3 34 35.75"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M4.333,3.062C1.94,3.062,0,5.002,0,7.396v17.208c0,2.394,1.94,4.333,4.333,4.333h23.333c2.393,0,4.333-1.939,4.333-4.333
		V7.396c0-2.393-1.94-4.333-4.333-4.333L4.333,3.062L4.333,3.062z M19.236,23.553c0,0.649-0.525,1.174-1.174,1.174H5.652
		c-0.649,0-1.174-0.526-1.174-1.174v-1.031c0-2.054,1.665-3.719,3.719-3.719h0.96c0.498,0,0.902-0.404,0.902-0.902
		c0-0.185-0.075-0.362-0.207-0.491c-0.55-0.535-1.014-1.252-1.352-2.025c-0.058,0.033-0.12,0.052-0.186,0.052
		c-0.539,0-1.172-1.191-1.172-2.003s0.075-1.47,0.614-1.47c0.046,0,0.096,0.011,0.145,0.02c0.039-2.2,0.449-4.942,3.957-4.942
		c3.363,0,3.919,2.748,3.958,4.95c0.069-0.018,0.137-0.028,0.202-0.028c0.539,0,0.613,0.658,0.613,1.47s-0.633,2.003-1.172,2.003
		c-0.084,0-0.157-0.036-0.227-0.088c-0.34,0.788-0.809,1.519-1.368,2.062c-0.132,0.129-0.207,0.307-0.207,0.491
		c0,0.498,0.404,0.902,0.902,0.902h0.96c2.054,0,3.719,1.665,3.719,3.719v1.031H19.236z M27.523,22.224
		c0,0.48-0.391,0.87-0.871,0.87h-6.336v-0.572c0-0.937-0.273-1.81-0.739-2.549h7.075c0.481,0,0.871,0.39,0.871,0.87V22.224z
		 M27.523,16.537c0,0.481-0.391,0.87-0.871,0.87H15.818V16.58c0.025-0.045,0.054-0.086,0.079-0.132
		c0.837-0.26,1.445-1.232,1.69-2.162h9.065c0.48,0,0.869,0.39,0.869,0.87v1.381H27.523z M27.523,10.851
		c0,0.481-0.391,0.87-0.871,0.87h-9.277c-0.137-0.267-0.303-0.489-0.525-0.626c-0.09-1.012-0.305-1.83-0.604-2.495h10.407
		c0.481,0,0.871,0.39,0.871,0.87V10.851z"
      />
    </svg>
  )
}
export default Vcard
