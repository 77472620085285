import React from 'react'

export const Webpro = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-15 -30 380.4 400"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M340.114,140.296 173.994,0 7.857,140.296 7.857,171.313 33.531,171.27 33.531,347.971 135.409,347.971 
		135.409,248.525 212.548,248.525 212.548,347.971 314.428,347.971 314.428,171.27 340.114,171.313 	z"
      />
    </svg>
  )
}
export default Webpro
