import api from '../utils/api'

///////////////////////////////////////////////////////////////////////////
// CORE Functions mecanism. ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

// Set Loading
export const setFetching = (dispatch, status) =>
  dispatch({
    type: 'SET_FETCHING',
    payload: status,
  })
// Set Error
export const setError = (dispatch, error) => {
  dispatch({
    type: 'SET_ERROR',
    payload: {
      error: error,
      message: '',
    },
  })
}
// Set internet offline
export const setInternet = async (internet, state, dispatch) => {
  dispatch({
    type: 'SET_INTERNET',
    payload: { internet },
  })
}
// Set user datas from Firebase
export const setUser = async (user, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  let userDatas = user
  dispatch({
    type: 'SET_USER',
    payload: userDatas,
  })
}
// Set route
export const setRoute = (route, dispatch) => {
  dispatch({
    type: 'SET_ROUTE',
    payload: route,
  })
}
// Get User token
export const getToken = async (state, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  const body = { uid: state.uid }
  const accessToken = window.sessionStorage.getItem('accessToken')

  if (accessToken && accessToken.length < 1) {
    dispatch({
      type: 'SET_ERROR',
      payload: {
        error: '400',
        message: 'No accessToken, empty bearer',
      },
    })
  } else {
    await api
      .post(
        '/token',
        { data: body },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${JSON.parse(accessToken)}`,
          },
        }
      )
      .then((res) => {
        // set user info
        const result = res.data
        //
        // TODO inject payload cookie 'role'
        // const result = cookie 'payload' .role ...
        //
        dispatch({
          type: 'GET_TOKEN',
          payload: result,
        })
      })
      .catch((error) => {
        const result = error
        dispatch({
          type: 'SET_ERROR',
          payload: {
            error: error.response.status,
            message: result,
          },
        })
      })
  }
}
// Set User (get user info)
export const getLinks = async (state, dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Uid': state.uid,
    },
  }

  // Fetching true
  setFetching(dispatch, true)

  // do fetch, api = axios + config/client
  await api

    .get(`/`, { withCredentials: true }, config)

    .then((res) => {
      // set user info
      const result = res.data
      dispatch({
        type: 'GET_LINKS',
        payload: result,
      })
    })
    .catch((error) => {
      // set error if has any
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: true,
          message: result,
        },
      })
    })
}
// Set User (get user info)
export const getProfile = async (state, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  const body = { uid: state.uid }
  await api
    .post(
      '/profile',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      // set user info
      const result = res.data
      dispatch({
        type: 'GET_PROFILE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Get profile info/card
export const fetchProfile = async (card_guid, state, dispatch) => {
  setFetching(dispatch, true)
  const body = { card_guid: card_guid }
  await api
    .post(
      '/getprofile',
      { data: body },
      {
        // withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'FETCH_PROFILE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// get a refreshed token
export const getRefresh = async (state, dispatch) => {
  const body = { uid: state.uid }
  await api
    .post(
      '/refresh',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'GET_REFRESH',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set User logout
export const setLogout = async (state, dispatch) => {
  const body = { uid: state.uid }
  await api
    .post(
      '/logout',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'SET_LOGOUT',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set revoke device
export const setRevoke = async (state, dispatch) => {
  dispatch({
    type: 'SET_REVOKE',
    payload: null,
  })
}
// Set license
export const setLicense = async (license, state, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  const body = { uid: state.uid, license: license }
  const accessToken = window.sessionStorage.getItem('accessToken')
  await api
    .post(
      '/license',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${JSON.parse(accessToken)}`,
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      // set license
      const result = res.data
      dispatch({
        type: 'SET_LICENSE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set license
export const getLicenses = async (numberLicenses, batchName, duration, state, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  const body = {
    uid: state.uid,
    numberLicenses: numberLicenses,
    batchName: batchName,
    duration: duration,
  }
  await api
    .post(
      '/generate',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      // set license
      const result = res.data
      dispatch({
        type: 'GET_LICENSES',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set profile
export const setProfile = async (displayPseudo, netbio, photo, photoFileType, state, dispatch) => {
  // Fetching true
  console.log(' ACTIONS setProfile', displayPseudo, netbio, photo, photoFileType)
  setFetching(dispatch, true)
  const formData = new FormData()
  formData.append('displayPseudo', displayPseudo)
  formData.append('netbio', netbio)
  formData.append('photoFileType', photoFileType)
  formData.append('file', photo)
  await api
    .post('/setprofile', formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json, multipart/form-data',
        'X-Uid': state.uid,
      },
    })
    .then((res) => {
      // set license
      const result = res.data
      dispatch({
        type: 'SET_PROFILE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set ONLINE
export const setOnline = async (online, state, dispatch) => {
  const body = { uid: state.uid, online: online }
  await api
    .post(
      '/setonline',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'SET_ONLINE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set OFFLINE
export const setOffline = async (state, dispatch) => {
  const body = { uid: state.uid, online: false }
  await api
    .post(
      '/setonline',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'SET_OFFLINE',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// user set stay offline
export const setUserOffline = (value, state, dispatch) => {
  let data = {
    offline: value,
  }
  dispatch({
    type: 'SET_STAY_OFFLINE',
    payload: data,
  })
}
// Delete a link
export const delLink = async (linkId, state, dispatch) => {
  const body = { uid: state.uid, linkId: linkId }
  await api
    .post(
      '/dellink',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'DEL_LINK',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Add a link
export const addLink = async (templateLinkId, state, dispatch) => {
  dispatch({
    type: 'ADD_LINK',
    payload: templateLinkId,
  })
}
// Restore links / id
export const restoreLink = (id, state, dispatch) => {
  let data = {
    id: id,
  }
  dispatch({
    type: 'RESTORE_LINK',
    payload: data,
  })
}
// set original links / id
export const originalNetlinks = async (originalNetlinksIn, state, dispatch) => {
  let data = {
    originalNetlinks: originalNetlinksIn,
  }
  dispatch({
    type: 'ORIGINAL_LINK',
    payload: data,
  })
}
// set currentProfile from db object
export const setCurrentProfile = async (profileIn, state, dispatch) => {
  let data = {
    profile: profileIn,
  }
  dispatch({
    type: 'SET_CURRENT_PROFILE',
    payload: data,
  })
}
// set currentCardList from db object
export const setCurrentCardList = async (cardListIn, state, dispatch) => {
  let data = {
    cardList: cardListIn,
  }
  dispatch({
    type: 'SET_CURRENT_CARDLIST',
    payload: data,
  })
}
// Update a link
export const updateLink = async (id, strLink, state, dispatch) => {
  let data = {
    id: id,
    strLink: strLink,
  }
  dispatch({
    type: 'UPDATE_LINK',
    payload: data,
  })
}
// Save links
export const saveLinks = async (templateLinkId, state, dispatch) => {
  let netlinks = state.netlinks.filter(
    (e) => e.templateLinkId.toString() === templateLinkId.toString()
  )
  // console.log('netlinks:', netlinks)
  const body = { uid: state.uid, netlinks: netlinks }
  await api
    .post(
      '/setlinks',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      const result = res.data
      dispatch({
        type: 'SET_LINKS',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// Set expiryTime
export const setExpiry = async (expiryTime, state, dispatch) => {
  // Fetching true
  setFetching(dispatch, true)
  const body = { uid: state.uid, expiryTime: expiryTime }
  await api
    .post(
      '/expirytime',
      { data: body },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Uid': state.uid,
        },
      }
    )
    .then((res) => {
      // set expiry time
      const result = res.data
      dispatch({
        type: 'SET_EXPIRY_TIME',
        payload: result,
      })
    })
    .catch((error) => {
      const result = error
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.response.status,
          message: result,
        },
      })
    })
}
// export other !!!
