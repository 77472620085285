import React from 'react'

export const Facetime = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-40 -400 2000 1890"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="m 1900,1278 q 20,-8 20,-30 V 32 q 0,-22 -20,-30 -8,-2 -12,-2 -12,0 -23,9 L 1280,595 V 288 Q 1280,169 1195.5,84.5 1111,0 992,0 H 288 Q 169,0 84.5,84.5 0,169 0,288 V 992 Q 0,1111 84.5,1195.5 169,1280 288,1280 h 704 q 119,0 203.5,-84.5 Q 1280,1111 1280,992 V 685 l 585,586 q 16,15 35,7 z"
      />
    </svg>
  )
}
export default Facetime
