// Reference: https://lokalise.com/blog/react-i18n-intl/
import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import French from '../lang/fr.json'
import Arabic from '../lang/ar.json'
import English from '../lang/en.json'

export const Context = React.createContext()

var local = navigator.language
let lang

// Local storage ?
let storedLanguage = localStorage.getItem('language')
if (storedLanguage !== '' && storedLanguage !== null) {
  local = storedLanguage
}

// One place to add a language :p
// and don't forget to add the corresponding language file in /lang
let languages = [
  { short: 'en', long: 'English' },
  { short: 'ar', long: 'Arabic' },
  { short: 'fr', long: 'French' },
]
const setLang = (newLocal) => {
  switch (newLocal) {
    case 'ar':
      lang = Arabic
      break
    case 'fr':
      lang = French
      break
    case 'en':
      lang = English
      break
    default:
      local = 'en'
      lang = English
      break
  }
}
setLang(local)

console.log('Language used: ' + local)
if (local !== storedLanguage) {
  localStorage.setItem('language', local)
}

let storedCurrency = localStorage.getItem('currency')
if (storedCurrency === '' || storedCurrency === null) {
  storedCurrency = 'USD'
  localStorage.setItem('currency', 'USD')
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local)
  const [currency, setCurrency] = useState(storedCurrency)
  const [messages, setMessages] = useState(lang)

  const selectLanguage = (selectedLanguage) => {
    console.log('selectedLanguage ', selectedLanguage)
    // const newLocale = e.target.value
    const newLocale = selectedLanguage
    setLocale(newLocale)
    setLang(newLocale)
    setMessages(lang)
    localStorage.setItem('language', newLocale)
  }

  function selectCurrency(e) {
    const newCurrency = e.target.value
    setCurrency(newCurrency)
    if (newCurrency === 'USD') {
      localStorage.setItem('currency', 'USD')
      setCurrency('USD')
    } else {
      localStorage.setItem('currency', 'EUR')
      setCurrency('EUR')
    }
  }

  return (
    <Context.Provider value={{ languages, locale, selectLanguage, currency, selectCurrency }}>
      <IntlProvider messages={messages} locale={locale} currency={currency}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

export default Wrapper
