import { useRef, useState } from 'react'
// CONFIG
import config from './../config'
// COMPONENTS
import Icon from './Layout/Icon'

const SelectLanguage = ({ defaultValue, options, onSelect }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? options[0])
  const [active, setActive] = useState(false)

  //   console.log(' options : ', options)

  const handleClick = () => {
    setActive((prevState) => !prevState)
  }
  const finalSelect = (e, option) => {
    e.stopPropagation()
    setActive(false)
    // for (let i = 0; i < options.length; i++) {
    //   console.log(' option : ', option)

    //   if (options[i].long === option) {
    //     onSelect(options[i].long)
    //   }
    // }
    onSelect(option)
  }
  const resetInput = () => {
    setActive(false)
  }

  const getLongSelected = (selectedValue) => {
    let longOption = ''
    for (let i = 0; i < options.length; i++) {
      if (options[i].short === selectedValue) {
        longOption = options[i].long
      }
    }
    return longOption
  }

  return (
    <div
      className="select-wrapper"
      tabIndex={'0'}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          resetInput()
        }
      }}
      onClick={() => handleClick()}
    >
      <div className={'select-lang' + (active === false ? '' : ' active')}>
        <Icon
          iconId={'arrowselect'}
          iconTitle={'arrowheavyup'}
          topColor={'white'}
          bottomColor={'white'}
          iconWidth={'16px'}
          iconHeight={'16px'}
        />
        <div className="selected-frame">
          <div className="selected-value">{getLongSelected(selectedValue)}</div>
        </div>
        <div className={'options-wrapper' + (active === false ? '' : ' active')}>
          {options.map((item, i) => (
            <div
              className={'button-lang' + (item.short === selectedValue ? ' selected' : '')}
              key={i}
              selected={item.short === selectedValue}
              onClick={(e) => {
                setSelectedValue(item.short)
                finalSelect(e, item.short)
              }}
            >
              {item.long}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default SelectLanguage
