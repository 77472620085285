import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get, set, update } from 'idb-keyval'
// SWIPER
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/navigation'
// COMPONENTS
import Icon from './../components/Layout/Icon'
import ProfileGuest from '../slides/profileguest'
import GuestLink from '../slides/guestlink'
import Photo from '../components/Photo'
import InputBox from '../components/InputBox'
import InputArea from '../components/InputArea'
// HOOKS
import { useNavbar } from './../hooks/useNavbar.tsx'
// CONTEXT
import { ProfileContext } from './../Reducers/ProfileContext'
import { fetchProfile, setFetching, setCurrentProfile } from '../Reducers/ProfileActions'
import { FormattedMessage, useIntl } from 'react-intl'

const PullGuest = (props) => {
  const { state, dispatch } = useContext(ProfileContext)
  const navigate = useNavigate()
  const swiper = useSwiper()
  const [swiperInstance, setSwiperInstance] = useState(false)
  const [id, setId] = useState(false)
  const { card_guid } = useParams()

  const [localFetching, setLocalFetching] = useState(true)
  const [profile, setProfile] = useState(true)

  const {
    setNavbarTitle,
    setTitleIcon,
    setAbsoluteWidth,
    setAbsoluteHeight,
    pullDown,
    setPullDown,
    setIconTopLeft,
    setIconTopLeftDown,
    setIconTopRight,
    setBadgeTopRight,
  } = useNavbar()

  useEffect(() => {
    setTitleIcon('femalelink')
    setAbsoluteWidth('36px')
    setAbsoluteHeight('22px')
    setIconTopLeft('menu')
    setIconTopLeftDown('menu')
    setBadgeTopRight(-1)
    handleSlideChange(swiperInstance)
    if (pullDown === true) {
      setIconTopRight('arrowheavyup')
    } else {
      setIconTopRight('arrowheavydown')
    }
  })

  // //////////////////////////////////////////////////////////////////////////
  // // RUN ONCE //////////////////////////////////////////////////////////////
  // const useMountEffect = (callback) => useEffect(callback, [])
  // let jobDone = false
  // const runOnce = () => {
  //   // run only ONCE !
  //   if (!jobDone) {
  //     jobDone = true

  //   }
  // }
  // useMountEffect(runOnce)
  // // END ONCE ! ////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////
  const asyncFunc = async () => {
    // console.log(' --- pullguest getprofile ---')
    setLocalFetching(true)

    let cardGuid = ''

    if (card_guid !== undefined) {
      cardGuid = card_guid
    } else {
      cardGuid = state.cardGuid
    }

    await fetchProfile(cardGuid, state, dispatch)
    setLocalFetching(false)
    setFetching(dispatch, false)
  }

  useEffect(() => {
    if (!card_guid || card_guid === '') {
      setPullDown(false)
      return
    }

    if (
      card_guid !== '' &&
      card_guid === state.cardGuid &&
      state.role !== 'anon' &&
      state.role !== 'guest'
    ) {
      navigate('/profile')
    }
  })

  useEffect(() => {
    asyncFunc()
    if (state.profile === false) {
      // console.log('useEffect 1 Profile not fetched ', state.message)
      setProfile(false)
    } else {
      // console.log('useEffect 1 Profile fetched -> :', state.currentProfile)
      setProfile(true)
    }
  }, [card_guid, state.profile])

  useEffect(() => {
    if (localFetching === false) {
      // console.log('begin --  useEffect 2, localFetching:', localFetching)
      const asyncFunc2 = async () => {
        let cardGuid = ''

        if (card_guid !== undefined) {
          cardGuid = card_guid
        } else {
          cardGuid = state.cardGuid
        }
        let results = []
        await get(cardGuid).then((result) => {
          results = result
        })

        if (!results) {
          if (state.currentProfile.cardGuid !== '' && state.currentProfile.cardGuid !== undefined) {
            // lets create it
            let dbProfile = {
              cardGuid: state.currentProfile.cardGuid,
              displayPseudo: state.currentProfile.displayPseudo,
              netbio: state.currentProfile.netbio,
              photo: state.currentProfile.photo,
              photoURL: state.currentProfile.photoURL,
              photoFileType: state.currentProfile.photoFileType,
              netlinks: state.currentProfile.netlinks,
            }
            set(state.currentProfile.cardGuid, dbProfile)
            // console.log('useEffect 2 CREATE Profile indexedDb ... ', state.currentProfile.cardGuid)
            return
          } else {
            // console.log('useEffect 2 FINALY NO Profile ...')
            setProfile(false)
          }
        } else {
          if (state.currentProfile.cardGuid !== '' && state.currentProfile.cardGuid !== undefined) {
            // lets set/update it
            // console.log('useEffect 2 UPDATE Profile indexedDb ...')
            let dbProfile = {
              cardGuid: state.currentProfile.cardGuid,
              displayPseudo: state.currentProfile.displayPseudo,
              netbio: state.currentProfile.netbio,
              photo: state.currentProfile.photo,
              photoURL: state.currentProfile.photoURL,
              photoFileType: state.currentProfile.photoFileType,
              netlinks: state.currentProfile.netlinks,
            }
            set(state.currentProfile.cardGuid, dbProfile)
            return
          } else {
            // console.log('useEffect 2 Profile OFFLINE OR OUTDATED ...')
            // lets put it as currentProfile
            let dbProfile = {
              cardGuid: results.cardGuid,
              displayPseudo: results.displayPseudo,
              netbio: results.netbio,
              photo: results.photo,
              photoURL: results.photoURL,
              photoFileType: results.photoFileType,
              netlinks: results.netlinks,
            }
            setCurrentProfile(dbProfile, state, dispatch)
            // console.log(' ... state.currentProfile ', state.currentProfile)
            setProfile(true)
          }
        }
      }
      asyncFunc2()
    }
  }, [localFetching])

  const handleClickNetlink = (id) => {
    setId(id)
    // console.log(' pull guest! click link id -> ', id)
  }
  const handleSlideChange = (swiper) => {
    let elnext = document.getElementsByClassName('swiper-button-next')
    let elprev = document.getElementsByClassName('swiper-button-prev')

    switch (swiper.activeIndex) {
      case 0:
        elnext[0]?.classList?.add('swiper-button-hidden')
        elnext[0]?.classList?.add('swiper-button-disabled')
        elprev[0]?.classList?.add('swiper-button-hidden')
        elprev[0]?.classList?.add('swiper-button-disabled')
        swiper.allowSlideNext = false
        swiper.allowSlidePrev = false
        break
      case 1:
        elnext[0]?.classList?.add('swiper-button-hidden')
        elnext[0]?.classList?.add('swiper-button-disabled')
        elprev[0]?.classList?.remove('swiper-button-hidden')
        elprev[0]?.classList?.remove('swiper-button-disabled')
        swiper.allowSlideNext = false
        swiper.allowSlidePrev = true
        break
      // case 2:
      //   elnext[0]?.classList?.add('swiper-button-hidden')
      //   elnext[0]?.classList?.add('swiper-button-disabled')
      //   elprev[0]?.classList?.add('swiper-button-hidden')
      //   elprev[0]?.classList?.add('swiper-button-disabled')
      //   break
      default:
        break
    }
  }
  return (
    <div className="inner innerMediumWidth">
      <div className="page-col screen-normal">
        <div className="page-row">
          <div className="page-frame">
            {/* DEBUG TEST ... ***********************************/}
            {/* <div className="small-top">
              fetching: {JSON.stringify(state.fetching)} -->
              {JSON.stringify(state.currentProfile.cardGuid)}
            </div> */}
            {/* DEBUG FIN. ***************************************/}

            {!profile && !localFetching && !state.fetching ? (
              <div className="no-profile">
                <Icon
                  iconId={'phoneoff'}
                  iconTitle={'phoneoff'}
                  topColor={'white'}
                  bottomColor={'white'}
                  iconWidth={'128px'}
                  iconHeight={'128px'}
                />
                <br />
                <div className="no-profile-text">
                  <FormattedMessage
                    id="app.noprofile"
                    defaultMessage="Unknown or logged out user"
                  />
                </div>
                {/* -- offline -- outdated -- not found --
                {JSON.stringify(card_guid)} */}
              </div>
            ) : (
              <>
                <div className="page-line ">
                  <div className="band-id" key={'profitem-' + state.currentProfile.cardGuid}>
                    <div className="photo-block">
                      <Photo
                        key={'pic-poc'}
                        photoblob={state.currentProfile.photo}
                        photofiletype={state.currentProfile.photoFileType}
                        photourl={state.currentProfile.photoURL}
                      />
                    </div>

                    <div className="id-wrapper">
                      <div className="id-box">
                        <div className="username">
                          <div className="inputbox">
                            <InputBox
                              editable={false}
                              passClass={'not-visible'}
                              type="text"
                              max={50}
                              defaultValue={state.currentProfile.displayPseudo}
                              placeholder={''}
                            />
                          </div>
                        </div>
                        <div className="biography">
                          <div className="inputbox">
                            <InputArea
                              editable={false}
                              passClass={'not-visible'}
                              type="text"
                              max={90}
                              defaultValue={state.currentProfile.netbio}
                              placeholder={''}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div key={'proflinks-' + state.currentProfile.cardGuid} className="page-end">
                  <Swiper
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    className="mySwiper"
                    navigation={true}
                    modules={[Navigation]}
                  >
                    <SwiperSlide>
                      <ProfileGuest
                        netlinks={state.currentProfile.netlinks}
                        onClickNetlink={handleClickNetlink}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <GuestLink
                        id={id}
                        card_guid={card_guid}
                        netlinks={state.currentProfile.netlinks}
                        onClickNetlink={handleClickNetlink}
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PullGuest
