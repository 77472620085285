import React from 'react'

export const GoogleMaps = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-18 -25 250 262.9"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M182.442,0H34.53C15.49,0,0,15.491,0,34.531v147.913c0,19.04,15.49,34.53,34.53,34.53h147.912
        c19.041,0,34.532-15.49,34.532-34.53V34.531C216.975,15.491,201.483,0,182.442,0z M135.731,48.122
        C135.731,29.858,150.59,15,168.852,15c18.264,0,33.122,14.858,33.122,33.122c0,12.021-18.405,41.336-33.122,61.603
        C154.137,89.458,135.731,60.143,135.731,48.122z M15,34.531C15,23.762,23.761,15,34.53,15h99.463
        c-8.208,8.635-13.262,20.296-13.262,33.122c0,7.455,3.886,17.633,9.306,28.209l-26.846,26.846
        c-0.003,0.002-0.005,0.004-0.008,0.007c-0.003,0.003-0.005,0.005-0.007,0.008l-86.695,86.695c-0.95-2.296-1.481-4.808-1.481-7.442
        V34.531z M34.53,201.975c-2.635,0-5.146-0.531-7.442-1.481l81.399-81.398l81.398,81.398c-2.296,0.95-4.808,1.481-7.443,1.481H34.53
        z M201.975,182.444c0,2.635-0.531,5.146-1.481,7.442l-81.398-81.399l18.555-18.554c10.69,17.785,22.918,33.86,25.262,36.903
        c1.42,1.843,3.614,2.923,5.941,2.923c2.327,0,4.521-1.08,5.941-2.923c2.485-3.227,16.085-21.106,27.181-40.141V182.444z M63.964,98.25c20.009,0,36.287-16.278,36.287-36.287c0-4.142-3.357-7.5-7.5-7.5H63.964c-4.143,0-7.5,3.358-7.5,7.5
        c0,4.142,3.357,7.5,7.5,7.5h19.923C80.848,77.511,73.063,83.25,63.964,83.25c-11.737,0-21.286-9.549-21.286-21.287
        c0-11.737,9.549-21.286,21.286-21.286c5.684,0,11.03,2.214,15.052,6.234c2.93,2.928,7.678,2.928,10.607-0.002
        c2.928-2.929,2.927-7.678-0.002-10.606c-6.854-6.852-15.967-10.625-25.657-10.625c-20.008,0-36.286,16.278-36.286,36.286
        C27.678,81.972,43.956,98.25,63.964,98.25z"
      />
    </svg>
  )
}
export default GoogleMaps
