import React from 'react'

export const Pinterest = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-4.44 -1 55.17 52.14"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M44.092,12.485c-0.76-2.567-1.98-4.728-3.732-6.606c-1.713-1.837-3.684-3.23-6.026-4.259
		c-2.668-1.169-5.761-1.737-8.716-1.6c-1.078,0.049-2.131,0.106-3.163,0.255c-2.908,0.416-5.469,1.283-7.828,2.65
		C11.616,4.673,9.265,7.049,7.64,9.989c-0.844,1.53-1.675,4.228-2.028,5.99c-0.418,2.078-0.05,5.681,0.789,7.713
		c1.048,2.533,1.91,3.656,4.013,5.223c0.013,0.009,1.313,0.945,2.179,0.931c1.577-0.03,1.891-1.539,2.041-2.264
		c0.028-0.137,0.056-0.272,0.089-0.399c0.029-0.112,0.067-0.232,0.106-0.357c0.223-0.72,0.526-1.706,0.023-2.58
		c-0.189-0.328-0.413-0.583-0.61-0.807c-0.107-0.122-0.215-0.24-0.302-0.37c-0.748-1.111-1.127-2.501-1.127-4.131
		c0-2.139,0.484-4.077,1.481-5.923c1.808-3.349,4.808-5.462,8.677-6.114c2.202-0.369,4.768-0.214,6.693,0.402
		c1.759,0.564,3.256,1.561,4.33,2.886c1.137,1.402,1.787,3.18,1.931,5.286c0.094,1.344-0.028,2.698-0.129,3.597
		c-0.389,3.461-1.396,6.247-2.994,8.282c-1.309,1.67-2.72,2.507-4.315,2.561c-1.027,0.04-1.795-0.17-2.489-0.667
		c-0.655-0.467-1.045-1.043-1.229-1.81c-0.208-0.876,0.043-1.784,0.31-2.746l0.043-0.154c0.233-0.846,0.477-1.619,0.716-2.38
		c0.376-1.199,0.766-2.438,1.087-3.876c0.363-1.623,0.411-2.934,0.148-4.005c-0.324-1.33-1.039-2.326-2.125-2.962
		c-1.149-0.67-2.777-0.799-4.144-0.329c-2.037,0.695-3.591,2.545-4.264,5.075c-0.341,1.285-0.44,2.634-0.293,4.006
		c0.113,1.076,0.354,2.054,0.799,3.235c-0.015,0.051-0.031,0.105-0.047,0.157c-0.032,0.105-0.061,0.207-0.083,0.294
		c-0.479,2-0.945,3.972-1.41,5.94c-0.441,1.869-0.883,3.735-1.334,5.62l-0.102,0.422c-0.462,1.92-0.938,3.906-1.049,6.277
		l-0.05,0.99c-0.098,1.842-0.197,3.747,0.05,5.509c0.049,0.344,0.157,1.115,0.916,1.384c0.227,0.17,0.445,0.242,0.657,0.242
		c0.635,0,1.2-0.645,1.681-1.192c1.569-1.784,2.903-4.037,4.079-6.885c0.526-1.274,0.875-2.645,1.212-3.971l0.203-0.79
		c0.246-0.944,0.487-1.901,0.726-2.848l0.016-0.063c0.443,0.388,0.955,0.738,1.548,1.063c1.255,0.695,2.671,1.1,4.207,1.203
		c1.44,0.098,2.956-0.087,4.629-0.567c1.271-0.362,2.487-0.913,3.617-1.636c4.054-2.596,6.817-7.137,7.781-12.786
		c0.289-1.688,0.412-3.045,0.412-4.537C44.703,15.41,44.498,13.846,44.092,12.485z M42.32,21.332
		c-0.869,5.088-3.315,9.15-6.889,11.438c-0.966,0.619-2.005,1.088-3.09,1.398c-1.446,0.416-2.738,0.577-3.942,0.495
		c-1.261-0.085-2.364-0.398-3.379-0.96c-1.015-0.555-1.673-1.158-2.135-1.955l-1.226-2.118l-1.105,4.337
		c-0.237,0.941-0.477,1.893-0.722,2.832l-0.205,0.802c-0.335,1.315-0.65,2.558-1.123,3.7c-1.053,2.552-2.229,4.571-3.589,6.163
		c-0.106-1.355-0.026-2.875,0.052-4.352l0.051-1.002c0.101-2.182,0.556-4.073,0.995-5.902l0.103-0.425
		c0.451-1.886,0.893-3.755,1.335-5.625c0.465-1.967,0.93-3.937,1.408-5.932c0.014-0.056,0.034-0.122,0.055-0.191
		c0.12-0.403,0.245-0.82,0.076-1.243c-0.429-1.099-0.655-1.976-0.756-2.932c-0.12-1.13-0.041-2.234,0.238-3.282
		c0.498-1.873,1.583-3.22,2.979-3.696c0.346-0.119,0.708-0.169,1.056-0.169c0.567,0,1.093,0.136,1.431,0.333
		c0.607,0.356,0.997,0.914,1.19,1.71c0.185,0.756,0.133,1.797-0.156,3.094c-0.304,1.355-0.663,2.5-1.044,3.713
		c-0.246,0.782-0.495,1.576-0.735,2.446l-0.042,0.152c-0.308,1.109-0.656,2.366-0.328,3.744c0.298,1.248,0.956,2.22,2.011,2.974
		c1.048,0.749,2.278,1.084,3.72,1.039c2.191-0.074,4.149-1.193,5.821-3.325c1.831-2.332,2.978-5.458,3.409-9.295
		c0.108-0.978,0.241-2.452,0.137-3.957c-0.174-2.524-0.972-4.68-2.373-6.408c-1.319-1.627-3.143-2.848-5.273-3.531
		c-2.211-0.709-5.137-0.891-7.635-0.471c-4.5,0.758-7.994,3.225-10.106,7.136c-1.158,2.146-1.721,4.394-1.721,6.873
		c0,2.036,0.493,3.801,1.467,5.247c0.134,0.2,0.294,0.386,0.46,0.574c0.149,0.17,0.29,0.33,0.376,0.479
		c0.061,0.163-0.113,0.727-0.197,0.998c-0.047,0.153-0.092,0.3-0.128,0.437c-0.042,0.16-0.078,0.331-0.114,0.503
		c-0.039,0.188-0.099,0.479-0.162,0.639c-0.237-0.093-0.67-0.331-0.904-0.504c-1.797-1.338-2.456-2.199-3.358-4.382
		c-0.677-1.641-1.013-4.888-0.677-6.556c0.375-1.869,1.174-4.248,1.818-5.417c1.447-2.619,3.546-4.739,6.239-6.301
		c2.133-1.236,4.457-2.022,7.109-2.401c0.943-0.137,1.943-0.19,2.971-0.237c2.65-0.125,5.429,0.385,7.819,1.433
		c2.088,0.917,3.844,2.157,5.367,3.792c1.536,1.646,2.607,3.546,3.277,5.81c0.351,1.177,0.528,2.55,0.528,4.078
		C42.703,18.505,42.588,19.761,42.32,21.332z"
      />
    </svg>
  )
}
export default Pinterest
