import React from 'react'

export const Rose = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 43 519 384"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M94.847,311.22c1.733-40.195,4.042-94.178-23.38-136.212c-13.142,3.077-30.361,6.411-48.492,8.011
		c-7.8,0.688-17.85,3.035-21.434,10.304c-3.424,6.946-1.049,18.335,6.197,29.723c8.353,13.127,16.042,25.976,23.477,38.402
		c19.972,33.378,38.316,64.03,65.617,94.775c-1.992-9.698-2.855-19.989-2.538-30.775C94.426,320.99,94.63,316.244,94.847,311.22z
M179.516,59.631c0.312,1.031,3.594,5.2,15.34,9.222c11.837,4.053,27.436,6.285,43.925,6.285s32.088-2.232,43.925-6.285
		c11.746-4.022,15.028-8.191,15.34-9.222c-0.311-1.031-3.592-5.2-15.338-9.222c-11.837-4.054-27.437-6.286-43.927-6.286
		c-16.489,0-32.089,2.232-43.927,6.286C183.108,54.431,179.827,58.6,179.516,59.631z
M238.781,114.435c32.617,0,63.645-3.113,87.369-8.765c25.356-6.041,33.422-12.878,34.522-15.149
		c-1.1-2.27-9.164-9.107-34.519-15.149c-4.027-0.959-8.281-1.84-12.703-2.649c-11.763,15.313-44.145,22.415-74.668,22.415
		c-30.517,0-62.893-7.1-74.661-22.406c-4.422,0.808-8.686,1.681-12.71,2.64c-25.355,6.042-33.419,12.879-34.519,15.149
		c1.1,2.27,9.166,9.108,34.521,15.149C175.136,111.322,206.164,114.435,238.781,114.435z
M114.286,326.036c-0.827,28.115,7.171,51.039,23.771,68.135c19.695,20.283,51.278,31.453,88.932,31.453
		c39.151,0,68.078-9.464,85.976-28.131c16.448-17.153,24.073-42.857,22.664-76.396c-2.968-70.645-36.35-121.965-99.217-152.535
		c-39.425-19.171-91.214-30.174-149.769-31.82c-0.147-0.004-0.294-0.006-0.44-0.006c-4.509,0-8.722,1.961-10.018,4.663
		c-1.257,2.621,0.225,6.866,4.064,11.649c39.325,48.984,36.469,115.21,34.58,159.034
		C114.615,317.036,114.413,321.715,114.286,326.036z
M438.532,261.449c7.435-12.426,15.123-25.275,23.477-38.402c7.247-11.388,9.622-22.777,6.197-29.723
		c-3.584-7.27-13.634-9.616-21.434-10.304c-13.535-1.194-26.536-3.358-37.742-5.658c-15.934,42.19-49.243,137.756-56.784,200.395
		C391.76,339.61,413.908,302.601,438.532,261.449z
M198.39,132.789c16.76,4.856,32.371,10.787,46.767,17.787c7.653,3.721,14.924,7.75,21.823,12.064 c8.919-8.781,24.343-21.268,49.529-34.527c-26.04,4.633-55.211,6.322-77.729,6.322C226.725,134.435,212.76,133.949,198.39,132.789z
M397.528,151.861c4.557-10.991,4.186-21.233-0.969-26.73c-3.594-3.833-9.248-4.805-15.508-2.669
		c-55.123,18.812-83.807,39.452-97.286,51.829c14.32,11.027,26.593,23.55,36.681,37.486c15.589,21.535,26.088,46.581,31.38,74.751
		C369.013,225.796,391.403,166.633,397.528,151.861z"
      />
    </svg>
  )
}
export default Rose
