import React, { useEffect, useState, useRef } from 'react'
// PICTOS
import default_photo from './../images/default_avatar.png'
// COMPONENTS
import Icon from './Layout/Icon'
// LIBRAIRIES
import { ToastContainer, toast, Zoom } from 'react-toastify'
// CONFIG
import config from './../config'
import { useIntl } from 'react-intl'

const Photo = (props) => {
  return <PhotoCached {...props} />
}

const PhotoCached = ({
  active,
  reinit,
  onChange = undefined,
  onReset = undefined,
  onTouched = undefined,
  photoblob = undefined,
  photofiletype = undefined,
  photourl = undefined,
}) => {
  const intl = useIntl()
  const inputRef = useRef(null)

  const [photo, setPhoto] = useState(photoblob || '')
  const [photoFileType, setPhotoFileType] = useState(photofiletype)

  const [touched, setTouched] = useState(false)
  const notify = (txtToast) => {
    toast.error(txtToast, {
      toastId: 'photo-toast',
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  useEffect(() => {
    if (active) {
      inputRef.current.focus()
      inputRef.current.click()
      window.addEventListener('focus', handleFocusBack)
    }
  }, [active])

  useEffect(() => {
    if (reinit) {
      setTouched(false)
    }
  }, [reinit])

  useEffect(() => {
    if (photoblob) {
      getImg(photoblob.data, photofiletype)
    } else {
    }
  }, [])

  const getImg = async (photodata, phototype) => {
    var base64String = btoa(
      new Uint8Array(photodata).reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
    var url = 'data:image/' + phototype + ';base64,' + base64String
    const response = await fetch(url)
    const imageBlob = await response.blob()
    const reader = new FileReader()
    reader.readAsDataURL(await imageBlob)
    reader.onloadend = () => {
      const base64data = reader.result
      setPhoto(base64data)
      setPhotoFileType(photoFileType)
    }
  }
  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // console.log(' - good error - img file error handling', photo, photoFileType, event)
    if (!photourl) {
      setPhoto(default_photo)
      setPhotoFileType('.png')
      event.currentTarget.className = 'default-photo'
    } else {
      setPhoto(photourl)
      event.currentTarget.className = 'url-photo'
    }
  }

  const onFileChange = async (event) => {
    let file = await event.target.files[0]
    if (!file) {
      console.log('No file selected.')
    } else {
      if (file.size > 10000000) {
        let stringMessage = intl.formatMessage({ id: 'app.profile.toolarge' })
        notify(stringMessage)
        setTimeout(function () {}, 3500)
        return
      }
      setPhoto(URL.createObjectURL(file))
      setPhotoFileType(file.type)
      setTouched(true)
      onTouched(true)
      onChange(file, file.type)
    }
    return
  }

  const handleFocusBack = () => {
    onReset()
    window.removeEventListener('focus', handleFocusBack)
  }

  return (
    <>
      <div className="photo-wrapper">
        <div className="photo">
          <img
            key={'img-poc'}
            src={photo}
            onError={imageOnErrorHandler}
            alt="pic-poc"
            className="custom-photo"
            // width="96px"
            // height="auto"
          />
          <div className="icon-photo-touched ">
            {touched ? (
              <Icon
                iconId={'check'}
                iconTitle={'check'}
                topColor={config.colors.fourth}
                bottomColor={config.colors.fourth}
                iconWidth={'12px'}
                iconHeight={'12px'}
              />
            ) : (
              <></>
            )}{' '}
          </div>
          <input
            id="photo"
            ref={inputRef}
            type="file"
            // accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            name="photo"
            onChange={(e) => onFileChange(e)}
            style={{ display: 'none' }}
          />
        </div>

        {/* <div>
          <ToastContainer transition={Zoom} limit={1} />
        </div> */}
      </div>
    </>
  )
}

export default Photo
