import React, { lazy } from 'react'
// HOOKS
import { AuthProvider } from './auth/authProvider'
import { ConnectionProvider } from './hooks/useConnection'
// PAGES GUEST
import Home from './pages/home'
import Guest from './pages/guest'
// PAGES USER
const Signin = lazy(() => import('./pages/signin'))
const Login = lazy(() => import('./pages/login'))
const Main = lazy(() => import('./pages/main'))
const Help = lazy(() => import('./pages/help'))
const Profile = lazy(() => import('./pages/profile'))
const Edit = lazy(() => import('./pages/edit'))
const EditLink = lazy(() => import('./pages/editlink'))
const Settings = lazy(() => import('./pages/settings'))
const Privacy = lazy(() => import('./pages/privacy'))
const Terms = lazy(() => import('./pages/terms'))
const Error = lazy(() => import('./pages/error'))

const About = lazy(() => import('./pages/about'))

const routes = [
  // Pages
  {
    path: '/on_scan/:card_guid',
    element: <Guest />,
  },
  {
    path: '/on_tap/:card_guid',
    element: <Guest />,
  },
  {
    path: '/on_link/:card_guid',
    element: <Guest />,
  },
  {
    path: '/on_self/:card_guid',
    element: <Guest />,
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/contacts',
    element: <Guest />,
  },
  {
    path: '/guest',
    element: <Guest />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/signin',
    element: (
      <AuthProvider>
        <Signin />
      </AuthProvider>
    ),
  },
  {
    path: '/login',
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
  {
    path: '/main',
    element: (
      <ConnectionProvider>
        <Main />
      </ConnectionProvider>
    ),
  },
  {
    path: '/help',
    element: (
      <ConnectionProvider>
        <Help />
      </ConnectionProvider>
    ),
  },
  {
    path: '/settings',
    element: (
      <ConnectionProvider>
        <Settings />
      </ConnectionProvider>
    ),
  },
  {
    path: '/profile',
    element: (
      <ConnectionProvider>
        <Profile />
      </ConnectionProvider>
    ),
  },
  {
    path: '/edit',
    element: (
      <ConnectionProvider>
        > <Edit />
      </ConnectionProvider>
    ),
  },
  {
    path: '/editlink',
    element: (
      <ConnectionProvider>
        <EditLink />
      </ConnectionProvider>
    ),
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '*',
    element: <Error />,
  },
]

export default routes
