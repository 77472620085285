import React, { Suspense, useState, useRef, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import Layout from './components/Layout'
import { get, set } from 'idb-keyval'
import { useIntl } from 'react-intl'
import isUUID from 'validator/lib/isUUID'

// CONTEXTS
import ProfileState from './Reducers/ProfileState'

// ROUTES
import routes from './routes'
// COMPONENTS
import SplashScreen from './components/SplashScreen'
import Modal from './components/ModalFrame'
import Spinner from './components/Spinner'

// HOOKS
import { DeviceProvider } from './hooks/useDevice' // HOOKS
import useWindowDimensions from './hooks/useWindowDimensions'

// CONFIG
import CardSupplierProfile from './data/cardsupplierlinks.json'
import DialogMobile from './components/DialogMobile'

function App() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [cardGuid, setCardGuid] = useState('')
  const modal1 = useRef(null)
  const modal2 = useRef(null)
  const [mobilePass, setMobilePass] = useState(false)

  const ismob = useWindowDimensions().ismob

  function getSessionStorage(key, initialValue) {
    try {
      const value = window.sessionStorage.getItem(key)
      return value ? JSON.parse(value) : initialValue
    } catch (e) {
      // if error, return initial value
      return initialValue
    }
  }

  let xGUID = window.location.pathname.slice(-36)
  const processMode = process.env.REACT_APP_ENV.toUpperCase()

  console.log('--------------- Render again ... What about CO2 ? ------------- ', processMode)
  console.log('--------------- Mobile use ? -------------->', ismob)
  console.log('--------------- Route ? -------------->', window.location.pathname)

  //////////////////////////////////////////////////////////////////////////
  // RUN ONCE //////////////////////////////////////////////////////////////
  const useMountEffect = (callback) => useEffect(callback, [])
  let jobDone = false
  const runOnce = () => {
    // run only ONCE !
    if (!jobDone) {
      jobDone = true

      const previousVersion = window.localStorage.getItem('version')

      if (!previousVersion) {
        window.localStorage.setItem('version', process.env.REACT_APP_VERSION)
      } else {
        if (previousVersion !== process.env.REACT_APP_VERSION) {
          window.localStorage.setItem('version', process.env.REACT_APP_VERSION)
          modal1?.current?.open()
        }
      }
    }
  }
  useMountEffect(runOnce)
  // END ONCE ! ////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {}, [])

  useEffect(() => {
    // Card supplier account check/update
    get(CardSupplierProfile[0].cardGuid).then((result) => {
      let aProfile = {}
      if (!result) {
        // copy profile in profile
        aProfile = {
          cardGuid: CardSupplierProfile[0].cardGuid,
          displayPseudo: CardSupplierProfile[0].displayPseudo,
          netbio: CardSupplierProfile[0].netbio,
          photo: CardSupplierProfile[0].photo,
          photoURL: CardSupplierProfile[0].photoURL,
          netlinks: CardSupplierProfile[0].netlinks,
        }
        set(CardSupplierProfile[0].cardGuid, aProfile)
      }
      console.log(' -- Db init ok -- ')
      if (isUUID(xGUID, 1)) {
        setCardGuid(xGUID)
        console.log(' -- xGuid ok -- ', xGUID)
      } else {
        setCardGuid(CardSupplierProfile[0].cardGuid)
        console.log(' -- xGuid ok -- ', CardSupplierProfile[0].cardGuid)
      }
      // console.log(' -- xGuid ok -- ', CardSupplierProfile[0].cardGuid)
    })
  }, [xGUID, cardGuid])

  useEffect(() => {
    if (processMode !== 'DEVELOPMENT') {
      if (ismob === false) {
        // setMobilePass(false) -> Avant 12 mai 2023 - reopen !

        modal2?.current?.close()
        setMobilePass(true)
        return

        // login and signin exception //////////////////////
        let path = window.location.pathname
        let connected = getSessionStorage('connection', false)
        if (
          path.substring(0, 6) !== '/login' &&
          path.substring(0, 7) !== '/signin' &&
          connected === false
        ) {
          modal2?.current?.open()
        } else {
          console.log(' -- dev check ok-- ')
          modal2?.current?.close()
          setMobilePass(true)
        }
      } else {
        modal2?.current?.close()
        setMobilePass(true)
      }
    } else {
      setMobilePass(true)
    }
  }, [ismob, processMode, xGUID])

  useEffect(() => {
    // const previousVersion = window.localStorage.getItem('version')
    // if (!previousVersion) {
    //   window.localStorage.setItem('version', process.env.REACT_APP_VERSION)
    // } else {
    //   if (previousVersion === process.env.REACT_APP_VERSION) {
    //     window.localStorage.setItem('version', process.env.REACT_APP_VERSION)
    //     modal1?.current?.open()
    //   }
    // }
  }, [])

  const handleModalClose = () => {
    modal1.current.close()
  }
  const handleModal2 = (choice) => {
    console.log('handleModal2 = (choice)- ', choice)
    if (choice === 'login') {
      setMobilePass(true)
      modal2?.current?.close()
      navigate('/signin')
    } else {
      modal2?.current?.close()
      window.location.replace('https://wp.tchitch.at')
    }
  }
  const handleModal2Escape = () => {
    window.location.replace('https://wp.tchitch.at')
  }

  return (
    <Suspense fallback={<Spinner datatext={intl.formatMessage({ id: 'app.spinner.loading' })} />}>
      <Modal ref={modal1} nocross="true" className="splashscreen">
        <div className="line fullGrid">
          <div className="grid100">
            <SplashScreen onChange={() => handleModalClose()} />
          </div>
        </div>
      </Modal>
      <DeviceProvider>
        {mobilePass === true ? (
          <ProfileState>
            <Layout>
              <Routes>
                {routes.map(({ path, element }, idx) => (
                  <Route key={idx} activeClassName="active" element={element} path={path} />
                ))}
              </Routes>
            </Layout>
          </ProfileState>
        ) : (
          <Spinner datatext={intl.formatMessage({ id: 'app.spinner.redirecting' })} />
        )}
        <Modal ref={modal2} nocross={false} onEscape={() => handleModal2Escape()}>
          <div className="line fullGrid">
            <div className="grid100">
              <div className="mobile-only">
                <DialogMobile
                  onSubmit={(choice) => handleModal2(choice)}
                  onClose={() => handleModal2()}
                  card_guid={cardGuid !== '' ? cardGuid : CardSupplierProfile[0].cardGuid}
                  title={
                    cardGuid !== ''
                      ? intl.formatMessage({ id: 'app.warning.scanqrcode' })
                      : intl.formatMessage({ id: 'app.warning.title' })
                  }
                  sentence={intl.formatMessage({ id: 'app.warning.mobileonly' })}
                  txtbutton={intl.formatMessage({ id: 'app.warning.txtbutton' })}
                  cancelbutton={intl.formatMessage({ id: 'app.continue' })}
                />
              </div>
            </div>
          </div>
        </Modal>
      </DeviceProvider>
    </Suspense>
  )
}
export default App
