import React from 'react'

export const Chat = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient id={props.id} x1="512" y1="512" x2="512" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M385.039,0H126.991C56.862,0,0.015,56.847,0.015,126.976v130.048C0.015,327.153,56.862,384,126.991,384h80.855
				c4.016,2.315,9.482,5.661,16.016,9.99c15.778,10.455,31.565,22.664,46.231,36.44c22.832,21.448,40.706,44.631,51.842,69.079
				c9.506,20.871,40.748,14.091,40.748-8.843V384h22.357c70.129,0,126.976-56.847,126.976-126.976V126.976
				C512.015,56.847,455.167,0,385.039,0z M469.348,257.024c0,46.565-37.745,84.309-84.309,84.309h-43.691
				c-11.782,0-21.333,9.551-21.333,21.333v57.89c-6.464-7.265-13.382-14.34-20.71-21.224
				c-16.551-15.547-34.208-29.203-51.876-40.909c-10.678-7.076-19.103-11.992-24.176-14.651c-3.056-1.602-6.455-2.439-9.905-2.439
				h-86.357c-46.565,0-84.309-37.745-84.309-84.309V126.976c0-46.565,37.745-84.309,84.309-84.309h258.048
				c46.565,0,84.309,37.745,84.309,84.309V257.024z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M128.015,149.333c-23.573,0-42.667,19.093-42.667,42.667s19.093,42.667,42.667,42.667
				c23.573,0,42.667-19.093,42.667-42.667S151.588,149.333,128.015,149.333z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M256.015,149.333c-23.573,0-42.667,19.093-42.667,42.667s19.093,42.667,42.667,42.667s42.667-19.093,42.667-42.667
				S279.588,149.333,256.015,149.333z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M384.015,149.333c-23.573,0-42.667,19.093-42.667,42.667s19.093,42.667,42.667,42.667
				c23.573,0,42.667-19.093,42.667-42.667S407.588,149.333,384.015,149.333z"
      />
    </svg>
  )
}
export default Chat
