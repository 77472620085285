import React from 'react'

export const Menu = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 92.833 92.833"
    >
      <defs>
        <linearGradient id={props.id} x1="92" y1="92" x2="92" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M89.834,1.75H3c-1.654,0-3,1.346-3,3v13.334c0,1.654,1.346,3,3,3h86.833c1.653,0,3-1.346,3-3V4.75
			C92.834,3.096,91.488,1.75,89.834,1.75z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M89.834,36.75H3c-1.654,0-3,1.346-3,3v13.334c0,1.654,1.346,3,3,3h86.833c1.653,0,3-1.346,3-3V39.75
			C92.834,38.096,91.488,36.75,89.834,36.75z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M89.834,71.75H3c-1.654,0-3,1.346-3,3v13.334c0,1.654,1.346,3,3,3h86.833c1.653,0,3-1.346,3-3V74.75
			C92.834,73.095,91.488,71.75,89.834,71.75z"
      />
    </svg>
  )
}
export default Menu
