/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSwiper } from 'swiper/react'
// COMPONENTS
import Picto from '../components/Layout/Picto'
import Icon from '../components/Layout/Icon'
import InputLink from '../components/InputLink'
import Publink from '../components/Publink'
// DATA
import templatelinks from '../data/templatelinks.json'
// LIBRARIES
import { toast } from 'react-toastify'
// CONTEXT
import { ProfileContext } from '../Reducers/ProfileContext'
// CONFIG
import config from '../config'

const GuestLink = ({ id = false, card_guid = '', netlinks = [] }) => {
  const { state } = useContext(ProfileContext)
  const intl = useIntl()
  const swiper = useSwiper()

  const notify = (txtToast) => {
    toast.error(txtToast, {
      toastId: 'footer-toast',
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }
  // useEffect(() => {
  //   console.log(' guestlink id: ', id)
  //   console.log('guestlink netlinks: ', netlinks)
  //   console.log('guestlink card_guid: ', card_guid)
  // }, [id])

  const getName = (id) => {
    for (let i = 0; i < templatelinks.length; i++) {
      if (templatelinks[i]['id'].toString() === id) {
        return templatelinks[i]['name']
      }
    }
  }
  const getRootPart = (id) => {
    for (let i = 0; i < templatelinks.length; i++) {
      if (templatelinks[i]['id'].toString() === id) {
        return templatelinks[i]['root_part']
      }
    }
  }

  const ClickCopy = (id, part1) => {
    let text2clip = templatelinks[id - 1].root_part + part1
    navigator.clipboard.writeText(text2clip)
    let stringMessage = intl.formatMessage({ id: 'app.editlinks.copied' })
    notify(stringMessage)
  }
  const handleClickBack = () => {
    swiper.slidePrev()
    return
  }

  return (
    <>
      <div className="editlink-frame">
        <div
          className="editlink-box"
          //  backgroundImage= `${templatelinks[id].svg_file}`
        >
          <div className="links">
            {netlinks
              .filter((_) => _.templateLinkId.toString() === id)
              .map((item, i) => (
                <div key={'netlink-key-' + item.id} className="netlink-frame">
                  <div id={'netlink-' + i} className="netlink-item">
                    <div className="netlink-input">
                      <div className="left-picto">
                        <Picto
                          key={item.id + '-' + i}
                          pictoName={getName(item.templateLinkId.toString())}
                          pictoFile={`${templatelinks[id].svg_file}`}
                          topColor={'white'}
                          bottomColor={'white'}
                          pictoWidth={'32px'}
                          pictoHeight={'32px'}
                        />
                      </div>

                      <div
                        className="inputlink"
                        onClick={(e) =>
                          window.open(
                            getRootPart(item.templateLinkId.toString()) + item.part1,
                            '_blank'
                          )
                        }
                      >
                        <InputLink
                          id={item.id}
                          editable={false}
                          type="text"
                          max={50}
                          defaultValue={item.part1}
                          placeholder={item.placeholder}
                        />
                      </div>

                      <div
                        className="icon-copy"
                        onClick={(e) => ClickCopy(item.templateLinkId, item.part1)}
                      >
                        <Icon
                          iconId={'copy'}
                          iconTitle={'copy'}
                          topColor={'white'}
                          bottomColor={`${config.colors.primary}`}
                          iconWidth={'28px'}
                          iconHeight={'28px'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div key={'netlink-key-pub-'} className="netlink-frame">
              {id !== false ? <Publink id={id} card_guid={card_guid} /> : ''}
            </div>
          </div>
        </div>

        <br />
        <div key={'sdfsdf9898'} className="netlink-button">
          <div
            className="btn btn-cancel btn-save-input"
            tabIndex="0"
            onClick={() => handleClickBack()}
          >
            <div className="btn-txt">
              <div className="rotate-90">
                <Icon
                  iconId={'arrowguestlink'}
                  iconTitle={'arrow'}
                  topColor={config.colors.secondary}
                  bottomColor={config.colors.secondary}
                  iconWidth={'16px'}
                  iconHeight={'14px'}
                />
              </div>
              <FormattedMessage id="app.return" defaultMessage="Back" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GuestLink
