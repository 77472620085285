import React from 'react'

export const Pen = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1.0004 -0.9925 432.2 417.5"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M406.315,45.323L370.199,9.207c-12.266-12.266-32.224-12.266-44.491,0L35.375,299.541
        c-0.842,0.842-1.472,1.873-1.836,3.007L0.36,405.727c-0.861,2.677-0.152,5.611,1.836,7.599c1.429,1.429,3.346,2.197,5.305,2.197
        c0.768,0,1.541-0.118,2.295-0.36l103.18-33.179c1.134-0.365,2.165-0.995,3.008-1.837L406.315,89.814
        c5.942-5.942,9.215-13.842,9.215-22.245C415.53,59.166,412.257,51.265,406.315,45.323z M293.964,62.164l7.735,7.735
        L59.02,312.579l-7.735-7.735L293.964,62.164z M312.307,80.507l22.71,22.71L92.337,345.896l-22.71-22.71L312.307,80.507z
         M19.111,396.41l10.835-33.69c4.891,2.418,9.353,5.601,13.302,9.55c3.95,3.949,7.135,8.413,9.556,13.306L19.111,396.41z
         M67.221,380.94c-3.234-7.153-7.707-13.617-13.365-19.276c-5.57-5.57-12.14-10.103-19.278-13.349l9.481-29.484l52.632,52.633
        L67.221,380.94z M110.679,364.238l-7.735-7.735l242.68-242.68l7.735,7.735L110.679,364.238z M395.708,79.208l-14.419,14.42
        l-19.86-19.86c-2.929-2.928-7.677-2.929-10.607,0c-2.929,2.929-2.929,7.678,0,10.607l19.86,19.861l-6.717,6.717l-59.393-59.394
        l6.717-6.718l5.45,5.45c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.304-2.197c2.929-2.929,2.929-7.678,0-10.607
        l-5.451-5.451l14.418-14.418c6.419-6.418,16.861-6.418,23.278,0l36.117,36.115c3.108,3.109,4.821,7.242,4.821,11.639
        C400.53,71.965,398.818,76.099,395.708,79.208z M346.287,59.437C346.478,59.887,346.108,58.987,346.287,59.437L346.287,59.437z M345.598,58.147c-0.271-0.42-0.58-0.8-0.931-1.14c-2.814-2.899-7.786-2.899-10.6,0c-2.898,2.817-2.899,7.782,0,10.6
        c2.812,2.896,7.787,2.897,10.6,0c2.14-2.074,2.775-5.441,1.62-8.17C346.107,58.987,345.877,58.547,345.598,58.147z"
      />
    </svg>
  )
}
export default Pen
