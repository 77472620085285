import React from 'react'

export const Radar = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient id={props.id} x1="512" y1="512" x2="512" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M 429 98 v 386 h 62 V 98 h -60.9 z M 327 168.2 v 316 h 60.8 v -316 H 327 z M 225.4 273.6 v 210.6 h 61 V 273.6 h -61 z M 124 343.8 v 140.4 h 60.9 V 343.8 H 124 z M 22.67 394.9 v 89.3 h 60.84 v -89.3 H 22.67 z"
      />
    </svg>
  )
}
export default Radar
