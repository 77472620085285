import React from 'react'

export const Phoneoff = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="1.1119 0.9999 23.64 21.9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* <defs>
        <linearGradient id={props.id} x1="25" y1="25" x2="25" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs> */}
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"
      />
      <line fill={`url(#${props.id})`} fillRule="evenodd" x1="23" y1="1" x2="1" y2="23"></line>
    </svg>
  )
}
export default Phoneoff
