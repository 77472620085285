// Inspiration from Arthur M. thx.
import { FC, useState, createContext, useContext } from 'react'

interface IFooterContext {
  // footerBrand
  footerBrand?: string
  setFooterBrand: (brand: string) => void
  // On-line/Off-line
  online?: boolean
  setInternetOnline: (connected: boolean) => void
}

const FooterContext = createContext<IFooterContext>({
  //
  footerBrand: undefined,
  setFooterBrand: () => {},
  //
  online: undefined,
  setInternetOnline: () => {},
})

export const FooterProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [footerBrand, setFooterBrand] = useState<string>()
  const [online, setInternetOnline] = useState<boolean>()

  return (
    <FooterContext.Provider
      value={{
        footerBrand,
        setFooterBrand,
        online,
        setInternetOnline,
      }}
    >
      {children}
    </FooterContext.Provider>
  )
}

export const useFooter = () => useContext(FooterContext)
