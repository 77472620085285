// LIBRAIRIES
import React from 'react'
import { useIntl } from 'react-intl'

const Countdown = (onlineExpiry) => {
  const intl = useIntl()

  // Absolute Universal time to expiration
  const online_delay = parseInt(onlineExpiry.onlineExpiry) - Date.now()
  // console.log('tick -> ', online_delay)

  // The function
  const formatSeconds = (online_delay) => {
    let txtCountdown = ''
    if (online_delay < 1000) {
      return ''
    }
    if (online_delay / 24 / 60 / 60 / 1000 > 1) {
      txtCountdown =
        parseInt(online_delay / 24 / 60 / 60 / 1000) + ' ' + intl.formatMessage({ id: 'app.day' })
      return txtCountdown
    } else {
      const pad = (n) => (n < 10 ? `0${n}` : n)
      const h = Math.floor(online_delay / 60 / 60 / 1000)
      const m = Math.floor(online_delay / 60 / 1000) - h * 60
      const s = Math.floor(online_delay / 1000 - h * 60 - m * 60)
      let finalTimer = pad(h) + ':' + pad(m) + ':' + pad(s)
      return finalTimer
    }
  }
  return <>{formatSeconds(online_delay)}</>
}
export default Countdown
