import React, { useEffect, useState } from 'react'
// PICTOS
import default_photo from './../images/default_avatar.png'
// COMPONENTS
// import Icon from './Layout/Icon'

const MiniPhoto = (props) => {
  return <PhotoCached {...props} />
}

const PhotoCached = ({
  photoblob = undefined,
  photofiletype = undefined,
  photourl = undefined,
  self_class = '',
}) => {
  const [photo, setPhoto] = useState(photoblob || '')

  useEffect(() => {
    if (photoblob) {
      getImg(photoblob.data, photofiletype)
    } else {
    }
  }, [photoblob, photofiletype])

  const getImg = async (photodata, phototype) => {
    var base64String = btoa(
      new Uint8Array(photodata).reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
    var url = 'data:image/.' + phototype + ';base64,' + base64String
    const response = await fetch(url)
    const imageBlob = await response.blob()
    const reader = new FileReader()
    reader.readAsDataURL(imageBlob)
    reader.onloadend = () => {
      const base64data = reader.result
      setPhoto(base64data)
    }
  }
  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!photourl) {
      setPhoto(default_photo)
      event.currentTarget.className = 'default-photo' + self_class
    } else {
      setPhoto(photourl)
      event.currentTarget.className = 'url-photo' + self_class
    }
  }

  return (
    <>
      <div className="miniphoto-wrapper">
        <div className="photo">
          <img
            key={'img-poc'}
            src={photo}
            onError={imageOnErrorHandler}
            alt="pic-poc"
            className={'custom-photo' + self_class}
            // width="96px"
            // height="auto"
          />
        </div>
      </div>
    </>
  )
}

export default MiniPhoto
