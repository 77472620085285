/* eslint-disable react-hooks/exhaustive-deps */
// LIBRAIRIES
import { FC, useState, useEffect, useContext } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'
// ROUTES
import pulldowns from '../../../pulldowns'
// COMPONENTS
import Icon from '../Icon'
// HOOKS
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useNavbar } from './../../../hooks/useNavbar'
import Countdown from '../../../hooks/useCountdown'
// import { useIcon, IconProvider } from './../../../hooks/useIcon'
// ICONS & PICTOS
import Curve from '../../../icons/Curve'
// CONFIG
import CardSupplierProfile from '../../../data/cardsupplierlinks.json'
import config from '../../../config'
// CONTEXT
import { ProfileContext } from '../../../Reducers/ProfileContext'

interface INavbar {}
const Navbar: FC<INavbar> = (props) => {
  const {
    navbarTitle,
    titleIcon,
    absoluteWidth,
    absoluteHeight,
    pullDown,
    setPullDown,
    withoutNavbar,
    iconTopLeft,
    iconTopLeftDown,
    iconTopRight,
    badgeTopRight,
    onlineBadge,
    cancelRedir,
  } = useNavbar()
  const { state } = useContext(ProfileContext)
  const { card_guid } = useParams()
  const { height } = useWindowDimensions()

  const navigate = useNavigate()

  const [onGrab, setOnGrab] = useState(false)
  const [navHeight, setNavHeight] = useState(height - 85)
  const [targetHeight, setTargetHeight] = useState(height - 85)
  const [pointerType, setPointerType] = useState('')
  const [brand, setBrand] = useState(false)
  const heights = useSpring({
    to: { height: targetHeight },
    from: { height: navHeight },
    config: { duration: 250, friction: 28 },
  })
  const opacities = useSpring({ opacity: brand ? 1 : 0, config: { duration: 50 } })

  useEffect(() => {
    if (navHeight === height - 85) {
      setBrand(true)
    } else {
      setBrand(false)
    }
  }, [navHeight])

  useEffect(() => {
    scrollToTop()
    if (/Android 4\.[0-3]/.test(navigator.appVersion)) {
      window.addEventListener('resize', function () {
        // @ts-ignore: Object is possibly 'null'.
        if (document.activeElement.tagName === 'INPUT') {
          window.setTimeout(function () {
            // @ts-ignore: Object is possibly 'null'.
            document.activeElement.scrollIntoViewIfNeeded()
          }, 0)
        }
      })
    }
  }, [])

  useEffect(() => {
    // Get pullDown

    if (pullDown) {
      setNavHeight(height - 85)
      setTargetHeight(height - 85)
    } else {
      setNavHeight(51)
      setTargetHeight(51)
    }
  }, [pullDown, height])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const onMouseClick = (e) => {
    if (pointerType === 'mouse' && navHeight === 51) {
      setTargetHeight(height - 85)
      setNavHeight(height - 85)
      setPullDown(true)
      return
    }
    if (pointerType === 'mouse' && navHeight > height - 150) {
      setTargetHeight(51)
      setNavHeight(51)
      setPullDown(false)
      return
    }

    if (pointerType === 'mouse' && navHeight === 300) {
      setTargetHeight(51)
      setNavHeight(51)
      setPullDown(false)
      return
    }
  }
  const onMoveBegin = (e) => {
    if (onGrab) return
    e.stopPropagation()
    setOnGrab(true)
    if (navHeight > height - 150) {
      // Remontada !
      setPullDown(false)
      setTargetHeight(51)
      setNavHeight(51)
    }
  }
  const onMove = (e) => {
    if (!onGrab) return
    let Y = 0
    if (e.type === 'touchmove') {
      Y = e.changedTouches[0].clientY
    } else {
      Y = e.clientY
    }
    if (Y < 75 || Y > height - 85) {
      setOnGrab(false)
      return
    }
    if (!Y || Y === navHeight) return
    setNavHeight(targetHeight)
    setTargetHeight(Y - 2)
  }
  const onMoveEnd = (e) => {
    setOnGrab(false)
    if (navHeight < height / 1.4) {
      setNavHeight(51)
      setTargetHeight(51)
      setPullDown(false)
      return
    }
    if (navHeight > height / 1.4) {
      setNavHeight(targetHeight)
      setTargetHeight(height - 85)
      setPullDown(true)
      return
    }
  }
  const onPointerDetect = (e) => {
    setPointerType(e.pointerType)
  }

  const onIconTopLeftClick = () => {
    if (pullDown === true) {
      switch (iconTopLeftDown) {
        case 'menu':
          if (state.role === 'anon' || state.role === 'guest') {
            navigate('/home')
          } else {
            navigate('/main')
          }
          setPullDown(true)
          break
        case 'arrow':
          if (state.role === 'anon' || state.role === 'guest') {
            navigate('/home')
          } else {
            navigate('/main')
          }
          break
        case 'cross':
          if (state.role === 'anon' || state.role === 'guest') {
            navigate('/home')
          } else {
            if (cancelRedir === '/home') {
              navigate('/home')
            } else {
              navigate('/main')
            }
          }
          setPullDown(false)
          break
        default:
          break
      }
    } else {
      switch (iconTopLeft) {
        case 'menu':
          setPullDown(true)
          break
        case 'arrow':
          if (state.role === 'anon' || state.role === 'guest') {
            navigate('/home')
          } else {
            navigate('/main')
          }
          break
        case 'cross':
          if (state.role === 'anon' || state.role === 'guest') {
            navigate('/home')
          } else {
            navigate('/main')
          }
          break
        default:
          break
      }
    }
  }
  const onIconTopRightClick = () => {
    if (!card_guid) {
      let selfURL = ''
      if (state.currentProfile.cardGuid !== '') {
        selfURL = '/on_self/' + state.currentProfile.cardGuid
        navigate(selfURL)
      } else {
        selfURL = '/on_self/' + CardSupplierProfile[0].cardGuid
        navigate(selfURL)
      }
    }

    if (pullDown === true) {
      switch (iconTopRight) {
        case 'femalelink':
          setPullDown(false)
          navigate('/contacts')
          break
        case 'arrowheavyup':
          setPullDown(false)
          break
        case 'cross':
          setPullDown(false)
          break
        default:
          break
      }
    } else {
      switch (iconTopRight) {
        case 'femalelink':
          setPullDown(false)
          navigate('/contacts')
          break
        case 'arrowheavydown':
          setPullDown(true)
          break
        case 'cross':
          setPullDown(false)
          break
        default:
          break
      }
    }
  }
  const brandClick = () => {
    if (state.role === 'anon' || state.role === 'guest') {
      return
    } else {
      if (cancelRedir === '/home') {
        navigate('/main')
      } else {
        navigate('/home')
      }
    }
  }

  return (
    <div className={!withoutNavbar ? 'tch-navbar' : 'nonavbar'}>
      <animated.div id="topnav" className="topnav" style={heights}>
        <div className="topnav-frame">
          <div className="left">
            <div className="dotmenu-frame">
              <div className="dotmenu-box">
                <div className="dotmenu-circle">
                  <div className="menu" onClick={() => onIconTopLeftClick()}>
                    <Icon
                      iconId={pullDown === true ? iconTopLeft : iconTopLeftDown}
                      iconTitle={!pullDown === true ? iconTopLeft : iconTopLeftDown}
                      topColor={config.colors.fourth}
                      bottomColor={config.colors.fourth}
                      iconWidth={`${absoluteWidth}`}
                      iconHeight={`${absoluteHeight}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'brand-box'}>
            <div className="brand-row" onClick={() => brandClick()}>
              <p className="left-part">{'Tchitch'}</p>
              <p className="center-part">{'.'}</p>
              <p className="right-part">{'at'}</p>
            </div>

            {(state.online === true || state.expiryTime === 0) && pullDown === true ? (
              <div className="countdown">
                <Countdown onlineExpiry={state.onlineExpiry} />
              </div>
            ) : (
              <></>
            )}

            {/* <div className="logo" style={{ background Image: `url(${config.logo})` }}></div> */}
          </div>

          <div className="icon-system">
            <div className="right-icon" onClick={() => onIconTopRightClick()}>
              <Icon
                iconId={iconTopRight ? iconTopRight + '-top-right' : 'deadjim'}
                iconTitle={iconTopRight}
                topColor={badgeTopRight === -1 ? 'white' : config.colors.secondary}
                bottomColor={badgeTopRight === -1 ? 'lightgrey' : 'white'}
                // iconWidth={'28px'}
                // iconHeight={'28px'}
              />
            </div>

            <div className={badgeTopRight === 1 ? 'badge nobadge' : 'badge'}>
              {/* <div className="number">{badgeTopRight}</div> */}
              <div className="nfc-badge">
                <Icon
                  iconId={'profile-online'}
                  iconTitle={'nfc'}
                  topColor={onlineBadge === true ? config.colors.primary : 'lightgrey'}
                  bottomColor={onlineBadge === true ? config.colors.primary : 'lightgrey'}
                  iconWidth={'28px'}
                  iconHeight={'28px'}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ////////////////////////// PULLDOWN => SPECIAL NAV CONTENT //////////////////////// */}
        <div className={'nav-content-frame ' + (pullDown ? 'showme' : 'hideme')}>
          <div className="nav-content-box">
            <animated.div
              className={'nav-content ' + (pullDown ? 'show' : 'hide')}
              style={opacities}
            >
              <Routes>
                {pulldowns.map(({ path, element }, idx) => (
                  <Route key={idx} element={element} path={path} />
                ))}
              </Routes>
            </animated.div>
          </div>
        </div>
        {/* ////////////////////////// END NAV CONTENT //////////////////////////////////////// */}
      </animated.div>

      <div className="tabnav">
        <div className="tab-left">
          <Curve
            id="tab-left"
            top_color={config.colors.primary}
            bottom_color={config.colors.secondary}
          />
        </div>

        <div
          id="tab"
          // Mouse&Touch: PointerType ...
          onPointerDown={(e) => onPointerDetect(e)}
          onClick={(e) => onMouseClick(e)}
          onTouchStart={(e) => onMoveBegin(e)}
          onTouchMove={(e) => onMove(e)}
          onTouchEnd={(e) => onMoveEnd(e)}
        >
          <div className="tab-box">
            <div className="tab-center-col">
              <div className="tab-frame">
                <div className="tab-icon">
                  {/* <Home id="home" top_color={config.colors.secondary} bottom_color={'white'} /> */}
                  {pullDown === true ? (
                    <></>
                  ) : (
                    <div className="icon">
                      <Icon
                        iconId={titleIcon ? titleIcon : 'deadjim'}
                        iconTitle={titleIcon}
                        topColor={config.colors.fourth}
                        bottomColor={config.colors.fourth}
                        iconWidth={absoluteWidth ? absoluteWidth : '32px'}
                        iconHeight={absoluteHeight ? absoluteHeight : '32px'}
                      />
                    </div>
                  )}
                </div>
                <div className="tab-title noselect">{pullDown === true ? '' : navbarTitle}</div>
              </div>
              <div className="tab-cursor-frame">
                <div className="tab-cursor">
                  <div className="cursor"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-right">
          <Curve
            id="tab-right"
            top_color={config.colors.primary}
            bottom_color={config.colors.secondary}
          />
        </div>
      </div>
      {/* <div
        className={'back-link' + ((cancelRedir !== '' && pullDown === false) ? '' : ' hidden')}
        onClick={() => onArrowClick()}
      >
        <Icon
          iconId={'arrow'}
          topColor={config.colors.primary}
          bottomColor={config.colors.secondary}
          iconWidth={'32px'}
          iconHeight={'32px'}
        />
        <div className="txt-back-link">
           annuler
           </div>
      </div> */}
    </div>
  )
}

export default Navbar
