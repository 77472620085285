import React from 'react'

export const TikTok = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="1 1 95.09 100"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M74.089,21.254c-4.121-4.791-6.28-10.981-6.108-17.283L52.582,3.599c0,0,0,0.657,0,1.543v61.751    c0.215,19.536-30.651,19.062-28.209-2.532c1.493-8.586,10.719-13.87,18.917-10.883c0,0,0-15.712,0-15.712    C25.442,34.658,8.528,48.864,8.706,66.962c1.56,39.25,57.856,39.255,59.418,0c-0.393-1.405-0.173-27.561-0.229-29.767    c7.002,4.357,15.149,6.535,23.4,6.256V27.224C83.667,27.224,77.846,25.196,74.089,21.254z"
      />
    </svg>
  )
}
export default TikTok
