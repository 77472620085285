/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { values } from 'idb-keyval'
// HOOKS
import { useNavbar } from './../hooks/useNavbar.tsx'
// COMPONENTS
import Icon from '../components/Layout/Icon'
import MiniPhoto from '../components/MiniPhoto'
// CONTEXT
import { ProfileContext } from './../Reducers/ProfileContext'
import { setCurrentCardList, setError } from './../Reducers/ProfileActions'
const Guest = (props) => {
  const { state, dispatch } = useContext(ProfileContext)
  const { card_guid } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const {
    setNavbarTitle,
    pullDown,
    setPullDown,
    setIconTopLeft,
    setIconTopLeftDown,
    setIconTopRight,
    setBadgeTopRight,
  } = useNavbar()
  useEffect(() => {
    setIconTopLeft('arrow')
    setIconTopLeftDown('arrow')
    // setIconTopRight('arrowdown')
    setBadgeTopRight(1)

    setNavbarTitle(intl.formatMessage({ id: 'app.guest.title' }))

    if (pullDown === true) {
      setIconTopRight('arrowheavyup')
    } else {
      setIconTopRight('arrowheavydown')
    }
  })
  //////////////////////////////////////////////////////////////////////////
  // RUN ONCE //////////////////////////////////////////////////////////////
  const useMountEffect = (callback) => useEffect(callback, [])
  let jobDone = false
  const runOnce = () => {
    // run only ONCE !
    if (!jobDone) {
      jobDone = true
      //      console.log('run Once GUEST ... card_guid: ', card_guid)
      if (!card_guid) {
        setPullDown(false)
      } else {
        setPullDown(true)
      }
    }
  }
  useMountEffect(runOnce)
  // END ONCE ! ////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // console.log('GUEST useEffect change currentCardList ? ...A', state.currentCardList)
    const asyncFunc = async () => {
      let results = []
      await values()
        .then((result) => {
          results = result
        })
        .catch((err) => {
          console.log('getAll It failed!', err)
          results = err
        })
      let newCardList = []
      for (let i = 0; i < results.length; i++) {
        newCardList.push(results[i])
      }
      await setCurrentCardList(newCardList, state, dispatch)
    }
    asyncFunc()
    // console.log('GUEST useEffect change currentCardList ? ...B', state.currentCardList)
  }, [pullDown])

  const handleClickProfile = (cardGuid) => {
    // console.log('handleClickProfile ... cardGuid:' + cardGuid)

    // console.log('handleClickProfile ... state.cardGuid:' + state.cardGuid)

    // console.log('handleClickProfile ... card_guid:' + card_guid)

    // console.log('handleClickProfile error... ' + state.error)

    setError(dispatch, false)

    navigate('/on_link/' + cardGuid)
    setPullDown(true)

    if (state.error === true) {
      //  window.location.reload()
    } else {
    }
  }

  return (
    <div className="inner innerMediumWidth">
      <div className="page">
        {/* <div className="">-> {Object.values(cardList).map((item, i) => item.cardGuid)}</div> */}

        {state.currentCardList.map((item, i) => (
          <div
            key={'profile-' + item.cardGuid}
            className={
              'card content-frame profile-row' +
              (state.cardGuid === item.cardGuid ? ' self-class' : '')
            }
            onClick={() => handleClickProfile(item.cardGuid)}
          >
            <div className="profile-box">
              <div className="profile-line">
                <div className="profile-photo">
                  <MiniPhoto
                    key={'pic-poc'}
                    photoblob={item.photo}
                    photofiletype={item.photoFileType}
                    photourl={item.photoURL}
                    self_class={state.cardGuid === item.cardGuid ? ' self-class' : ''}
                  />
                </div>
                <div
                  className={
                    'profile-pseudo' + (state.cardGuid === item.cardGuid ? ' self-class' : '')
                  }
                >
                  {item.displayPseudo}
                </div>
                <div className=""></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Guest
