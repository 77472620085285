import React from 'react'

export const Lockgirl = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 -1 261.1 220.2"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M214.31,105.207c-2.154,0-3.897,1.743-3.897,3.897c0,30.552-13.613,57.967-35.069,76.558v-13.326
        c0-12.401-10.609-22.493-23.649-22.493h-18.421l-6.829-14.499c15.468-1.624,26.004-4.61,26.601-4.782
        c2.032-0.586,3.223-2.687,2.69-4.73c-0.084-0.316-8.276-31.823-8.276-47.192v-9.056c0-19.03-15.484-34.513-34.514-34.513h-11.583
        c-19.03,0-34.513,15.483-34.513,34.513v9.056c0,15.369-8.192,46.877-8.276,47.192c-0.533,2.043,0.658,4.144,2.69,4.73
        c0.6,0.173,11.245,3.19,26.852,4.809l-5.953,14.472H66.511c-13.041,0-23.649,10.091-23.649,22.493v13.326
        c-21.456-18.59-35.069-46.005-35.069-76.558c0-55.864,45.446-101.31,101.31-101.31c2.154,0,3.897-1.743,3.897-3.897
        s-1.743-3.897-3.897-3.897C48.943,0.001,0,48.943,0,109.103c0,60.16,48.943,109.103,109.103,109.103
        c60.16,0,109.103-48.943,109.103-109.103C218.207,106.951,216.464,105.207,214.31,105.207z M67.075,123.993
        c2.241-9.098,7.568-32.142,7.568-45.354v-9.056c0-14.734,11.987-26.72,26.72-26.72h11.583c14.734,0,26.72,11.987,26.72,26.72
        v9.056c0,13.212,5.327,36.256,7.569,45.354c-6.712,1.572-21.994,4.593-40.08,4.593S73.787,125.566,67.075,123.993z
         M167.551,191.778c-16.528,11.719-36.689,18.637-58.448,18.637c-21.759,0-41.919-6.919-58.448-18.638v-19.442
        c0-8.105,7.112-14.7,15.856-14.7h18.261c1.579,0,3.002-0.955,3.604-2.412l7.894-19.187c3.478,0.21,7.107,0.344,10.885,0.344
        c3.821,0,7.489-0.137,11.002-0.351l9.12,19.37c0.639,1.366,2.013,2.237,3.524,2.237h20.894c8.744,0,15.856,6.595,15.856,14.7
        V191.778z M214.31,31.173h-3.897V23.38c0-8.596-6.99-15.586-15.586-15.586c-8.596,0-15.586,6.99-15.586,15.586v7.793h-3.897
        c-2.154,0-3.897,1.743-3.897,3.897v27.276c0,2.154,1.743,3.897,3.897,3.897h38.966c2.154,0,3.897-1.743,3.897-3.897V35.069
        C218.207,32.915,216.464,31.173,214.31,31.173z M187.034,23.38c0-4.296,3.497-7.793,7.793-7.793c4.296,0,7.793,3.497,7.793,7.793
        v7.793h-15.586V23.38z M210.415,58.449h-0.001h-31.172V38.967h3.897h23.379h3.897V58.449z"
      />
    </svg>
  )
}
export default Lockgirl
