import { useState, useEffect } from 'react'
// LIBRARIES
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { get } from 'idb-keyval'
// COMPONENTS
import Picto from '../components/Layout/Picto'
import Icon from '../components/Layout/Icon'
import InputLink from '../components/InputLink'
// CONFIG
import config from '../config'
// DATA
import templatelinks from './../data/templatelinks.json'
import CardSupplierProfile from './../data/cardsupplierlinks.json'

const Publink = ({ id, card_guid = '' }) => {
  const [item, setItem] = useState()

  const notify = (txtToast) => {
    toast.error(txtToast, {
      toastId: 'footer-toast',
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }
  const intl = useIntl()

  useEffect(() => {
    // console.log(' publink id: ' + id)
    // console.log(' Publink netlinks: ', CardSupplierProfile[0].netlinks)

    let netlink = []
    const asyncFunc = async () => {
      await get(CardSupplierProfile[0].cardGuid).then((result) => {
        if (!result) {
          //  console.log(' -- Card supplier has no link for this network .. -- ')
        } else {
          // console.log(' -- Card supplier indexed db ok .. -- ', result)

          for (let i = 0; i < result.netlinks.length; i++) {
            if (result.netlinks[i].templateLinkId.toString() === id.toString()) {
              netlink.push(result.netlinks[i])
            }
          }
          setItem(netlink)
          // console.log(' item ! : ', item)
        }
      })
    }
    asyncFunc()

    // for (let i = 0; i < CardSupplierProfile[0].netlinks.length; i++) {
    //   if (CardSupplierProfile[0].netlinks[i].templateLinkId.toString() === id.toString()) {
    //     netlink.push(CardSupplierProfile[0].netlinks[i])
    //   }
    // }
  }, [id, setItem])

  const getName = (id) => {
    for (let i = 0; i < templatelinks.length; i++) {
      if (templatelinks[i]['id'].toString() === id) {
        return templatelinks[i]['name']
      }
    }
  }
  const getPicto = (id) => {
    for (let i = 0; i < templatelinks.length; i++) {
      if (templatelinks[i]['id'].toString() === id) {
        return templatelinks[i]['svg_file']
      }
    }
  }
  const getRootPart = (id) => {
    for (let i = 0; i < templatelinks.length; i++) {
      if (templatelinks[i]['id'].toString() === id) {
        return templatelinks[i]['root_part']
      }
    }
  }

  const ClickCopy = (id, part1) => {
    let text2clip = templatelinks[id - 1].root_part + part1
    navigator.clipboard.writeText(text2clip)
    let stringMessage = intl.formatMessage({ id: 'app.editlinks.copied' })
    notify(stringMessage)
  }

  if (item && item.length > 0 && card_guid !== CardSupplierProfile[0].cardGuid) {
    return (
      <div id={'netlink-pub'} className="netlink-item">
        <div className="netlink-input">
          <div className="left-picto">
            <Picto
              key={'publink-picto'}
              pictoName={getName(id)}
              pictoFile={getPicto(id)}
              topColor={'white'}
              bottomColor={'white'}
              pictoWidth={'32px'}
              pictoHeight={'32px'}
            />
          </div>

          <div
            className="inputlink"
            onClick={(e) => window.open(getRootPart(id) + item[0]?.part1, '_blank')}
          >
            <InputLink
              id={item[0]?.id}
              editable={false}
              type="text"
              max={50}
              defaultValue={item[0]?.part1}
              placeholder={item[0]?.placeholder}
            />
          </div>

          <div className="icon-copy" onClick={(e) => ClickCopy(id, item[0]?.part1)}>
            <Icon
              iconId={'copy'}
              iconTitle={'copy'}
              topColor={'white'}
              bottomColor={`${config.colors.primary}`}
              iconWidth={'28px'}
              iconHeight={'28px'}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Publink
