import React from 'react'

export const MailAt = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1.5 -1 35.8 33.83"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M33.7,4.9c-0.3-1-0.8-1.8-1.4-2.6c-0.7-0.7-1.5-1.3-2.4-1.7C28.8,0.2,27.7,0,26.6,0c-1.2,0-2.4,0.2-3.4,0.7
		c-1,0.4-1.9,1.1-2.7,1.9c-0.1,0.2-0.3,0.3-0.4,0.5H3.8c0,0,0,0,0,0c-1.9,0-3.5,1.6-3.5,3.5v16.6c0,1.9,1.6,3.5,3.5,3.5H26
		c1.9,0,3.5-1.6,3.5-3.5v-8c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.1-0.5,0.2c-0.3,0.1-0.5,0.1-0.8,0.2C27,16,26.5,16,25.9,16
		c-0.9,0-1.8-0.1-2.6-0.4c-0.7-0.3-1.4-0.7-1.9-1.3c-0.5-0.6-0.9-1.4-1.1-2.2c-0.2-1-0.3-2-0.3-3c0-1,0.2-2,0.5-3
		c0.3-0.9,0.7-1.7,1.3-2.4c0.5-0.7,1.2-1.2,2-1.6c0.8-0.4,1.7-0.6,2.6-0.6c0.9,0,1.8,0.1,2.6,0.5c0.7,0.3,1.3,0.8,1.8,1.4
		c0.5,0.6,0.8,1.3,1,2.1c0.2,0.9,0.3,1.8,0.2,2.7c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.2,0.8-0.4,1.2c-0.1,0.3-0.4,0.6-0.6,0.8
		c-0.3,0.3-0.7,0.4-1.1,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0-0.7l0.5-5.6c-0.2-0.1-0.4-0.3-0.6-0.4
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.7-0.1-1-0.1c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-1,0.6-1.4,1.1
		c-0.4,0.5-0.8,1.1-1,1.7c-0.3,0.7-0.5,1.4-0.5,2.2c-0.1,0.6,0,1.2,0.1,1.8c0.1,0.5,0.3,1,0.5,1.4c0.5,0.7,1.3,1.2,2.2,1.2
		c1,0,1.9-0.4,2.5-1.3c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.1,1.9-0.5c0.5-0.3,1-0.7,1.3-1.2
		c0.4-0.5,0.6-1.1,0.8-1.8c0.2-0.7,0.3-1.3,0.3-2C34.2,7.1,34.1,6,33.7,4.9z M3.8,4.6h15.3c-0.1,0.3-0.3,0.6-0.4,0.9
		C18.3,6.6,18,7.9,18,9.1c-0.1,1.3,0.1,2.6,0.4,3.8c0,0,0,0,0,0l-3.5,2.5l-13-9.1C2,5.3,2.8,4.6,3.8,4.6z M22.4,17.3
		c1.1,0.4,2.3,0.6,3.4,0.6c0.6,0,1.3-0.1,1.9-0.2c0.1,0,0.1,0,0.2-0.1v5.6c0,1.1-0.9,2-2,2H3.8c-1.1,0-2-0.9-2-2V8l12.7,8.9
		c0.1,0.1,0.3,0.1,0.4,0.1s0.3,0,0.4-0.1l3.7-2.6c0.3,0.5,0.6,0.9,0.9,1.3C20.6,16.4,21.5,16.9,22.4,17.3z M27.2,10.3
		c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0-0.8-0.2-1-0.6
		c-0.2-0.6-0.3-1.2-0.2-1.9l0,0c0-0.5,0.1-1,0.3-1.5c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.2,1-0.2
		c0.2,0,0.4,0,0.6,0.1L27.2,10.3z"
      />
    </svg>
  )
}
export default MailAt
