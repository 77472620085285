// LIBRAIRIES
import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
// COMPONENTS
import Icon from './../components/Layout/Icon'
// Version modifiée EL 10 nov 2021
// Origine: https://dev.to/viclafouch/build-a-complete-modal-component-with-react-hooks-2fk8
const modalElement = document.getElementById('modal-frame')

export function Modal(
  {
    children,
    fade = false,
    defaultOpened = false,
    nocross = true,
    fullscreen = false,
    onEscape = undefined,
    className = false,
  },
  ref
) {
  const [isOpen, setIsOpen] = useState(defaultOpened)
  const close = useCallback(() => setIsOpen(false), [])
  const [showAlert, setShowAlert] = useState(true)
  const showTime = setTimeout(() => {}, 3000)
  const [modalType, setModalType] = useState(fullscreen ? 'modal-fullscreen' : 'modal-window')

  const closing = () => {
    setIsOpen(false)
    // onEscape(true)
    onEscape = true
  }
  const onClose = (e) => {
    onEscape(true)
    setIsOpen(false)
  }
  useImperativeHandle(
    ref,
    () => ({
      isOpen: isOpen,
      open: () => setIsOpen(true),
      closeModal: () => setIsOpen(false),
      close,
    }),
    [close]
  )
  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onEscape(true)
        close()
      }
    },
    [close]
  )

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, isOpen])

  useEffect(() => {
    if (!showAlert) {
      setIsOpen(false)
    }
  }, [])

  return createPortal(
    isOpen ? (
      <div className={`modal ${fade ? 'modal-fade' : ''}`}>
        <div className="modal-overlay" onClick={closing} />
        <div className={'modal-body ' + modalType + ' ' + className}>
          {nocross === false ? (
            <div
              role="button"
              className="modal-close"
              aria-label="close"
              onClick={(e) => onClose(e)}
            >
              <Icon
                iconId={'crossmodale'}
                iconTitle={'cross'}
                topColor={'white'}
                bottomColor={'white'}
                iconWidth={'24px'}
                iconHeight={'24px'}
              />
            </div>
          ) : (
            ''
          )}

          {/* ///////////////////////////////////////////////////////////////////////////// */}
          <div className="modal-content">{children}</div>
          {/* ///////////////////////////////////////////////////////////////////////////// */}
        </div>
      </div>
    ) : null,
    modalElement
  )
}

export default forwardRef(Modal)
