import React from 'react'

export const Diamond = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 56 56"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="56" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="" />
      <polygon fill={`url(#${props.id})`} points="0.285,19.392 24.181,49.057 13.342,19.392 	">
        <animate
          attributeType="XML"
          attributeName="r"
          from="0"
          to="40"
          dur="5s"
          fill="freeze"
          repeatCount="indefinite"
        />
      </polygon>
      <polygon fill={`url(#${props.id})`} points="15.472,19.392 27.02,50.998 38.795,19.392 	" />
      <polygon fill={`url(#${props.id})`} points="29.593,49.823 54.105,19.392 40.929,19.392 	" />
      <polygon fill={`url(#${props.id})`} points="44.755,3.392 29.297,3.392 39.896,16.437 	" />
      <polygon fill={`url(#${props.id})`} points="38.094,17.392 27.195,3.979 16.297,17.392 	" />
      <polygon fill={`url(#${props.id})`} points="25.094,3.392 9.625,3.392 14.424,16.525 	" />
      <polygon fill={`url(#${props.id})`} points="7.959,4.658 0,17.392 12.611,17.392 	" />
      <polygon fill={`url(#${props.id})`} points="54.391,17.392 46.424,4.645 41.674,17.392 	" />
    </svg>
  )
}
export default Diamond
