import React from 'react'

export const ShortMessage = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 30.26 492 486.7"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M384.547,31.26H105.453C47.313,31.26,0,79.606,0,139.016v106.739c0,59.411,47.313,107.756,105.453,107.756h176.408
        L410.103,458.74V350.266C456.489,338.453,490,295.745,490,245.755V139.016C490,79.606,442.687,31.26,384.547,31.26z M112.84,238.413
        c-12.486,0-23.612-2.722-30.819-6.624l5.264-21.264c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8
        c0-4.89-2.931-7.223-14.041-10.931c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208
        c11.125,0,21.085,2.527,26.931,5.458l-5.264,20.486c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806
        c0,4.486,3.708,6.834,15.417,10.931c18.139,6.236,25.75,15.417,25.945,29.459C154.202,225.344,140.16,238.413,112.84,238.413z
         M316.314,236.469h-28.875V184.58c0-13.862-4.501-21.862-14.056-21.862c-6.819,0-11.709,4.68-13.653,10.348
        c-0.778,2.138-1.166,5.264-1.166,7.611v55.792h-28.875V183.01c0-12.098-4.292-20.292-13.668-20.292
        c-7.596,0-12.098,5.862-13.847,10.737c-0.972,2.333-1.166,5.069-1.166,7.417v55.598h-28.875v-64.973
        c0-11.888-0.389-22.042-0.778-30.431h24.973l1.361,12.875h0.583c4.097-6.041,12.486-15.013,28.875-15.013
        c12.292,0,22.042,6.236,26.139,16.18h0.389c3.514-4.875,7.806-8.778,12.292-11.499c5.279-3.125,11.125-4.68,18.154-4.68
        c18.333,0,32.195,12.875,32.195,41.347V236.469z M365.242,238.413c-12.486,0-23.612-2.722-30.819-6.624l5.264-21.264
        c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8c0-4.89-2.931-7.223-14.042-10.931
        c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208c11.125,0,21.07,2.527,26.931,5.458l-5.264,20.486
        c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806c0,4.486,3.708,6.834,15.417,10.931
        c18.139,6.236,25.75,15.417,25.945,29.459C406.604,225.344,392.562,238.413,365.242,238.413z"
      />
    </svg>
  )
}
export default ShortMessage
