import React from 'react'

export const Profile = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
      viewBox="0 0 475 402"
    >
      <defs>
        <linearGradient id={props.id} x1="512" y1="512" x2="512" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <g>
        <g>
          <circle
            fill={`url(#${props.id})`}
            fillRule="evenodd"
            cx="236.308"
            cy="117.504"
            r="111.537"
          />
        </g>
      </g>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M369,246.306c-1.759-1.195-5.297-3.493-5.297-3.493c-28.511,39.583-74.993,65.402-127.395,65.402
        c-52.407,0-98.894-25.825-127.404-65.416c0,0-2.974,1.947-4.451,2.942C41.444,288.182,0,360.187,0,441.87v24.779h472.615V441.87
        C472.615,360.549,431.538,288.822,369,246.306z"
      />
    </svg>
  )
}
export default Profile
