import React from 'react'

export const Qrcode = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 16 16"
    >
      <defs>
        <linearGradient id={props.id} x1="12" y1="12" x2="12" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M6 0h-6v6h6v-6zM5 5h-4v-4h4v4z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M2 2h2v2h-2v-2z"></path>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M0 16h6v-6h-6v6zM1 11h4v4h-4v-4z"
      ></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M2 12h2v2h-2v-2z"></path>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M10 0v6h6v-6h-6zM15 5h-4v-4h4v4z"
      ></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M12 2h2v2h-2v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M2 7h-2v2h3v-1h-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M7 9h2v2h-2v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M3 7h2v1h-2v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M9 12h-2v1h1v1h1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M6 7v1h-1v1h2v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M8 4h1v2h-1v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M9 8v1h2v-2h-3v1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M7 6h1v1h-1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M9 14h2v2h-2v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M7 14h1v2h-1v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M9 11h1v1h-1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M9 3v-2h-1v-1h-1v4h1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M12 14h1v2h-1v-2z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M12 12h2v1h-2v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M11 13h1v1h-1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M10 12h1v1h-1v-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M14 10v1h1v1h1v-2h-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M15 13h-1v3h2v-2h-1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M10 10v1h3v-2h-2v1z"></path>
      <path fill={`url(#${props.id})`} fillRule="evenodd" d="M12 7v1h2v1h2v-2h-2z"></path>
    </svg>
  )
}
export default Qrcode
