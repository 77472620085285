import { useEffect, useRef, useState } from 'react'
// COMPONENTS
import Icon from './Layout/Icon'
// CONFIG
import config from './../config'
const InputBox = ({
  active = false,
  editable = false,
  defaultValue = '',
  newValue = false,
  reinit = false,
  id,
  onChange = undefined,
  onReset = undefined,
  onTouched = undefined,
  type = 'text',
  max = 30,
  placeholder = '',
  passClass = '',
}) => {
  const [input, setInput] = useState(defaultValue) // '' is the initial state value
  const [editing, setEditing] = useState(false)
  const [touched, setTouched] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (editable && active) {
      inputRef.current.disabled = false
      setEditing(true)
      //   onEditing(true)
      inputRef.current.focus()
    } else {
      inputRef.current.disabled = true
    }
  }, [editable, active])

  useEffect(() => {
    if (reinit) {
      setTouched(false)
    }
  }, [reinit])

  const onFocusInput = (e) => {
    // console.log(' onFocusInput ...')
  }

  const clickReset = () => {
    if (touched) {
      setInput(defaultValue)
      setTouched(false)
      onTouched(false)
    }
    resetInput()
  }

  const resetInput = () => {
    setEditing(false)
    onReset()
  }
  const onChangeInput = (e) => {
    setTouched(true)
    onTouched(true)
    setInput(e.target.value)
    onChange(inputRef.current.value.trim())
  }
  return (
    <div
      className="focus-frame"
      key={'editzone'}
      tabIndex={1}
      //   onFocus={(e) => {
      //     if (!active || !editable) return
      //   }}
      onBlur={(e) => {
        if (!active || !editable) {
          return
        } else {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            resetInput()
            if (e.relatedTarget !== null) {
              let strTarget = e.relatedTarget.outerHTML
              // console.log(' ... click blur ...', strTarget)
              if (strTarget.includes('btn-validation')) {
                // console.log('btn-validation trouvé -----------------')
                onReset()
              }
              // e.relatedTarget.click()
            }
          }
        }
      }}
    >
      <div className="input-frame">
        <div className="input-box">
          <div className="input-container">
            <input
              disabled={!editable}
              className={passClass}
              ref={inputRef}
              required
              type={type}
              maxLength={max}
              value={newValue ? newValue : input}
              onChange={(e) => onChangeInput(e)}
              onFocus={(e) => onFocusInput(e)}
              placeholder={placeholder}
              spellCheck={false}
            />
          </div>
          <div className="icon-touched">
            {touched ? (
              <Icon
                iconId={'check'}
                iconTitle={'check'}
                topColor={config.colors.fourth}
                bottomColor={config.colors.fourth}
                iconWidth={'12px'}
                iconHeight={'12px'}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="icon-reset" onClick={() => clickReset()}>
            {editing ? (
              <Icon
                iconId={'cross'}
                iconTitle={'cross'}
                topColor={'white'}
                bottomColor={'white'}
                iconWidth={'19px'}
                iconHeight={'12px'}
              />
            ) : (
              <></>
            )}
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
export default InputBox

// <div
//       className="focus-frame"
//       key={'editzone'}
//       tabIndex={1}
//       onFocus={(e) => {
//         if (!active) return
//         // if (e.currentTarget === e.target) {
//         //   console.log('focused self')
//         // } else {
//         //   console.log('focused child', e.target)
//         // }
//         // if (!e.currentTarget.contains(e.relatedTarget)) {
//         //   // Not triggered when swapping focus between children
//         //   console.log('focus entered self')
//         // }
//       }}
//       onBlur={(e) => {
//         // if (e.currentTarget === e.target) {
//         //   console.log('unfocused self')
//         // } else {
//         //   console.log('unfocused child', e.target)
//         // }
//         if (!e.currentTarget.contains(e.relatedTarget)) {
//           // Not triggered when swapping focus between children
//           // console.log('focus left self')
//           console.log('focus left self -> resetInput')
//           resetInput()
//         }
//       }}
//     >
