import { useState, useEffect } from 'react'

export function getDevicePixelRatio() {
  var mediaQuery
  var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  if (window.devicePixelRatio !== undefined && !is_firefox) {
    return window.devicePixelRatio
  } else if (window.matchMedia) {
    mediaQuery =
      '(-webkit-min-device-pixel-ratio: 1.5),\
        (min--moz-device-pixel-ratio: 1.5),\
        (-o-min-device-pixel-ratio: 3/2),\
        (min-resolution: 1.5dppx)'
    if (window.matchMedia(mediaQuery).matches) {
      return 1.5
    }
    mediaQuery =
      '(-webkit-min-device-pixel-ratio: 2),\
        (min--moz-device-pixel-ratio: 2),\
        (-o-min-device-pixel-ratio: 2/1),\
        (min-resolution: 2dppx)'
    if (window.matchMedia(mediaQuery).matches) {
      return 2
    }
    mediaQuery =
      '(-webkit-min-device-pixel-ratio: 0.75),\
        (min--moz-device-pixel-ratio: 0.75),\
        (-o-min-device-pixel-ratio: 3/4),\
        (min-resolution: 0.75dppx)'
    if (window.matchMedia(mediaQuery).matches) {
      return 0.7
    }
  } else {
    return 1
  }
}

function isMob() {
  var dv = document.getElementById('divscr')
  if (dv.offsetWidth - dv.clientWidth < 10) {
    return true
  } else {
    // console.log('Scrollbar > 10 ! mobile false')
    return false
  }
}

function isMobile() {
  let ua = navigator.userAgent
  // console.log('isMobile ?', ua)
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      ua
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      ua.substr(0, 4)
    )
  ) {
    // console.log('isMobile TRUE !', ua)
    return true
  } else {
    // console.log('isMobile FALSE !', ua)
    return false
  }
}

function isMobileDevice() {
  let Device = window.matchMedia('only screen and (max-width: 2048px)').matches
  if (Device) {
    // The viewport is less than 768 pixels wide
    // console.log('Device:', Device)
    return true
  } else {
    //The viewport is greater or equal than 768 pixels wide
    // console.log('isMobileDevice false')
    return false
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  // Advance mobile check :
  let ismob = false
  if (isMob() && isMobile() && isMobileDevice()) {
    ismob = true
    // console.log('ismob = true')
  }
  return {
    width,
    height,
    ismob,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
