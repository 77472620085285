import React from 'react'

export const Connection = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient id={props.id} x1="512" y1="512" x2="512" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89471 19.6226 5.63604 18.364C5.24551 17.9734 5.24551 17.3403 5.63604 16.9498C6.02656 16.5592 6.65973 16.5592 7.05025 16.9498C8.02922 17.9287 9.2765 18.5954 10.6344 18.8655C11.9922 19.1356 13.3997 18.997 14.6788 18.4672C15.9579 17.9373 17.0511 17.0401 17.8203 15.889C18.5895 14.7379 19 13.3845 19 12C19 10.6155 18.5895 9.26216 17.8203 8.11101C17.0511 6.95987 15.9579 6.06266 14.6788 5.53285C13.3997 5.00303 11.9922 4.86441 10.6344 5.13451C9.2765 5.4046 8.02922 6.07129 7.05025 7.05026C6.65973 7.44078 6.02656 7.44078 5.63604 7.05026C5.24551 6.65973 5.24551 6.02657 5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17294Z
        M9.29289 8.2929C8.90237 8.68342 8.90237 9.31659 9.29289 9.70711L10.5858 11H2C1.44771 11 1 11.4477 1 12C1 12.5523 1.44771 13 2 13H10.5858L9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L13.7071 12.7071C14.0976 12.3166 14.0976 11.6834 13.7071 11.2929L10.7071 8.2929C10.3166 7.90237 9.68342 7.90237 9.29289 8.2929Z"
      />
    </svg>
  )
}
export default Connection
