import React from 'react'

export const Shield = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="59 -1 573 481"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M239.489,40.737c-76.104,0-138.02,61.916-138.02,138.02s61.916,138.02,138.02,138.02c76.104,0,138.02-61.916,138.02-138.02
		S315.593,40.737,239.489,40.737z M239.489,60.737c48.703,0,90.607,29.655,108.612,71.855H130.876
		C148.881,90.392,190.786,60.737,239.489,60.737z M194.428,168.758h-72.531c0.464-5.503,1.304-10.901,2.5-16.165h77.087
		C198.162,157.403,195.733,162.87,194.428,168.758z M121.897,188.758h72.531c1.306,5.887,3.734,11.353,7.055,16.163h-77.087
		C123.201,199.657,122.361,194.26,121.897,188.758z M213.324,178.757c0-14.427,11.737-26.164,26.164-26.164
		s26.163,11.737,26.163,26.164s-11.736,26.164-26.163,26.164S213.324,193.184,213.324,178.757z M284.548,188.758h72.532
		c-0.464,5.502-1.304,10.899-2.5,16.163h-77.088C280.814,200.111,283.242,194.645,284.548,188.758z M357.08,168.758h-72.532
		c-1.306-5.888-3.734-11.355-7.056-16.165h77.088C355.776,157.857,356.616,163.255,357.08,168.758z M239.489,296.776
		c-48.703,0-90.608-29.655-108.612-71.856h217.225C330.096,267.121,288.192,296.776,239.489,296.776z M220.034,116.822c1.953,1.953,4.512,2.929,7.071,2.929s5.119-0.976,7.071-2.929l5.315-5.315l5.315,5.315
		c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143l-5.315-5.315l5.315-5.315
		c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0l-5.315,5.315l-5.315-5.315
		c-3.905-3.905-10.237-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l5.315,5.315l-5.315,5.315
		C216.129,106.584,216.129,112.917,220.034,116.822z M258.946,240.692c-3.906-3.905-10.236-3.905-14.143,0l-5.312,5.312l-5.312-5.312c-3.905-3.905-10.237-3.905-14.143,0
		c-3.905,3.905-3.905,10.237,0,14.143l5.312,5.312l-5.312,5.312c-3.905,3.905-3.905,10.237,0,14.143
		c1.953,1.953,4.512,2.929,7.071,2.929s5.119-0.976,7.071-2.929l5.312-5.312l5.312,5.312c1.953,1.953,4.512,2.929,7.071,2.929
		s5.118-0.976,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143l-5.312-5.312l5.312-5.312
		C262.851,250.929,262.851,244.597,258.946,240.692z M393.05,270.161c15.99-26.763,25.195-58.027,25.195-91.404C418.245,80.19,338.055,0,239.489,0
		C140.922,0,60.732,80.19,60.732,178.757c0,33.375,9.203,64.636,25.191,91.398v40.976c-11.395,12.847-17.635,29.199-17.635,46.518
		c0,18.756,7.304,36.389,20.566,49.652c1.953,1.953,4.512,2.929,7.071,2.929s5.119-0.976,7.071-2.929
		c13.262-13.263,20.566-30.896,20.566-49.652c0-17.321-6.241-33.676-17.64-46.523V297.42c14.58,16.393,32.119,30.098,51.779,40.257
		v24.553c-24.393,27.544-23.424,69.808,2.934,96.166c1.875,1.875,4.419,2.929,7.071,2.929c2.652,0,5.196-1.054,7.072-2.929
		c13.262-13.262,20.566-30.896,20.566-49.651c0-17.322-6.242-33.678-17.643-46.526v-15.716c16.287,6.018,33.683,9.724,51.79,10.728
		v22.643c-24.402,27.543-23.435,69.815,2.925,96.176c1.875,1.875,4.419,2.929,7.071,2.929c2.653,0,5.196-1.054,7.072-2.929
		c13.263-13.263,20.566-30.896,20.566-49.652c0-17.318-6.24-33.67-17.635-46.517v-22.651c18.102-1.003,35.495-4.708,51.779-10.725
		v15.719c-11.397,12.847-17.639,29.202-17.639,46.522c0,18.756,7.304,36.389,20.566,49.652c1.953,1.953,4.512,2.929,7.071,2.929
		s5.118-0.976,7.071-2.929c13.263-13.263,20.566-30.896,20.566-49.652c0-17.319-6.241-33.673-17.637-46.52v-24.545
		c19.66-10.158,37.199-23.862,51.779-40.255v13.704c-11.396,12.847-17.637,29.201-17.637,46.52c0,18.756,7.304,36.389,20.566,49.652
		c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929c13.263-13.263,20.566-30.896,20.566-49.652
		c0-17.32-6.242-33.675-17.639-46.522V270.161z M95.926,384.305c-4.975-7.913-7.638-17.08-7.638-26.657
		c0-9.576,2.663-18.744,7.638-26.657c4.975,7.913,7.638,17.081,7.638,26.657C103.563,367.225,100.9,376.392,95.926,384.305z
		 M167.707,435.4c-4.975-7.913-7.637-17.08-7.637-26.657c0-9.576,2.663-18.744,7.638-26.656c4.975,7.913,7.637,17.08,7.637,26.656
		C175.344,418.32,172.681,427.488,167.707,435.4z M311.27,435.4c-4.975-7.913-7.638-17.08-7.638-26.657
		c0-9.576,2.663-18.744,7.638-26.657c4.975,7.913,7.638,17.081,7.638,26.657C318.907,418.32,316.244,427.488,311.27,435.4z
		 M239.495,453.042c-10.163-16.234-10.163-37.056,0.001-53.29c4.97,7.91,7.63,17.074,7.63,26.645S244.465,445.132,239.495,453.042z
		 M239.489,337.514c-87.539,0-158.757-71.218-158.757-158.757S151.95,20,239.489,20c87.539,0,158.757,71.218,158.757,158.757
		S327.028,337.514,239.489,337.514z M383.051,384.305c-4.975-7.913-7.638-17.08-7.638-26.657c0-9.576,2.663-18.744,7.638-26.657
		c4.975,7.913,7.638,17.081,7.638,26.657C390.689,367.225,388.026,376.392,383.051,384.305z M239.491,168.757c-2.64,0-5.21,1.07-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07s1.06,5.21,2.93,7.07
		c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07s-1.07-5.21-2.931-7.07
		C244.701,169.827,242.121,168.757,239.491,168.757z"
      />
    </svg>
  )
}
export default Shield
