import React, { useEffect, useRef, useState } from 'react'

import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Extension,
  Options,
} from 'qr-code-styling'
// CONFIG
import config from './../config'

const Qrcode = (props) => {
  const [options, setOptions] = useState({
    width: props.width,
    height: props.height,
    type: 'canvas',
    data: props.data,
    image: '/diamond.png',
    margin: 10,
    qrOptions: {
      typeNumber: props.typeNumber,
      mode: 'Byte',
      errorCorrectionLevel: 'Q',
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 4,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      //   color: 'black',
      color: config.colors.primary,
      type: 'extra-rounded',
    },
    backgroundOptions: {
      color: 'transparent',
    },
    cornersSquareOptions: {
      color: 'black',
      // color: config.colors.secondary,
      type: 'dot',
    },
    cornersDotOptions: {
      color: 'black',
      // color: config.colors.primary,
      type: 'dot',
    },
  })

  const [fileExt, setFileExt] = useState('svg')
  const [qrCode] = useState(new QRCodeStyling(options))
  const ref = useRef(null)

  useEffect(() => {
    console.log('qrcode new ...: ', props.data)
    if (ref.current) {
      qrCode.append(ref.current)
    }
  }, [qrCode, ref])

  useEffect(() => {
    console.log('props.data: ', props.data)
    if (!qrCode) return
    qrCode.update(options)
  }, [qrCode, options, props.data])

  const onDownloadClick = () => {
    if (!qrCode) return
    qrCode.download({})
  }

  return (
    <>
      <div className={'qrcode-profile'} ref={ref}></div>
    </>
  )
}

export default Qrcode
