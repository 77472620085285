export const Client = (client_name) => {
  // console.log('window.location.hostname: --   > ', window.location.hostname)
  switch (client_name) {
    default:
    case 'tchitchat-dev':
      return {
        name: 'tchitchat-dev',
        apis: {
          baseURL: 'https://dev.tchitch.at/',
          version: 'v1',
        },
        // raw_url_addr: 'tchitch.at',
        raw_url_addr: window.location.hostname,
        admin_url: 'https://tchitch.at/admin',
        firebase_configuration: {
          apiKey: 'AIzaSyDS_6gvYGmG4kQXGon9aw84-EA5HKUaw2k',
          authDomain: 'tchitchat-5ca22.firebaseapp.com',
          projectId: 'tchitchat-5ca22',
          storageBucket: 'tchitchat-5ca22.appspot.com',
          messagingSenderId: '622230746310',
          appId: '1:622230746310:web:2b67a4ccb06d5214f893c7',
        },
        firebase_public_key:
          'BLLtqsvK6TVrcZFro3f96DLaaaaaaaaaalEg1DMJani1IP1w1n_7ikzaai__WsqPN08HKvST5C3lDRJmhL-q0zA',
        linkedin_redirection_url: 'https://tchitchat-app/linkedin-popup',
        colors: {
          primary: '#645CBB',
          secondary: '#A084DC',
          third: '#BFACE2',
          fourth: '#EBC7E6',
        },
      }

    case 'tchitchat-prod':
      return {
        name: 'tchitchat-prod',
        apis: {
          baseURL: 'https://api.tchitch.at/',
          version: 'v1',
        },
        admin_url: 'https://tchitch.at/admin',
        raw_url_addr: 'tchitch.at',
        firebase_configuration: {
          apiKey: 'AIzaSyBFWb83tlK9pl2XC3IEluN014Ou_0KK_vM',
          authDomain: 'tchitchat-prod.firebaseapp.com',
          projectId: 'tchitchat-prod',
          storageBucket: 'tchitchat-prod.appspot.com',
          messagingSenderId: '1009623211297',
          appId: '1:1009623211297:web:da42ef41c258115c497260',
          measurementId: 'G-B0HR1GZYXQ',
        },
        firebase_public_key:
          'BLLtqsvK6TVrcZFro3f96DLaaaaaaaaaalEg1DMJani1IP1w1n_7ikzaai__WsqPN08HKvST5C3lDRJmhL-q0zA',
        linkedin_redirection_url: 'https://tchitchat-app/linkedin-popup',
        colors: {
          primary: '#645CBB',
          secondary: '#A084DC',
          third: '#BFACE2',
          fourth: '#EBC7E6',
        },
      }
  }
}
