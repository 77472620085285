/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
// LIBRAIRIES
import { useSwiper } from 'swiper/react'
// COMPONENTS
import Picto from './../components/Layout/Picto'
// DATA
import templatelinks from './../data/templatelinks.json'
// HOOKS
import useWindowDimensions from './../hooks/useWindowDimensions'

const ProfileGuest = ({ netlinks = [], onClickNetlink = undefined }) => {
  const swiper = useSwiper()
  const { height } = useWindowDimensions()
  //////////////////////////////////////////////////////////////////////////
  // RUN ONCE //////////////////////////////////////////////////////////////
  const useMountEffect = (callback) => useEffect(callback, [])
  let jobDone = false
  const runOnce = () => {
    // run only ONCE !
    if (!jobDone) {
      jobDone = true
      // console.log('-> /profileSlide: ', state.displayName, state.uid, state.role)
      // console.log('height: ', height)

      //   console.log('netlinks: ', netlinks)
    }
  }
  useMountEffect(runOnce)
  // END ONCE ! ////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  const getPictoName = (id) => {
    let name = ''
    for (let i = 0; i < templatelinks.length - 1; i++) {
      if (templatelinks[i].id === id) {
        name = templatelinks[i].name
      }
    }
    return name
  }

  const getPictoFile = (id) => {
    let file = ''
    for (let i = 0; i < templatelinks.length - 1; i++) {
      if (templatelinks[i].id === id) {
        file = templatelinks[i].file
      }
    }
    return file
  }
  const clickPicto = async (id) => {
    await onClickNetlink(id)
    swiper.allowSlideNext = true
    swiper.slideTo(1)
  }
  const isNetlink = (id) => {
    let result = false
    for (let i = 0; i < netlinks.length; i++) {
      if (netlinks[i].templateLinkId === id) {
        result = true
      }
    }
    return result
  }

  return (
    <>
      <div className="centerLine">
        <div className="page-divider"></div>
      </div>

      <div className="netlinks-frame">
        <div className="netlinks-category">
          <div className="netlinks-box netlinks-box-profile">
            {
              // netlink -> item
              templatelinks.map((item, i) =>
                isNetlink(item.id) ? (
                  <div key={item.id} className="netlink" onClick={() => clickPicto(`${item.id}`)}>
                    <Picto
                      pictoName={`${getPictoName(item.id)}`}
                      pictoFile={`${getPictoFile(item.id)}`}
                      topColor={'white'}
                      bottomColor={'white'}
                      pictoWidth={'72px'}
                      pictoHeight={'72px'}
                      // color_path={this.setColorIcon(item.id)}
                      // check_badge={this.setBadge(item.id)}
                      // netlinks={state.netlinks}
                      // onClickLink={this.onClickLink}
                    />
                  </div>
                ) : (
                  ''
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileGuest
