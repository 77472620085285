import { FC, useEffect, useState, useContext, createContext } from 'react'
import { firebase } from './initFirebase'
interface IContext {
  user: firebase.User | null
  loading: boolean
  logout: () => void
}
const AuthContext = createContext<IContext>({
  user: null,
  loading: true,
  logout: () => {},
})
interface Props {
  children: React.ReactNode
}

const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const cancelAuthListener = firebase.auth().onIdTokenChanged((user) => {
      setLoading(true)
      firebase
        ?.auth()
        .currentUser?.getIdToken(true)
        .then(function (sidToken) {
          // Send token to your backend via HTTPS
          // console.log('authProvider event -> onIdTokenChange user token: ', sidToken.slice(-5))
        })
        .catch(function (error) {
          // Handle error
          console.log('currentUser?.getIdToken error: ', error)
        })
      setUser(user)
      setLoading(false)
    })
    return () => cancelAuthListener()
  }, [])

  return (
    <AuthContext.Provider value={{ user, loading, logout: () => firebase.auth().signOut() }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  return useContext(AuthContext)
}
export { AuthProvider, useAuth }

// console.log('AuthProvider hook -> firebase user: ', user)

// Listen to the Firebase Auth state and set the local state.
// useEffect(() => {
//   const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
//     console.log('authProvider event -> onAuthStateChanged ')
//     // setIsSignedIn(!!user);
//     if (!user) {
//       console.log(' authProvider says: not a user !')
//     } else {
//       console.log(' authProvider says user :', user)
//       setUser(user)
//       setLoading(false)
//     }
//   })
//   return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
// }, [])
