import React from 'react'

export const Signout = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 2 28 20"
    >
      <defs>
        <linearGradient id={props.id} x1="16" y1="16" x2="16" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2442 3.1729C13.99 2.8257 15.7996 3.0039 17.4442 3.6851 19.0887 4.3663 20.4943 5.5198 21.4832 6.9999 22.4722 8.4799 23 10.22 23 12 23 13.78 22.4722 15.5201 21.4832 17.0001 20.4943 18.4802 19.0887 19.6337 17.4442 20.3149 15.7996 20.9961 13.99 21.1743 12.2442 20.8271 10.4984 20.4798 8.8947 19.6226 7.636 18.364 7.2455 17.9734 7.2455 17.3403 7.636 16.9498 8.0266 16.5592 8.6597 16.5592 9.0503 16.9498 10.0292 17.9287 11.2765 18.5954 12.6344 18.8655 13.9922 19.1356 15.3997 18.997 16.6788 18.4672 17.9579 17.9373 19.0511 17.0401 19.8203 15.889 20.5895 14.7379 21 13.3845 21 12 21 10.6155 20.5895 9.2622 19.8203 8.111 19.0511 6.9599 17.9579 6.0627 16.6788 5.5328 15.3997 5.003 13.9922 4.8644 12.6344 5.1345 11.2765 5.4046 10.0292 6.0713 9.0503 7.0503 8.6597 7.4408 8.0266 7.4408 7.636 7.0503 7.2455 6.6597 7.2455 6.0266 7.636 5.636 8.8947 4.3774 10.4984 3.5202 12.2442 3.1729ZM5.7071 8.2929C6.0976 8.6834 6.0976 9.3166 5.7071 9.7071L4.4142 11H11C11.5523 11 12 11.4477 12 12 12 12.5523 11.5523 13 11 13H4.4142L5.7071 14.2929C6.0976 14.6834 6.0976 15.3166 5.7071 15.7071 5.3166 16.0976 4.6834 16.0976 4.2929 15.7071L1.2929 12.7071C.9024 12.3166.9024 11.6834 1.2929 11.2929L4.2929 8.2929C4.6834 7.9024 5.3166 7.9024 5.7071 8.2929Z"
      />
    </svg>
  )
}
export default Signout
