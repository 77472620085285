import styles from './Layout.module.scss'
import { memo, FC } from 'react'

// HOOKS
import { NavbarProvider } from './../../hooks/useNavbar'
import { FooterProvider } from './../../hooks/useFooter'

// TYPES
// import { IRootState } from './../../types/state'

// COMPONENTS
import Navbar from './Navbar'
import Footer from './Footer'

interface ILayout {
  children: JSX.Element[] | JSX.Element
}

const Layout: FC<ILayout> = ({ children }) => {
  return (
    <NavbarProvider>
      <FooterProvider>
        <div className="frame">
          <div className="layout">
            <Navbar />
            <div className={'content'}>{children}</div>

            <Footer />
          </div>
        </div>
      </FooterProvider>
    </NavbarProvider>
  )
}

export default memo(Layout)
