import { FC, useState, useEffect, useContext, createContext } from 'react'

// HOOKS
import useWindowDimensions from './useWindowDimensions'

interface IContext {
  isMobile: boolean
}

const DeviceContext = createContext<IContext>({
  isMobile: false,
})

interface Props {
  children: React.ReactNode
}

export const DeviceProvider: FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const { ismob } = useWindowDimensions()

  useEffect(() => {
    setIsMobile(ismob)
    // console.log('isMobile/provider: ', isMobile)
  }, [])

  return <DeviceContext.Provider value={{ isMobile }}>{children}</DeviceContext.Provider>
}

export const useDevice = () => useContext(DeviceContext)
