import React, { FC } from 'react'

import Add from '../../../icons/Add'
import Arrow from '../../../icons/Arrow'
import Arrowdown from '../../../icons/ArrowDown'
import Arrowheavyup from '../../../icons/ArrowHeavyUp'
import Arrowheavydown from '../../../icons/ArrowHeavyDown'
import Arrowup from '../../../icons/ArrowUp'
import Bin from '../../../icons/Bin'
import Chat from '../../../icons/Chat'
import Cancel from '../../../icons/Cancel'
import Check from '../../../icons/Check'
import Checked from '../../../icons/Checked'
import Chevron from '../../../icons/Chevron'
import Connection from '../../../icons/Connection'
import Contactus from '../../../icons/Contactus'
import Copy from '../../../icons/Copy'
import Cross from '../../../icons/Cross'
import Curve from '../../../icons/Curve'
import Deadjim from '../../../icons/Deadjim'
import Diamond from '../../../icons/Diamond'
import Eiffel from '../../../icons/Eiffel'
import Femalelink from '../../../icons/FemaleLink'
import Followus from '../../../icons/Followus'
import Gears from '../../../icons/Gears'
import Globe from '../../../icons/Globe'
import Gueststar from '../../../icons/Gueststar'
import Haunted from '../../../icons/Haunted'
import Heart from '../../../icons/Heart'
import Home from '../../../icons/Home'
import Lock from '../../../icons/Lock'
import Lockgirl from '../../../icons/Lockgirl'
import Menu from '../../../icons/Menu'
import Nfc from '../../../icons/Nfc'
import Pen from '../../../icons/Pen'
import Phoneoff from '../../../icons/Phoneoff'
import Profile from '../../../icons/Profile'
import Qrcode from '../../../icons/Qrcode'
import Question from '../../../icons/Question'
import Radar from '../../../icons/Radar'
import Refresh from '../../../icons/Refresh'
import Rose from '../../../icons/Rose'
import Shield from '../../../icons/Shield'
import Signin from '../../../icons/Signin'
import Signout from '../../../icons/Signout'
import Train from '../../../icons/Train'
import Tuto from '../../../icons/Tuto'

interface IIcon {
  // iconId
  iconId?: string
  setIconId?: (iconId: string) => void
  // iconTitle
  iconTitle?: string
  setIconTitle?: (title: string) => void
  // topColor
  topColor?: string
  setTopColor?: (color: string) => void
  // bottomColor
  bottomColor?: string
  setBottomColor?: (color: string) => void
  // width
  iconWidth?: string
  setIconWidth?: (width: string) => void
  // height
  iconHeight?: string
  setIconHeight?: (height: string) => void
}

const components = {
  add: Add,
  arrow: Arrow,
  arrowdown: Arrowdown,
  arrowup: Arrowup,
  arrowheavyup: Arrowheavyup,
  arrowheavydown: Arrowheavydown,
  bin: Bin,
  chat: Chat,
  cancel: Cancel,
  check: Check,
  checked: Checked,
  chevron: Chevron,
  connection: Connection,
  contactus: Contactus,
  copy: Copy,
  cross: Cross,
  curve: Curve,
  deadjim: Deadjim,
  diamond: Diamond,
  eiffel: Eiffel,
  femalelink: Femalelink,
  followus: Followus,
  gears: Gears,
  globe: Globe,
  gueststar: Gueststar,
  haunted: Haunted,
  heart: Heart,
  home: Home,
  lock: Lock,
  lockgirl: Lockgirl,
  menu: Menu,
  nfc: Nfc,
  pen: Pen,
  phoneoff: Phoneoff,
  profile: Profile,
  question: Question,
  qrcode: Qrcode,
  radar: Radar,
  refresh: Refresh,
  rose: Rose,
  signin: Signin,
  shield: Shield,
  signout: Signout,
  train: Train,
  tuto: Tuto,
}

const Icon: FC<IIcon> = (props) => {
  // -------------------------------------------
  if (!props.iconTitle || props.iconTitle === 'none') {
    return <></>
  }
  // console.log('props.iconId:', props.iconId)
  // console.log('props.iconTitle:', props.iconTitle)

  const TheIcon = components[props.iconTitle]

  return (
    <TheIcon
      id={props.iconId}
      top_color={props.topColor}
      bottom_color={props.bottomColor}
      width={props.iconWidth}
      height={props.iconHeight}
    />
  )
}

export default Icon
