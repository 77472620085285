import React from 'react'
// COMPONENTS
import Icon from './../components/Layout/Icon'
// CONFIG
import config from './../config'
// IMAGES
import ModalImage from './../images/TchitchatBrandHome.png'
const ConnectionBox = ({
  onSubmit,
  title = '... message',
  sentence = '',
  txtbutton = '',
  cancelbutton = '',
  bottomtext = '',
}) => {
  const handleClick = (choice) => {
    onSubmit(choice)
  }

  return (
    <>
      <div className="dialogbox">
        <div className="dialog-frame">
          <div className="dialog-col">
            <div className="dialog-line">
              <div className="title-image">
                <img
                  key={'img-modale'}
                  src={ModalImage}
                  alt="tchitchat brand"
                  className="img-brand"
                  width="48px"
                  height="48px"
                />
              </div>
              <div className="title-brand">Tchitchat</div>
            </div>
            <br />
            <div className="dialog-line">
              <div className="title">{title}</div>
            </div>
            <div className="dialog-line">
              <div className="splitbar"></div>
            </div>
            <div className="dialog-line">
              <div className="sentence">{sentence} </div>
            </div>
          </div>
        </div>
        <div className="txtbutton">
          <div className="btn btn-validation btn-modal" onClick={() => handleClick('login')}>
            <div className="btn-txt">
              <div className="rotate-180">
                <Icon
                  iconId={'signin-reco'}
                  iconTitle={'signin'}
                  topColor={'white'}
                  bottomColor={'white'}
                  iconWidth={'20px'}
                  iconHeight={'20px'}
                />
              </div>
              &nbsp;
              {txtbutton}
            </div>
          </div>
        </div>
        <div className="txtbutton">
          <div className="btn btn-validation btn-modal" onClick={() => handleClick('home')}>
            <div className="btn-txt">
              <div className="rotate-180">
                <Icon
                  iconId={'home-reco'}
                  iconTitle={'home'}
                  topColor={'white'}
                  bottomColor={'white'}
                  iconWidth={'20px'}
                  iconHeight={'20px'}
                />
              </div>
              &nbsp;
              {cancelbutton}
            </div>
          </div>
        </div>
        <div className="bottom-frame">
          <div className="bottom-line">
            <div className="bottom-text" onClick={() => handleClick('offline')}>
              {bottomtext}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConnectionBox
