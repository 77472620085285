import React from 'react'
// COMPONENTS
import Qrcode from './../components/Qrcode'
import Icon from './../components/Layout/Icon'
// CONFIG
import config from './../config'
// IMAGES
import ModalImage from './../images/TchitchatBrandHome.png'
import Smartworks from './../images/futuristicSmartphone.png'

const DialogMobile = ({
  onSubmit = undefined,
  card_guid = '',
  title = '... message',
  sentence = '',
  txtbutton = '',
  cancelbutton = '',
  bottomtext = '',
}) => {
  const handleClick = (choice) => {
    onSubmit(choice)
  }

  return (
    <>
      <div className="dialogbox">
        <div className="dialog-frame">
          <div className="dialog-col">
            <div className="dialog-line">
              <div className="title-image">
                <img
                  key={'img-modale'}
                  src={ModalImage}
                  alt="tchitchat brand"
                  className="img-brand"
                  width="48px"
                  height="48px"
                />
              </div>
              <div className="title-brand">Tchitchat</div>
            </div>

            <br />
            <div className="dialog-card">
              <div className="dialog-line">
                <div
                  className="smartworks"
                  style={{
                    backgroundImage: `url(${Smartworks})`,
                    backgroundSize: '128px 128px',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div className="smartworks-title">{title}</div>
              </div>

              <div className="qrcode-box">
                <div className="qrcode-mobile">
                  <Qrcode
                    id="qrcode"
                    typeNumber={0}
                    data={'https://' + config.raw_url_addr + '/on_scan/' + card_guid}
                    className=""
                    width={256}
                    height={256}
                  />
                </div>
              </div>

              <br />
              <div className="dialog-line dialog-mobile-line dialog-mobile-sentence">
                <div className="sentence">{sentence} </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dialog-mobile-line">
          <div className="txtbutton">
            <div className="btn btn-cancel btn-modal" onClick={() => handleClick('cancel')}>
              <div className="btn-txt">
                <div className="rotate-180">
                  <Icon
                    iconId={'home-reco'}
                    iconTitle={'home'}
                    topColor={config.colors.primary}
                    bottomColor={config.colors.primary}
                    iconWidth={'20px'}
                    iconHeight={'20px'}
                  />
                </div>
                &nbsp;
                {cancelbutton}
              </div>
            </div>
          </div>
          <div className="txtbutton">
            <div className="btn btn-validation btn-modal" onClick={() => handleClick('login')}>
              <div className="btn-txt">
                <div className="rotate-180">
                  <Icon
                    iconId={'signin-reco'}
                    iconTitle={'signin'}
                    topColor={'white'}
                    bottomColor={'white'}
                    iconWidth={'20px'}
                    iconHeight={'20px'}
                  />
                </div>
                &nbsp;
                {txtbutton}
              </div>
            </div>
          </div>
        </div>

        <br />
        {/* <div className="bottom-frame">
          <div className="bottom-line">
            <div className="bottom-text">COUNTER !</div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default DialogMobile
