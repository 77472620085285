import { set } from 'idb-keyval'

function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
  return value
}
function setSessionStorage(key, value) {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
  return value
}
// function getLocalStorage(key, initialValue) {
//   try {
//     const value = window.localStorage.getItem(key)
//     return value ? JSON.parse(value) : initialValue
//   } catch (e) {
//     // if error, return initial value
//     return initialValue
//   }
// }
function getSessionStorage(key, initialValue) {
  try {
    const value = window.sessionStorage.getItem(key)
    return value ? JSON.parse(value) : initialValue
  } catch (e) {
    // if error, return initial value
    return initialValue
  }
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
  switch (action.type) {
    case 'SET_INTERNET':
      return {
        ...state,
        internet: setSessionStorage('internet', action.payload.internet),
      }
    case 'SET_ROUTE':
      return {
        ...state,
        route: setSessionStorage('route', action.payload),
      }
    case 'GET_TOKEN':
      let role = state.role
      let connection = false
      if (
        action.payload.role &&
        action.payload.role !== 'anon' &&
        action.payload.role !== 'guest'
      ) {
        role = action.payload.role
        connection = true
      }
      return {
        ...state,
        connection: setSessionStorage('connection', connection),
        exp: setSessionStorage('exp', action.payload.exp),
        // From /token json response
        role: setLocalStorage('role', role),
        expirationDate: setLocalStorage('expirationDate', action.payload.expirationDate),
        token: setLocalStorage('token', true),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    case 'GET_REFRESH':
      return {
        ...state,
        connection: setSessionStorage('connection', true),

        message: setSessionStorage('message', action.payload.message),
        exp: setSessionStorage('exp', action.payload.exp),
      }
    case 'SET_LOGOUT':
      return {
        ...state,
        connection: setSessionStorage('connection', false),

        message: setSessionStorage('message', action.payload.message),
        token: setLocalStorage('token', false),
      }
    case 'SET_REVOKE':
      return {
        ...state,
        role: setLocalStorage('role', 'anon'),
        accessToken: setSessionStorage('accessToken', ''),
      }
    case 'SET_PROFILE':
      // console.log('SET_PROFILE action.payload.cardGuid ... : ', action.payload.cardGuid)

      let lastProfile = {
        cardGuid: action.payload.cardGuid,
        displayPseudo: action.payload.displayPseudo,
        netbio: action.payload.netbio,
        photo: action.payload.photo,
        photoFileType: action.payload.photoFileType,
        photoURL: action.payload.photoURL,
        netlinks: state.netlinks,
      }
      // console.log('Reducer SET_PROFILE lastProfile: ', lastProfile)
      // for CardList, just modify indexed record
      set(action.payload.cardGuid, lastProfile)

      return {
        ...state,
        connection: setSessionStorage('connection', true),
        exp: setSessionStorage('exp', action.payload.exp),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),

        displayPseudo: setLocalStorage('displayPseudo', action.payload.displayPseudo),
        netbio: setLocalStorage('netbio', action.payload.netbio),
        expiryTime: setLocalStorage('expiryTime', action.payload.expiryTime),
        photo: setLocalStorage('photo', action.payload.photo ? action.payload.photo : null),
        photoFileType: setLocalStorage(
          'photoFileType',
          action.payload.photoFileType ? action.payload.photoFileType : ''
        ),
        currentProfile: setSessionStorage('currentProfile', lastProfile),
      }
    case 'GET_PROFILE':
      let Profile = {
        cardGuid: action.payload.cardGuid,
        displayPseudo: action.payload.displayPseudo,
        netbio: action.payload.netbio,
        photo: action.payload.photo,
        photoFileType: action.payload.photoFileType,
        photoURL: action.payload.photoURL,
        netlinks: state.netlinks,
      }
      // console.log('Reducer GET_PROFILE Profile: ', Profile)
      // for CardList, just modify indexed record
      //  set(action.payload.cardGuid, Profile)
      return {
        // ...state,
        connection: setSessionStorage('connection', true),
        exp: setSessionStorage('exp', action.payload.exp),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
        online: setLocalStorage('online', action.payload.online),
        currentProfile: setSessionStorage('currentProfile', Profile),
        onlineExpiry: setLocalStorage('onlineExpiry', action.payload.onlineExpiry),
        // From rest db api :
        cardGuid: setLocalStorage('cardGuid', action.payload.cardGuid),
        displayPseudo: setLocalStorage(
          'displayPseudo',
          action.payload.displayPseudo !== '' ? action.payload.displayPseudo : 'User name ...'
        ),
        netbio: setLocalStorage(
          'netbio',
          action.payload.netbio !== '' ? action.payload.netbio : 'Netbio ...'
        ),
        expiryTime: setLocalStorage('expiryTime', action.payload.expiryTime),
        photo: setLocalStorage('photo', action.payload.photo),
        photoFileType: setLocalStorage('photoFileType', action.payload.photoFileType),
        photoURL: setLocalStorage('photoURL', action.payload.photoURL),
        typeContract: setLocalStorage('typeContract', action.payload.typeContract),
        expirationDate: setLocalStorage('expirationDate', action.payload.expirationDate),
        ownerKey: setLocalStorage('ownerKey', action.payload.ownerKey),
        accountHistory: setLocalStorage('accountHistory', action.payload.accountHistory),
        balance: setLocalStorage('balance', action.payload.balance),
        netlinks: setLocalStorage('netlinks', action.payload.netlinks),
        ...state,
      }
    case 'FETCH_PROFILE':
      // return a profile
      // console.log('Reducer FETCH_PROFILE payload: ', action.payload)
      let dbProfile = {
        cardGuid: action.payload.cardGuid,
        displayPseudo: action.payload.displayPseudo,
        netbio: action.payload.netbio,
        photo: action.payload.photo,
        photoFileType: action.payload.photoFileType,
        photoURL: action.payload.photoURL,
        netlinks: action.payload.netlinks,
      }
      return {
        ...state,
        error: setSessionStorage('error', action.payload.error),
        profile: setSessionStorage('profile', action.payload.profile),
        message: setSessionStorage('message', action.payload.message),
        currentProfile: setSessionStorage('currentProfile', dbProfile),
      }
    case 'GET_LINKS':
      return {
        ...state,
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
        // TODO: Add all fetched datas ...
        netlinks: setLocalStorage('netlinks', action.payload.netlinks),
      }
    case 'SET_ERROR':
      let Connection = state.connection
      if (action.payload.error === 403) {
        //        console.log('Reducer SET_ERROr payload: ', action.payload)
        Connection = false
      }
      return {
        ...state,
        connection: setSessionStorage('connection', Connection),
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
      }
    case 'SET_FETCHING':
      return {
        ...state,
        fetching: action.payload,
      }
    case 'SET_USER':
      return {
        ...state,
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
        // Authenticated firebase
        role: setLocalStorage('role', 'guest'),
        // From google firebase :
        displayName: setLocalStorage('displayName', action.payload.displayName),
        email: setLocalStorage('email', action.payload.email),
        uid: setLocalStorage('uid', action.payload.uid),
        accessToken: setSessionStorage('accessToken', action.payload.auth.currentUser.accessToken),
        providerId: setLocalStorage('providerId', action.payload.providerData[0].providerId),
        photoURL: setLocalStorage('photoURL', action.payload.photoURL),
      }
    case 'SET_LICENSE':
      return {
        ...state,
        connection: setSessionStorage('connection', true),
        exp: setSessionStorage('exp', action.payload.exp),
        // From /token json response
        role: setLocalStorage('role', action.payload.role),
        token: setLocalStorage('token', true),
        ownerKey: setLocalStorage('ownerKey', action.payload.ownerKey),
        expirationDate: setLocalStorage('expirationDate', action.payload.expirationDate),
        cardGuid: setLocalStorage('cardGuid', action.payload.cardGuid),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    case 'GET_LICENSES':
      return {
        ...state,
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
        batchName: setSessionStorage('batchName', action.payload.batchName),
        count: setSessionStorage('count', action.payload.count),
        fileName: setSessionStorage('fileName', action.payload.fileName),
        fileContent: setSessionStorage('fileContent', action.payload.fileContent),
      }
    case 'SET_ONLINE':
      return {
        ...state,
        online: setLocalStorage('online', action.payload.online),
        onlineExpiry: setLocalStorage('onlineExpiry', action.payload.onlineExpiry),
        exp: setSessionStorage('exp', action.payload.exp),
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
      }
    case 'SET_EXPIRY_TIME':
      return {
        ...state,
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
        exp: setSessionStorage('exp', action.payload.exp),
        expiryTime: setLocalStorage('expiryTime', action.payload.expiryTime),
      }
    case 'SET_OFFLINE':
      return {
        ...state,
        online: setLocalStorage('online', false),
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
      }
    case 'SET_STAY_OFFLINE':
      return {
        ...state,
        offline: setSessionStorage('offline', action.payload.offline),
      }
    case 'DEL_LINK':
      state.netlinks = state.netlinks.filter((e) => e.id !== action.payload.linkId)
      return {
        ...state,
        netlinks: setLocalStorage('netlinks', state.netlinks),
        error: setSessionStorage('error', action.payload.error),
        message: setSessionStorage('message', action.payload.message),
      }
    case 'ADD_LINK':
      // Is there allready a blank input ?
      let links = state.netlinks.filter(
        (e) => e.templateLinkId.toString() === action.payload.toString()
      )

      let existingBlank = false
      for (let i = 0; i < links.length; i++) {
        // console.log('state.netlinks[i].part1:', links[i])
        if (links[i].part1 === '') {
          existingBlank = true
        }
      }
      if (existingBlank === false) {
        let id = uuidv4()

        // console.log('templateLinkId:', action.payload)
        let aLink = {
          id: id,
          part1: '',
          part2: null,
          part3: null,
          templateLinkId: parseInt(action.payload),
        }
        state.netlinks.push(aLink)
        return {
          ...state,
          netlinks: setLocalStorage('netlinks', state.netlinks),
        }
      } else {
        return {
          ...state,
        }
      }
    case 'UPDATE_LINK':
      state.netlinks.forEach(function (netlink) {
        // console.log('netlink.id:', netlink.id)
        if (netlink.id === action.payload.id) {
          netlink.part1 = action.payload.strLink
        }
      })
      return {
        ...state,
        netlinks: setLocalStorage('netlinks', state.netlinks),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    case 'ORIGINAL_LINK':
      state.originalNetlinks = []
      for (let i = 0; i < action.payload.originalNetlinks.length; i++) {
        state.originalNetlinks.push(action.payload.originalNetlinks[i])
      }
      return {
        ...state,
        netlinks: setLocalStorage('netlinks', state.netlinks),
        originalNetlinks: setSessionStorage('originalNetlinks', state.originalNetlinks),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    case 'SET_CURRENT_PROFILE':
      // console.log('SET_CURRENT_PROFILE action.payload: ', action.payload)
      let indexedDbProfile = action.payload.profile
      // console.log('SET_CURRENT_PROFILE: ', indexedDbProfile)
      return {
        ...state,
        currentProfile: setSessionStorage('currentProfile', indexedDbProfile),
      }
    case 'SET_CURRENT_CARDLIST':
      // console.log('SET_CARDLIST action.payload: ', action.payload)
      let indexedDbCardList = action.payload.cardList
      // console.log('SET_CURRENT_CARDLIST: ', indexedDbCardList)
      return {
        ...state,
        currentCardList: setSessionStorage('currentCardList', indexedDbCardList),
      }
    case 'RESTORE_LINK':
      // del old
      state.netlinks = state.netlinks.filter(
        (e) => e.templateLinkId.toString() !== action.payload.id
      )
      state.originalNetlinks = getSessionStorage('originalNetlinks', [])
      for (let i = 0; i < state.originalNetlinks.length; i++) {
        state.netlinks.push(state.originalNetlinks[i])
      }
      return {
        ...state,
        netlinks: setLocalStorage('netlinks', state.netlinks),
        // originalNetlinks: setSessionStorage('originalNetlinks', []),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    case 'SET_LINKS':
      // state.netlinks = state.netlinks.filter((e) => e.id !== action.payload.linkId)
      return {
        ...state,
        netlinks: setLocalStorage('netlinks', state.netlinks),
        error: setSessionStorage('error', false),
        message: setSessionStorage('message', ''),
      }
    default:
      return state
  }
}
