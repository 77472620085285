import { FC, useEffect, useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// HOOKS
import { useNavbar } from './../../../hooks/useNavbar'
import { Context } from '../../../components/Wrapper'
import { useFooter } from './../../../hooks/useFooter'
import Clock from '../../../hooks/useTime'
// LIBRARIES
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { getTIMESTAMP } from '../../../utils/utilFunc'
// UTILS
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter.js'
// COMPONENTS
import Icon from '../../../components/Layout/Icon'
import Modal from '../../../components/ModalFrame'
import DialogBox from '../../../components/DialogBox'
import SelectLanguage from '../../../components/SelectLanguage'
// CONTEXT
import { ProfileContext } from '../../../Reducers/ProfileContext'
import { setOnline, getRefresh, setFetching, setInternet } from '../../../Reducers/ProfileActions'
import { useIntl } from 'react-intl'

interface IFooter {}
const Footer: FC<IFooter> = (props) => {
  const { withoutNavbar, setOnlineBadge } = useNavbar()
  const modal1 = useRef<any>(null)
  const { footerBrand, online, setInternetOnline } = useFooter()
  const [lockColor, setLockColor] = useState('red')
  const [currentConnection, setCurrentConnection] = useState(false)
  const { state, dispatch } = useContext(ProfileContext)
  const notify = (txtToast) => {
    toast.error(txtToast, {
      toastId: 'footer-toast',
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }
  const [jobDone, setJobDone] = useState(false)

  const [nbRetry, setNbRetry] = useState(0)

  const [reminderDone, setReminderDone] = useState(false)
  const navigate = useNavigate()
  // Localization
  const intl = useIntl()
  const myContext = useContext(Context)
  const myLang = myContext.locale?.substring(0, 2)
  const languages = myContext.languages

  useEffect(() => {
    if (
      state.connection === false &&
      state.role !== 'anon' &&
      state.role !== 'guest' &&
      currentConnection === true &&
      online === true
    ) {
      if (state.error === 403 && !jobDone) {
        setJobDone(true)
        let stringMessage = intl.formatMessage({ id: 'app.authexpired' })
        notify(stringMessage)
      }
    }
  }, [online, intl])

  useEffect(() => {
    // console.log('footer : state.connection', state.connection)
    // console.log('footer : state.inernet', state.internet)
    if (!state.connection) {
      setLockColor('red')
      if (
        state.role !== 'anon' &&
        state.role !== 'guest' &&
        currentConnection === true &&
        online === true
      ) {
        if (state.error === 403 && !jobDone) {
          setJobDone(true)
          let stringMessage = intl.formatMessage({ id: 'app.authexpired' })
          notify(stringMessage)
          setTimeout(function () {}, 5000)
        }
      }
      return
    } else {
      setLockColor('green')
      setCurrentConnection(true)
      setJobDone(false)
    }
  }, [state.connection])

  useEffect(() => {
    const timer = window.setInterval(() => {
      // Phase I - globe color
      let connection = navigator.onLine ? 'online' : 'offline'
      if (connection === 'online') {
        // beware ! this setOnline is from useFooter tsx ...
        setInternetOnline(true)
        setInternet(true, state, dispatch)
      } else {
        setInternetOnline(false)
        setInternet(false, state, dispatch)
      }
      // Phase II - soft reminder - user ux
      const remain = Math.floor((state.exp - Date.now() + 61000) / 1000 / 60)
      // console.log('footer : Date.now()', getTIMESTAMP(Date.now()))
      // console.log('footer : remain:', remain, ' minutes')
      if (remain < 2 && remain > 0 && !reminderDone) {
        setReminderDone(true)
        modal1?.current?.open()
      }
      if (remain === 0 && reminderDone === true) {
        setReminderDone(false)
        getRefresh(state, dispatch) // get our user profile from backend
        setFetching(dispatch, false)
        modal1?.current?.close()
      }
      // Phase III - online expiry check

      // console.log('footer state.onlineExpiry - Date.now(): ', countdown)
      if (state.expiryTime < 0 || state.expiryTime === 0) {
        // state.online applies
        // console.log(' state.online: ', state.online)
        setOnlineBadge(state.online)
      } else {
        let countdown = state.onlineExpiry - Date.now()
        if (countdown < 0 && state.online === true) {
          if (nbRetry < 9) {
            setOnline(false, state, dispatch)
            setNbRetry(nbRetry + 1)
          }

          // console.log(' end of presence / state.online: ', state.online)
          setOnlineBadge(false)
        } else {
          setOnlineBadge(state.online)
        }
      }
    }, 1000)
    return () => {
      window.clearInterval(timer)
    }
  })

  const handleDialogReminder = async (response) => {
    if (!response || response !== 'yes') return
    // ok - refresh token
    await getRefresh(state, dispatch) // refresh token from backend
    setFetching(dispatch, false)
    setReminderDone(false)
    modal1.current.close()
    console.log(' -> Session about to expire, route: ', state.route)
  }

  const handleSelectLanguage = (select: string): any => {
    console.log('handleSelectLanguage = (select) ', select)
    myContext.selectLanguage(select)
    return
  }
  return (
    <div className={!withoutNavbar ? 'tch-footer' : 'nonavbar'}>
      <div className="bottom-line">
        <div className="footer-left">
          <div className="connection">
            <div className="role-frame">
              <div
                className="role-line"
                onClick={() => {
                  navigate('/login')
                }}
              >
                <Icon
                  iconId={'lock'}
                  iconTitle={'lock'}
                  topColor={lockColor}
                  bottomColor={lockColor}
                  iconWidth={'22px'}
                  iconHeight={'22px'}
                />
              </div>
              <div className="role">{state.role} </div>
            </div>
            <div>
              <ToastContainer transition={Zoom} limit={1} />
            </div>
            {/* // MODAL 1 -> Reminder  */}
            <Modal ref={modal1} nocross={true}>
              <div className="line fullGrid">
                <div className="grid100">
                  <DialogBox
                    onSubmit={handleDialogReminder}
                    title={intl.formatMessage({ id: 'app.reminder.title' })}
                    sentence={intl.formatMessage({ id: 'app.reminder.sentence' })}
                    txtbutton={intl.formatMessage({ id: 'app.reminder.txtbutton' })}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className="footer-middle-frame">
          <div className="online-icon">
            <Icon
              iconId={'globe'}
              iconTitle={'globe'}
              topColor={online ? 'white' : 'red'}
              bottomColor={online ? 'white' : 'red'}
              iconWidth={'24px'}
              iconHeight={'24px'}
            />
          </div>
          <div className="footer-middle">
            <div className="footer-middle-col">
              <div className="copyright">
                <div className="c-unicode">© </div>
                <div className="year">{new Date().getFullYear()}</div>-
                <div className="number-version"> Ver {process.env.REACT_APP_VERSION}</div>
              </div>
              <div className="footer-brand">
                {process.env.REACT_APP_ENV === 'development' ? 'Dev - ' : ''}
                {capitalizeFirstLetter(footerBrand ? footerBrand : process.env.REACT_APP_NAME)}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-right">
          {/* // LANGUAGE SELECTION */}
          <SelectLanguage
            defaultValue={myLang}
            onSelect={handleSelectLanguage}
            options={languages}
          />

          {/* <div className="online-icon">
            <Icon
              iconId={'globe'}
              iconTitle={'globe'}
              topColor={online ? 'white' : 'red'}
              bottomColor={online ? 'white' : 'red'}
              iconWidth={'24px'}
              iconHeight={'24px'}
            />
          </div>
          <div className="date-time">
            <div className="date">{new Date().toLocaleDateString() + ' '}</div>
            <div className="time">
              <Clock />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Footer
