import { useEffect, useRef, useState } from 'react'
// HOOKS
import { useDevice } from './../hooks/useDevice'
// COMPONENTS
import Icon from './Layout/Icon'
// CONFIG
import config from './../config'

const InputLink = ({
  id,
  active = true,
  editable = false,
  reset = false,
  onEditing = undefined,
  onChange = undefined,
  onReset = undefined,
  onTouched = undefined,
  onClickBin = undefined,
  defaultValue = '',
  type = 'text',
  max = 50,
  placeholder = '',
}) => {
  const [input, setInput] = useState(defaultValue) // '' is the initial state value
  const [editing, setEditing] = useState(false)
  const [touched, setTouched] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (reset === true) {
      setInput(defaultValue)
      setTouched(false)
    }
  }, [reset, defaultValue])

  const onFocusInput = (e) => {
    if (editable === false) return

    console.log(' onFocusInput ...')
    setEditing(true)
    onEditing(true)
  }

  const onChangeInput = (e) => {
    setTouched(true)
    onTouched(true)
    setInput(e.target.value)

    onChange(id, inputRef.current.value.trim())
  }

  const clickBin = () => {
    console.log(' clickBin ...')
    onClickBin(id)
  }

  const resetInput = () => {
    setEditing(false)
    onEditing(false)
    // onReset(true)
  }

  return (
    <div className="input-focus" tabIndex={'0'}>
      <div
        className="focus-frame"
        key={'editzone'}
        tabIndex={'0'}
        onBlur={(e) => {
          if (!active || !editable) {
            return
          } else {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              resetInput()
              if (e.relatedTarget !== null) {
                let strTarget = e.relatedTarget.outerHTML
                // console.log(' ... click blur ...', strTarget)
                if (strTarget.includes('btn-cancel')) {
                  // console.log('btn-cancel trouvé -----------------')
                  onReset()
                }
                e.relatedTarget.click()
              }
            }
          }
        }}
      >
        <div className="input-frame">
          <div className="input-box">
            <div className="input-container">
              <input
                id={id}
                className={editing ? 'editing' : 'not-editing'}
                ref={inputRef}
                type={type}
                maxLength={max}
                value={input}
                onChange={(e) => onChangeInput(e)}
                onFocus={(e) => onFocusInput(e)}
                placeholder={placeholder}
                spellCheck={false}
              />
            </div>
            <div className="icon-touched">
              {touched ? (
                <Icon
                  iconId={'check'}
                  iconTitle={'check'}
                  topColor={config.colors.primary}
                  bottomColor={config.colors.primary}
                  iconWidth={'12px'}
                  iconHeight={'12px'}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {editing ? (
        <>
          <div className="icon-bin" tabIndex={'0'} onClick={(e) => clickBin(e)}>
            <Icon
              iconId={'bin'}
              iconTitle={'bin'}
              topColor={'white'}
              bottomColor={'white'}
              iconWidth={'24px'}
              iconHeight={'24px'}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
export default InputLink
