import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

// // CONFIG
import configClient from './../config'

const config = configClient.firebase_configuration

function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
    console.log(' ... firebase init ok ...')
  }
}
initFirebase()

export { firebase }
