import React from 'react'

export const Calendly = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="0 0 40 40"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>

      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M27.4166 25.9298C26.1216 27.0554 24.5105 28.4566 21.5764 28.4566H19.8247C17.7043 28.4566 15.7759 27.702 14.3955 26.3307C13.0478 24.9914 12.3043 23.1595 12.3043 21.1702V18.8179C12.3043 16.8286 13.0466 14.9955 14.3955 13.6574C15.7759 12.286 17.7043 11.5314 19.8247 11.5314H21.5764C24.5105 11.5314 26.1216 12.9326 27.4166 14.0582C28.7596 15.2263 29.9199 16.2348 33.0098 16.2348C33.4898 16.2348 33.9605 16.1969 34.4183 16.1245C34.4148 16.1153 34.4113 16.1073 34.4078 16.0981C34.224 15.6513 34.0073 15.2125 33.758 14.7887L31.6914 11.2776C29.7958 8.05585 26.2914 6.07227 22.5002 6.07227H18.367C14.5758 6.07227 11.0714 8.05699 9.17577 11.2776L7.10922 14.7887C5.21359 18.0105 5.21359 21.9787 7.10922 25.1993L9.17577 28.7105C11.0714 31.9322 14.5758 33.9158 18.367 33.9158H22.5002C26.2914 33.9158 29.7958 31.9311 31.6914 28.7105L33.758 25.1993C34.0073 24.7744 34.224 24.3367 34.4078 23.89C34.4113 23.8808 34.4148 23.8727 34.4183 23.8635C33.9605 23.7912 33.491 23.7533 33.0098 23.7533C29.9199 23.7533 28.7596 24.7617 27.4166 25.9298Z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M21.5767 13.6621H19.825C16.5982 13.6621 14.4766 15.9236 14.4766 18.818V21.1703C14.4766 24.0647 16.597 26.3262 19.825 26.3262H21.5767C26.2788 26.3262 25.91 21.6228 33.0101 21.6228C33.6904 21.6228 34.3624 21.6837 35.0169 21.8031C35.2324 20.6075 35.2324 19.3831 35.0169 18.1863C34.3624 18.3058 33.6904 18.3666 33.0101 18.3666C25.91 18.3655 26.2788 13.6621 21.5767 13.6621Z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M39.095 23.5203C37.882 22.6428 36.491 22.0708 35.0157 21.8009C35.0134 21.8124 35.0122 21.8239 35.0099 21.8354C34.8834 22.5245 34.6867 23.2033 34.4174 23.8614C35.662 24.059 36.8095 24.5184 37.7895 25.2225C37.786 25.2328 37.7836 25.2432 37.7801 25.2547C37.2146 27.0556 36.3622 28.7532 35.2476 30.298C34.1458 31.8233 32.8145 33.166 31.2889 34.2881C28.1217 36.6186 24.3492 37.8498 20.3776 37.8498C17.9188 37.8498 15.535 37.3778 13.2916 36.4474C11.1243 35.5481 9.17718 34.2605 7.50402 32.6192C5.83086 30.9779 4.51835 29.0679 3.60156 26.9419C2.65317 24.7412 2.17194 22.4028 2.17194 19.9908C2.17194 17.5788 2.65317 15.2403 3.60156 13.0397C4.51835 10.9137 5.83086 9.0036 7.50402 7.3623C9.17718 5.721 11.1243 4.43346 13.2916 3.53414C15.535 2.6038 17.9188 2.13174 20.3776 2.13174C24.3492 2.13174 28.1217 3.36301 31.2889 5.69345C32.8145 6.81559 34.1458 8.15827 35.2476 9.68356C36.3622 11.2284 37.2146 12.926 37.7801 14.7269C37.7836 14.7384 37.7871 14.7487 37.7895 14.7591C36.8095 15.4631 35.662 15.9237 34.4174 16.1201C34.6867 16.7794 34.8846 17.4593 35.0099 18.1485C35.0122 18.16 35.0134 18.1703 35.0157 18.1818C36.491 17.9119 37.8808 17.3399 39.095 16.4624C40.2576 15.6182 40.0328 14.6649 39.856 14.0998C37.293 5.93464 29.542 0 20.3776 0C9.12334 0 0 8.94962 0 19.9896C0 31.0296 9.12334 39.9793 20.3776 39.9793C29.542 39.9793 37.293 34.0446 39.856 25.8795C40.0328 25.3178 40.2588 24.3645 39.095 23.5203Z"
      />
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M34.4187 16.1224C33.9609 16.1948 33.4914 16.2327 33.0102 16.2327C29.9203 16.2327 28.76 15.2242 27.417 14.0561C26.122 12.9305 24.5109 11.5293 21.5767 11.5293H19.8251C17.7047 11.5293 15.7763 12.2839 14.3959 13.6553C13.0482 14.9945 12.3047 16.8265 12.3047 18.8158V21.1681C12.3047 23.1574 13.047 24.9905 14.3959 26.3286C15.7763 27.6999 17.7047 28.4546 19.8251 28.4546H21.5767C24.5109 28.4546 26.122 27.0533 27.417 25.9277C28.76 24.7596 29.9203 23.7512 33.0102 23.7512C33.4902 23.7512 33.9609 23.7891 34.4187 23.8614C34.688 23.2033 34.8847 22.5234 35.0112 21.8354C35.0135 21.8239 35.0147 21.8124 35.017 21.8009C34.3625 21.6815 33.6904 21.6206 33.0102 21.6206C25.9101 21.6206 26.2789 26.324 21.5767 26.324H19.8251C16.5983 26.324 14.4766 24.0624 14.4766 21.1681V18.8158C14.4766 15.9214 16.5971 13.6599 19.8251 13.6599H21.5767C26.2789 13.6599 25.9101 18.3633 33.0102 18.3633C33.6904 18.3633 34.3625 18.3024 35.017 18.1829C35.0147 18.1715 35.0135 18.1611 35.0112 18.1496C34.8859 17.4616 34.688 16.7817 34.4187 16.1224Z"
      />
      <path
        fillOpacity={'0'}
        // fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M34.4187 16.1224C33.9609 16.1948 33.4914 16.2327 33.0102 16.2327C29.9203 16.2327 28.76 15.2242 27.417 14.0561C26.122 12.9305 24.5109 11.5293 21.5767 11.5293H19.8251C17.7047 11.5293 15.7763 12.2839 14.3959 13.6553C13.0482 14.9945 12.3047 16.8265 12.3047 18.8158V21.1681C12.3047 23.1574 13.047 24.9905 14.3959 26.3286C15.7763 27.6999 17.7047 28.4546 19.8251 28.4546H21.5767C24.5109 28.4546 26.122 27.0533 27.417 25.9277C28.76 24.7596 29.9203 23.7512 33.0102 23.7512C33.4902 23.7512 33.9609 23.7891 34.4187 23.8614C34.688 23.2033 34.8847 22.5234 35.0112 21.8354C35.0135 21.8239 35.0147 21.8124 35.017 21.8009C34.3625 21.6815 33.6904 21.6206 33.0102 21.6206C25.9101 21.6206 26.2789 26.324 21.5767 26.324H19.8251C16.5983 26.324 14.4766 24.0624 14.4766 21.1681V18.8158C14.4766 15.9214 16.5971 13.6599 19.8251 13.6599H21.5767C26.2789 13.6599 25.9101 18.3633 33.0102 18.3633C33.6904 18.3633 34.3625 18.3024 35.017 18.1829C35.0147 18.1715 35.0135 18.1611 35.0112 18.1496C34.8859 17.4616 34.688 16.7817 34.4187 16.1224Z"
      />
    </svg>
  )
}
export default Calendly
