import React from 'react'

export const Eiffel = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="84 -1 400 515"
    >
      <defs>
        <linearGradient id={props.id} x1="512" y1="512" x2="512" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M413.705,469.827h-22.881c-13.319-27.257-26.247-57.295-38.587-89.668h3.227c7.547,0,13.688-6.153,13.688-13.716V347.63
        c0-7.563-6.14-13.716-13.688-13.716h-19.892c-10.104-29.619-19.624-60.616-28.426-92.496h1.498
        c7.548,0,13.689-6.152,13.689-13.715V208.89c0-7.563-6.141-13.715-13.689-13.715h-13.609
        c-6.682-26.964-12.828-54.361-18.362-81.914h5.634c7.548,0,13.69-6.153,13.69-13.715V84.261c0-7.563-6.141-13.715-13.69-13.715
        h-0.437V49.005c0-8.868-7.198-16.084-16.045-16.084h-2.016V7.837c0-4.328-3.509-7.837-7.837-7.837
        c-4.328,0-7.837,3.509-7.837,7.837v25.084h-2.016c-8.848,0-16.047,7.215-16.047,16.084v21.541h-0.437
        c-7.548,0-13.689,6.152-13.689,13.715v15.286c0,7.563,6.141,13.715,13.689,13.715h5.693
        c-5.533,27.552-11.678,54.949-18.361,81.914h-13.666c-7.548,0-13.69,6.152-13.69,13.715v18.812c0,7.563,6.142,13.715,13.69,13.715
        h1.556c-8.802,31.88-18.322,62.876-28.427,92.496h-19.948c-7.548,0-13.689,6.153-13.689,13.716v18.812
        c0,7.563,6.141,13.716,13.689,13.716h3.284c-12.34,32.371-25.268,62.409-38.588,89.668H98.242
        c-7.352,0-13.333,5.991-13.333,13.357v15.459c0,7.365,5.981,13.357,13.333,13.357h94.529c7.352,0,13.334-5.991,13.334-13.357
        v-15.459c0-7.365-5.982-13.357-13.334-13.357h-9.706c4.154-11.964,11.208-22.825,20.699-31.602
        c14.311-13.234,32.863-20.522,52.239-20.522c19.391,0,37.942,7.28,52.238,20.498c9.493,8.777,16.548,19.647,20.703,31.626h-9.765
        c-7.352,0-13.334,5.991-13.334,13.357v15.459c0,7.365,5.982,13.357,13.334,13.357h94.529c7.351,0,13.332-5.991,13.332-13.357
        v-15.459C427.036,475.818,421.056,469.827,413.705,469.827z M319.025,333.914h-26.453v-9.995h23.12
        C316.797,327.267,317.908,330.598,319.025,333.914z M310.62,308.245h-18.049v-5.227c0-2.14-0.2-4.232-0.561-6.267h15.002
        C308.205,300.601,309.408,304.432,310.62,308.245z M257.568,267.635c-11.106,0-21.014,5.262-27.431,13.443h-20.375
        c1.348-4.507,2.681-9.035,4.001-13.584h43.282c4.328,0,7.837-3.509,7.837-7.837c0-4.328-3.509-7.837-7.837-7.837h-38.813
        c0.969-3.459,1.929-6.927,2.883-10.403h69.773c0.954,3.476,1.915,6.944,2.883,10.403h-5.379c-4.328,0-7.837,3.509-7.837,7.837
        c0,4.328,3.509,7.837,7.837,7.837h9.848c1.32,4.548,2.653,9.077,4.001,13.584h-17.243
        C278.582,272.897,268.674,267.635,257.568,267.635z M276.898,303.018v30.896h-38.661v-30.896c0-10.868,8.672-19.71,19.331-19.71
        S276.898,292.15,276.898,303.018z M231.622,97.588v-11.37h11.565c4.328,0,7.837-3.509,7.837-7.837c0-3.43-2.208-6.338-5.277-7.4
        V49.005c0-0.226,0.167-0.411,0.373-0.411h19.704c0.206,0,0.373,0.184,0.373,0.411v21.541h-1.038c-4.328,0-7.837,3.509-7.837,7.837
        c0,4.328,3.509,7.837,7.837,7.837h15.165v11.37H231.622z M271.306,163.091c0.654,2.877,1.315,5.749,1.981,8.618h-34.57
        c0.667-2.869,1.327-5.742,1.981-8.618H271.306z M244.187,147.417c2.478-11.35,4.856-22.741,7.131-34.156h9.366
        c2.275,11.416,4.654,22.807,7.131,34.156H244.187z M235.01,187.383h41.985c0.628,2.602,1.261,5.199,1.898,7.793h-45.781
        C233.749,192.581,234.382,189.983,235.01,187.383z M205.286,210.849H306.66v14.895H205.286V210.849z M204.992,296.751h18.133
        c-0.362,2.035-0.561,4.127-0.561,6.267v5.227h-21.18C202.596,304.432,203.799,300.601,204.992,296.751z M196.312,323.918h26.252
        v9.995h-29.585C194.097,330.598,195.207,327.267,196.312,323.918z M158.468,364.485v-14.898h195.01v14.898H158.468z
         M141.87,462.89h27.042c-0.843,2.279-1.597,4.592-2.261,6.937h-28.063C139.686,467.533,140.779,465.22,141.87,462.89z
         M190.43,485.5v10.826h-89.848V485.5H190.43z M176.442,447.216h-27.395c2.15-4.805,4.286-9.686,6.409-14.629h31.866
        C183.206,437.126,179.565,442.024,176.442,447.216z M220.474,402.286h28.984c-15.628,1.104-30.612,6.143-43.698,14.629H162.05
        c1.986-4.818,3.959-9.694,5.919-14.629h22.203c4.328,0,7.837-3.509,7.837-7.837s-3.509-7.837-7.837-7.837h-16.096
        c0.817-2.14,1.631-4.292,2.444-6.453h158.962c0.812,2.16,1.627,4.313,2.444,6.453H220.474c-4.328,0-7.837,3.509-7.837,7.837
        S216.146,402.286,220.474,402.286z M306.271,416.914c-13.084-8.485-28.076-13.524-43.72-14.629h81.484
        c1.959,4.935,3.933,9.811,5.918,14.629H306.271z M324.701,432.588h31.846c5.536,12.887,11.164,25.311,16.869,37.239h-28.064
        C341.447,456.031,334.409,443.296,324.701,432.588z M411.362,496.327h-89.847V485.5h89.847V496.327z"
      />
    </svg>
  )
}
export default Eiffel
