import React from 'react'

export const Snapchat = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 32}
      height={props.height ? props.height : 32}
      viewBox="-1 -2 34 35.8"
    >
      <defs>
        <linearGradient id={props.id} x1="256" y1="512" x2="256" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={props.top_color} />
          <stop offset="1" stopColor={props.bottom_color} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${props.id})`}
        fillRule="evenodd"
        d="M16.276 1.057c1.318 0 5.797 0.37 7.906 5.094 0.703 1.594 0.536 4.292 0.396 6.464v0.078c-0.016 0.24-0.031 0.464-0.042 0.682 0.099 0.057 0.271 0.12 0.536 0.12 0.396-0.021 0.875-0.161 1.375-0.401 0.219-0.12 0.458-0.141 0.62-0.141 0.24 0 0.479 0.042 0.677 0.12 0.599 0.198 0.979 0.641 0.979 1.12 0.021 0.599-0.521 1.12-1.62 1.557-0.115 0.036-0.276 0.099-0.458 0.156-0.599 0.182-1.516 0.479-1.776 1.083-0.12 0.297-0.078 0.698 0.161 1.156l0.021 0.021c0.078 0.182 2.031 4.63 6.385 5.349 0.344 0.063 0.583 0.359 0.563 0.682 0 0.099-0.021 0.198-0.063 0.297-0.318 0.76-1.698 1.318-4.193 1.698-0.078 0.12-0.161 0.5-0.219 0.76-0.042 0.234-0.099 0.479-0.177 0.734-0.104 0.359-0.365 0.542-0.745 0.542h-0.036c-0.182 0-0.417-0.042-0.719-0.099-0.479-0.099-1.021-0.182-1.698-0.182-0.401 0-0.797 0.021-1.219 0.099-0.797 0.141-1.495 0.62-2.297 1.182-1.135 0.797-2.432 1.714-4.391 1.714-0.078 0-0.156-0.021-0.24-0.021h-0.198c-1.958 0-3.234-0.896-4.375-1.714-0.797-0.563-1.474-1.042-2.276-1.182-0.417-0.057-0.839-0.099-1.234-0.099-0.719 0-1.276 0.12-1.698 0.203-0.281 0.057-0.521 0.099-0.719 0.099-0.5 0-0.698-0.302-0.776-0.563-0.083-0.255-0.12-0.521-0.182-0.755-0.063-0.24-0.141-0.661-0.219-0.76-2.557-0.297-3.932-0.854-4.255-1.635-0.042-0.083-0.068-0.198-0.073-0.302-0.021-0.323 0.219-0.62 0.563-0.677 4.349-0.719 6.307-5.172 6.385-5.359l0.021-0.036c0.24-0.464 0.302-0.859 0.161-1.161-0.26-0.578-1.177-0.875-1.776-1.078-0.161-0.036-0.323-0.099-0.464-0.156-1.474-0.583-1.677-1.24-1.594-1.698 0.12-0.641 0.896-1.057 1.557-1.057 0.193 0 0.359 0.036 0.51 0.099 0.557 0.255 1.052 0.396 1.474 0.396 0.307 0 0.51-0.078 0.62-0.135l-0.063-0.76c-0.13-2.167-0.302-4.87 0.406-6.448 2.052-4.703 6.516-5.063 7.833-5.063l0.557-0.021z"
      />
    </svg>
  )
}
export default Snapchat
