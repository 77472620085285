import React, { useState, useEffect } from 'react'
// HOOKS
import { useNavbar } from './../hooks/useNavbar.tsx'
import useFetch from './../hooks/useFetch'
// LIBRARIES
import { useIntl } from 'react-intl'
// COMPONENTS
import Qrcode from './../components/Qrcode'
import Spinner from './../components/Spinner'
import Post from './../components/Post'
// IMAGES
import NailChip from './../images/nail-chip.png'

const Home = () => {
  console.log('home/begin ----------------------------')
  const categories = useFetch('https://wp.tchitch.at/wp-json/wp/v2/categories')
  const intl = useIntl()
  const {
    setNavbarTitle,
    setTitleIcon,
    setAbsoluteWidth,
    setAbsoluteHeight,
    setIconTopLeft,
    setIconTopLeftDown,
    setIconTopRight,
    setBadgeTopRight,
    pullDown,
  } = useNavbar()

  useEffect(() => {
    setNavbarTitle(intl.formatMessage({ id: 'app.home' }))
    setTitleIcon('haunted')
    setAbsoluteWidth('28px')
    setAbsoluteHeight('28px')
    setIconTopLeft('menu')
    setIconTopLeftDown('cross')
    setIconTopRight('femalelink')
    setBadgeTopRight(1)
  })
  const [category, setCategory] = useState('news')
  const [posts, setPosts] = useState(null)

  const DoFetch = (category) => {
    async function LoadData() {
      console.log('posts category: ', category)

      const categoryId = categories.filter((e) => e.slug === category)

      const url = 'https://wp.tchitch.at/wp-json/wp/v2/posts?categories=' + categoryId[0].id

      console.log('url: ', url)

      const response = await fetch(url)
      if (!response.ok) {
        // oups! something went wrong
        return
      }

      const data = await response.json()
      console.log('-+ data :', data)

      setPosts(data)
    }

    LoadData()

    // console.log('posts concerned: ', categPosts)

    return
  }

  useEffect(() => {
    if (categories) {
      DoFetch(category)
      window.scrollTo(0, 0)
    }
  }, [category, categories])

  const handleCateg = (categ) => {
    setCategory(categ)
    setPosts(null)
    DoFetch(categ)
  }

  const handleClick = (link) => {
    if (!pullDown) {
      window.open(link, '_blank')
    }
  }

  const clickQrcode = (link) => {
    console.log('clickQrcode  .........', link)
    window.open(link, '_blank')
  }

  if (!categories || !posts) {
    return <Spinner datatext={intl.formatMessage({ id: 'app.spinner.loading' })} />
  }

  return (
    <>
      {categories && (
        <div className="inner innerMediumWidth">
          <div className="home-page">
            <div className="btn-categ-frame">
              <div
                className={'btn-categ' + (category === 'news' ? ' active' : '')}
                onClick={() => handleCateg('news')}
              >
                Newz
              </div>
              <div
                className={'btn-categ' + (category === 'kit' ? ' active' : '')}
                onClick={() => handleCateg('kit')}
              >
                Kits
              </div>
              <div
                className={'btn-categ' + (category === 'nail-art' ? ' active' : '')}
                onClick={() => handleCateg('nail-art')}
              >
                Nail art
              </div>
            </div>
            <br /> <br />
            <div className="chip-nail-home">
              <img
                key={'img-nailchip'}
                src={NailChip}
                alt="nail chip"
                className="img-settings"
                width="100px"
                // height="96px"
              />{' '}
              <div className="home-topic">
                {intl.formatMessage({ id: 'app.home.findarticles' })} :
              </div>
            </div>
            <div className="card content-frame">
              <div className="html-frame">
                <div className="html-content">
                  <br />
                  {posts.map((post, index) => (
                    <div className="article" key={'In-' + post.id}>
                      <div
                        className="article-title"
                        dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                        onClick={() => handleClick(post.link)}
                      ></div>

                      <div className="article-hd">
                        <img
                          onClick={() => handleClick(post.link)}
                          src={`${post.fimg_url}`}
                          alt="article pict"
                        />
                        <div className="qrcode-frame" onClick={() => clickQrcode(post.link)}>
                          <Qrcode
                            id={post.id}
                            data={post.link}
                            width={128}
                            height={128}
                            typeNumber={4}
                          />
                        </div>
                      </div>
                      <div
                        className="article-text"
                        dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                      ></div>
                    </div>
                  ))}
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Home

//  {/* <Icon
//               iconId={'rose-pullmain'}
//               iconTitle={'diamond'}
//               topColor={'white'}
//               bottomColor={config.colors.primary}
//               iconWidth={'38px'}
//               iconHeight={'38px'}
//             />
//             <div className="title">{navbarTitle} </div> */}

// <div className="html-content">
// {postsList.map((post, index) => (
//   <Post postId={post} />
// ))}
// </div>

// const postsList = [597, 350, 348, 344, 471, 710]
